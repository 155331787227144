import React from "react";
import { FormattedMessage } from "react-intl";
import { ImanListItem } from "./ImanList";
import { IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useHistory } from "react-router-dom";

export function ImanNavigationItem(props: {
    link: string;
    messageId: string;
    messageDefault: string;
    icon: React.ReactNode;
    ariaLabel?: string;
}): JSX.Element {
    const history = useHistory();
    return (
        <ImanListItem>
            <ListItemButton aria-label={props.ariaLabel} onClick={() => history.push(props.link)}>
                <ListItemIcon sx={{ color: (theme) => theme.palette.primary.main }}>
                    {props.icon}
                </ListItemIcon>
                <ListItemText sx={{ color: (theme) => theme.palette.primary.main }}>
                    <FormattedMessage id={props.messageId} defaultMessage={props.messageDefault} />
                </ListItemText>
            </ListItemButton>
        </ImanListItem>
    );
}

export function ImanNavigationIcon(props: { link: string; icon: React.ReactNode }): JSX.Element {
    const history = useHistory();
    return (
        <IconButton size="large" color="inherit" onClick={() => history.push(props.link)}>
            {props.icon}
        </IconButton>
    );
}
