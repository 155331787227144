import React from "react";
import { Typography } from "@mui/material";

export const H1 = function (props: TypographyProps): JSX.Element {
    return HN("h1", props);
};

export const H2 = function (props: TypographyProps): JSX.Element {
    return HN("h2", props);
};

export const H3 = function (props: TypographyProps): JSX.Element {
    return HN("h3", props);
};

export const H4 = function (props: TypographyProps): JSX.Element {
    return HN("h4", props);
};

export const H5 = function (props: TypographyProps): JSX.Element {
    return HN("h5", props);
};

export const H6 = function (props: TypographyProps): JSX.Element {
    return HN("h6", props);
};

interface TypographyProps {
    children: React.ReactNode;
    paddingRight?: number;
    gutterBottom?: boolean;
    textAlign?: "left";
    sxColor?: string;
    className?: string;
}

function HN(variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6", props: TypographyProps): JSX.Element {
    return (
        <Typography
            variant={variant}
            paddingRight={props.paddingRight}
            gutterBottom={props.gutterBottom}
            textAlign={props.textAlign}
            sx={{ color: props.sxColor }}
            className={props.className}
        >
            {props.children}
        </Typography>
    );
}

export function Body1(props: { children: React.ReactNode }): JSX.Element {
    return (
        <Typography textAlign="left" variant="body1">
            {props.children}
        </Typography>
    );
}

export function Body2(props: { children: React.ReactNode }): JSX.Element {
    return (
        <Typography textAlign="left" variant="body2">
            {props.children}
        </Typography>
    );
}

export function Subtitle2(props: { children: React.ReactNode }): JSX.Element {
    return <Typography variant="subtitle2">{props.children}</Typography>;
}

export function Caption(props: { children: React.ReactNode }): JSX.Element {
    return (
        <Typography textAlign="left" variant="caption">
            {props.children}
        </Typography>
    );
}
