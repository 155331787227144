/* tslint:disable */
/* eslint-disable */
/**
 * Viaduct
 * The Viaduct API
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface ActivateScheduleResponse
 */
export interface ActivateScheduleResponse {
    /**
     *
     * @type {string}
     * @memberof ActivateScheduleResponse
     */
    result: ActivateScheduleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ActivateScheduleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidIdentifier = 'INVALID_IDENTIFIER'
}

/**
 *
 * @export
 * @interface AppInfo
 */
export interface AppInfo {
    /**
     *
     * @type {string}
     * @memberof AppInfo
     */
    company: string;
    /**
     *
     * @type {string}
     * @memberof AppInfo
     */
    environment: string;
    /**
     *
     * @type {Array<string>}
     * @memberof AppInfo
     */
    supportedLocales: Array<string>;
}
/**
 *
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     *
     * @type {string}
     * @memberof AuthResponse
     */
    jwt: string;
    /**
     *
     * @type {Array<PermissionForApi>}
     * @memberof AuthResponse
     */
    permissions: Array<PermissionForApi>;
    /**
     *
     * @type {UserForApi}
     * @memberof AuthResponse
     */
    user: UserForApi;
}
/**
 *
 * @export
 * @interface BlueprintBaseData
 */
export interface BlueprintBaseData {
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseData
     */
    identifier: string;
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseData
     */
    name: string;
}
/**
 *
 * @export
 * @interface BlueprintBaseDataForApi
 */
export interface BlueprintBaseDataForApi {
    /**
     *
     * @type {Array<string>}
     * @memberof BlueprintBaseDataForApi
     */
    connectedSystems: Array<string>;
    /**
     *
     * @type {boolean}
     * @memberof BlueprintBaseDataForApi
     */
    currentlyRunning: boolean;
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseDataForApi
     */
    identifier: string;
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseDataForApi
     */
    longDescription: string;
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseDataForApi
     */
    name: string;
    /**
     *
     * @type {number}
     * @memberof BlueprintBaseDataForApi
     */
    queueSize: number;
    /**
     *
     * @type {string}
     * @memberof BlueprintBaseDataForApi
     */
    shortDescription: string;
}
/**
 *
 * @export
 * @interface BlueprintMetadataForApi
 */
export interface BlueprintMetadataForApi {
    /**
     *
     * @type {boolean}
     * @memberof BlueprintMetadataForApi
     */
    currentlyRunning: boolean;
    /**
     *
     * @type {string}
     * @memberof BlueprintMetadataForApi
     */
    identifier: string;
    /**
     *
     * @type {JobStatistics}
     * @memberof BlueprintMetadataForApi
     */
    jobStatistics: JobStatistics;
    /**
     *
     * @type {JobForApi}
     * @memberof BlueprintMetadataForApi
     */
    lastRun?: JobForApi;
    /**
     *
     * @type {string}
     * @memberof BlueprintMetadataForApi
     */
    name: string;
    /**
     *
     * @type {ScheduleMetadataForApi}
     * @memberof BlueprintMetadataForApi
     */
    nextRun?: ScheduleMetadataForApi;
    /**
     *
     * @type {Array<BlueprintQueueEntryForApi>}
     * @memberof BlueprintMetadataForApi
     */
    queue: Array<BlueprintQueueEntryForApi>;
    /**
     *
     * @type {Array<ScheduleMetadataForApi>}
     * @memberof BlueprintMetadataForApi
     */
    schedules: Array<ScheduleMetadataForApi>;
}
/**
 *
 * @export
 * @interface BlueprintQueueEntryForApi
 */
export interface BlueprintQueueEntryForApi {
    /**
     *
     * @type {string}
     * @memberof BlueprintQueueEntryForApi
     */
    blueprintIdentifier: string;
    /**
     *
     * @type {Instant}
     * @memberof BlueprintQueueEntryForApi
     */
    queuedAt: Instant;
    /**
     *
     * @type {string}
     * @memberof BlueprintQueueEntryForApi
     */
    triggeredBy: BlueprintQueueEntryForApiTriggeredByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BlueprintQueueEntryForApiTriggeredByEnum {
    Scheduler = 'SCHEDULER',
    User = 'USER',
    ApiCall = 'API_CALL',
    Request = 'REQUEST',
    Slab = 'SLAB'
}

/**
 *
 * @export
 * @interface BlueprintsBaseDataResponse
 */
export interface BlueprintsBaseDataResponse {
    /**
     *
     * @type {Array<BlueprintBaseDataForApi>}
     * @memberof BlueprintsBaseDataResponse
     */
    blueprints: Array<BlueprintBaseDataForApi>;
}
/**
 *
 * @export
 * @interface BlueprintsListResponse
 */
export interface BlueprintsListResponse {
    /**
     *
     * @type {Array<BlueprintMetadataForApi>}
     * @memberof BlueprintsListResponse
     */
    blueprints: Array<BlueprintMetadataForApi>;
}
/**
 *
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
    /**
     *
     * @type {string}
     * @memberof ChangePasswordResponse
     */
    result: ChangePasswordResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ChangePasswordResponseResultEnum {
    Success = 'SUCCESS',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface CpuInfoForApi
 */
export interface CpuInfoForApi {
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    jvmAvailableProcessors: number;
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    processCpuLoad?: number | null;
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    runningThreads: number;
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    systemAvailableProcessors: number;
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    systemCpuLoad?: number | null;
    /**
     *
     * @type {number}
     * @memberof CpuInfoForApi
     */
    systemLoadAverage: number;
}
/**
 *
 * @export
 * @interface CreateBlueprintScheduleResponse
 */
export interface CreateBlueprintScheduleResponse {
    /**
     *
     * @type {string}
     * @memberof CreateBlueprintScheduleResponse
     */
    result: CreateBlueprintScheduleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateBlueprintScheduleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidExpression = 'INVALID_EXPRESSION',
    InvalidBlueprintIdentifier = 'INVALID_BLUEPRINT_IDENTIFIER',
    UnknownBlueprint = 'UNKNOWN_BLUEPRINT',
    UnknownError = 'UNKNOWN_ERROR',
    AlreadyExists = 'ALREADY_EXISTS',
    InsufficientRights = 'INSUFFICIENT_RIGHTS'
}

/**
 *
 * @export
 * @interface CreateRoleResponse
 */
export interface CreateRoleResponse {
    /**
     *
     * @type {string}
     * @memberof CreateRoleResponse
     */
    result: CreateRoleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateRoleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidName = 'INVALID_NAME'
}

/**
 *
 * @export
 * @interface CreateUserResponse
 */
export interface CreateUserResponse {
    /**
     *
     * @type {string}
     * @memberof CreateUserResponse
     */
    result: CreateUserResponseResultEnum;
    /**
     *
     * @type {string}
     * @memberof CreateUserResponse
     */
    singleUseToken?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum CreateUserResponseResultEnum {
    Success = 'SUCCESS',
    IdentifierNotUnique = 'IDENTIFIER_NOT_UNIQUE',
    InvalidEmailAddress = 'INVALID_EMAIL_ADDRESS',
    EmailAddressNotUnique = 'EMAIL_ADDRESS_NOT_UNIQUE',
    UnknownError = 'UNKNOWN_ERROR'
}

/**
 *
 * @export
 * @interface DeactivateScheduleResponse
 */
export interface DeactivateScheduleResponse {
    /**
     *
     * @type {string}
     * @memberof DeactivateScheduleResponse
     */
    result: DeactivateScheduleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeactivateScheduleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidIdentifier = 'INVALID_IDENTIFIER',
    InvalidTimeframe = 'INVALID_TIMEFRAME'
}

/**
 *
 * @export
 * @interface DeleteBlueprintScheduleResponse
 */
export interface DeleteBlueprintScheduleResponse {
    /**
     *
     * @type {string}
     * @memberof DeleteBlueprintScheduleResponse
     */
    result: DeleteBlueprintScheduleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeleteBlueprintScheduleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidIdentifier = 'INVALID_IDENTIFIER',
    UnknownIdentifier = 'UNKNOWN_IDENTIFIER',
    InsufficientRights = 'INSUFFICIENT_RIGHTS'
}

/**
 *
 * @export
 * @interface DeleteRoleResponse
 */
export interface DeleteRoleResponse {
    /**
     *
     * @type {string}
     * @memberof DeleteRoleResponse
     */
    result: DeleteRoleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeleteRoleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidData = 'INVALID_DATA'
}

/**
 *
 * @export
 * @interface DeleteUserResponse
 */
export interface DeleteUserResponse {
    /**
     *
     * @type {string}
     * @memberof DeleteUserResponse
     */
    result: DeleteUserResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeleteUserResponseResultEnum {
    Success = 'SUCCESS',
    UnknownUser = 'UNKNOWN_USER',
    CannotDeleteYourself = 'CANNOT_DELETE_YOURSELF'
}

/**
 *
 * @export
 * @interface DiskSpaceInfoForApi
 */
export interface DiskSpaceInfoForApi {
    /**
     *
     * @type {number}
     * @memberof DiskSpaceInfoForApi
     */
    freeDiskSpace: number;
    /**
     *
     * @type {number}
     * @memberof DiskSpaceInfoForApi
     */
    totalDiskSpace: number;
    /**
     *
     * @type {number}
     * @memberof DiskSpaceInfoForApi
     */
    usableDiskSpace: number;
}
/**
 *
 * @export
 * @interface EncryptStringResponse
 */
export interface EncryptStringResponse {
    /**
     *
     * @type {string}
     * @memberof EncryptStringResponse
     */
    encryptedString: string;
}
/**
 *
 * @export
 * @interface GetAllPermissionsResponse
 */
export interface GetAllPermissionsResponse {
    /**
     *
     * @type {Array<PermissionForApi>}
     * @memberof GetAllPermissionsResponse
     */
    permissions: Array<PermissionForApi>;
}
/**
 *
 * @export
 * @interface GetAllRolesResponse
 */
export interface GetAllRolesResponse {
    /**
     *
     * @type {Array<RoleForApi>}
     * @memberof GetAllRolesResponse
     */
    roles: Array<RoleForApi>;
}
/**
 *
 * @export
 * @interface GetAllUsersResponse
 */
export interface GetAllUsersResponse {
    /**
     *
     * @type {Array<UserForApi>}
     * @memberof GetAllUsersResponse
     */
    allUsers: Array<UserForApi>;
}
/**
 *
 * @export
 * @interface GetAllUsersWithRoleResponse
 */
export interface GetAllUsersWithRoleResponse {
    /**
     *
     * @type {Array<UserForApi>}
     * @memberof GetAllUsersWithRoleResponse
     */
    users: Array<UserForApi>;
}
/**
 *
 * @export
 * @interface GetJobResponse
 */
export interface GetJobResponse {
    /**
     *
     * @type {JobForApi}
     * @memberof GetJobResponse
     */
    jobData?: JobForApi;
}
/**
 *
 * @export
 * @interface Instant
 */
export interface Instant {
    /**
     *
     * @type {number}
     * @memberof Instant
     */
    epochSeconds: number;
    /**
     *
     * @type {number}
     * @memberof Instant
     */
    nanosecondsOfSecond: number;
}
/**
 *
 * @export
 * @interface JobForApi
 */
export interface JobForApi {
    /**
     *
     * @type {string}
     * @memberof JobForApi
     */
    blueprintIdentifier: string;
    /**
     *
     * @type {number}
     * @memberof JobForApi
     */
    elapsedMillis?: number | null;
    /**
     *
     * @type {string}
     * @memberof JobForApi
     */
    id: string;
    /**
     *
     * @type {number}
     * @memberof JobForApi
     */
    startedAtInEpochMillis: number;
    /**
     *
     * @type {string}
     * @memberof JobForApi
     */
    status: JobForApiStatusEnum;
    /**
     *
     * @type {string}
     * @memberof JobForApi
     */
    triggeredBy: JobForApiTriggeredByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum JobForApiStatusEnum {
    Running = 'RUNNING',
    Cancelled = 'CANCELLED',
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Red = 'RED'
}
/**
    * @export
    * @enum {string}
    */
export enum JobForApiTriggeredByEnum {
    Scheduler = 'SCHEDULER',
    User = 'USER',
    ApiCall = 'API_CALL',
    Request = 'REQUEST',
    Slab = 'SLAB'
}

/**
 *
 * @export
 * @interface JobStatistics
 */
export interface JobStatistics {
    /**
     *
     * @type {number}
     * @memberof JobStatistics
     */
    averageElapsedMillis?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobStatistics
     */
    maxElapsedMillis?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobStatistics
     */
    minElapsedMillis?: number | null;
    /**
     *
     * @type {number}
     * @memberof JobStatistics
     */
    numberOfDoneJobs: number;
    /**
     *
     * @type {number}
     * @memberof JobStatistics
     */
    numberOfSuccessfulJobs: number;
}
/**
 *
 * @export
 * @interface JobsMetadataResponse
 */
export interface JobsMetadataResponse {
    /**
     *
     * @type {Array<BlueprintBaseData>}
     * @memberof JobsMetadataResponse
     */
    allBlueprints: Array<BlueprintBaseData>;
    /**
     *
     * @type {Array<JobForApi>}
     * @memberof JobsMetadataResponse
     */
    jobs: Array<JobForApi>;
    /**
     *
     * @type {number}
     * @memberof JobsMetadataResponse
     */
    numberOfAllJobsWhenUnfiltered: number;
    /**
     *
     * @type {PagingMetadata}
     * @memberof JobsMetadataResponse
     */
    pagingMetaData: PagingMetadata;
    /**
     *
     * @type {JobsStatisticsForApi}
     * @memberof JobsMetadataResponse
     */
    statistics: JobsStatisticsForApi;
}
/**
 *
 * @export
 * @interface JobsStatisticsForApi
 */
export interface JobsStatisticsForApi {
    /**
     *
     * @type {number}
     * @memberof JobsStatisticsForApi
     */
    activeJobs: number;
    /**
     *
     * @type {number}
     * @memberof JobsStatisticsForApi
     */
    greenJobs: number;
    /**
     *
     * @type {number}
     * @memberof JobsStatisticsForApi
     */
    redJobs: number;
    /**
     *
     * @type {number}
     * @memberof JobsStatisticsForApi
     */
    yellowJobs: number;
}
/**
 *
 * @export
 * @interface LogEntryApi
 */
export interface LogEntryApi {
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    attachmentUniqueFilename?: string | null;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    blueprintIdentifier?: string | null;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    jobId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    level: LogEntryApiLevelEnum;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    localizedMessage: string;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    requestId?: string | null;
    /**
     *
     * @type {string}
     * @memberof LogEntryApi
     */
    subject: string;
    /**
     *
     * @type {number}
     * @memberof LogEntryApi
     */
    threadId: number;
    /**
     *
     * @type {number}
     * @memberof LogEntryApi
     */
    timestampInEpochMillis: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LogEntryApiLevelEnum {
    Trace = 'TRACE',
    Debug = 'DEBUG',
    Info = 'INFO',
    Warn = 'WARN',
    Error = 'ERROR',
    Fatal = 'FATAL'
}

/**
 *
 * @export
 * @interface LoggablesMetaListResponse
 */
export interface LoggablesMetaListResponse {
    /**
     *
     * @type {Array<string>}
     * @memberof LoggablesMetaListResponse
     */
    loggableNames: Array<string>;
}
/**
 *
 * @export
 * @interface LogsResponse
 */
export interface LogsResponse {
    /**
     *
     * @type {Array<LogEntryApi>}
     * @memberof LogsResponse
     */
    logEntries: Array<LogEntryApi>;
    /**
     *
     * @type {PagingMetadata}
     * @memberof LogsResponse
     */
    pagingMetaData: PagingMetadata;
}
/**
 *
 * @export
 * @interface MemoryInfoForApi
 */
export interface MemoryInfoForApi {
    /**
     *
     * @type {number}
     * @memberof MemoryInfoForApi
     */
    jvmFreeMemorySize: number;
    /**
     *
     * @type {number}
     * @memberof MemoryInfoForApi
     */
    jvmMaxMemorySize: number;
    /**
     *
     * @type {number}
     * @memberof MemoryInfoForApi
     */
    jvmTotalMemorySize: number;
    /**
     *
     * @type {number}
     * @memberof MemoryInfoForApi
     */
    systemFreeMemorySize?: number | null;
    /**
     *
     * @type {number}
     * @memberof MemoryInfoForApi
     */
    systemTotalMemorySize?: number | null;
}
/**
 *
 * @export
 * @interface MyInputTypeBodyWrapper
 */
export interface MyInputTypeBodyWrapper {
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof MyInputTypeBodyWrapper
     */
    colorList: Array<Array<MyInputTypeBodyWrapperColorListEnum>>;
    /**
     *
     * @type {Array<Array<SomeDataClass>>}
     * @memberof MyInputTypeBodyWrapper
     */
    dataList: Array<Array<SomeDataClass>>;
    /**
     *
     * @type {number}
     * @memberof MyInputTypeBodyWrapper
     */
    myIntValue: number;
    /**
     *
     * @type {string}
     * @memberof MyInputTypeBodyWrapper
     */
    myStrValue: string;
    /**
     *
     * @type {SomeDataClass}
     * @memberof MyInputTypeBodyWrapper
     */
    nested: SomeDataClass;
    /**
     *
     * @type {Array<Array<string>>}
     * @memberof MyInputTypeBodyWrapper
     */
    strList: Array<Array<string>>;
}

/**
    * @export
    * @enum {string}
    */
export enum MyInputTypeBodyWrapperColorListEnum {
    Blue = 'BLUE',
    White = 'WHITE'
}

/**
 *
 * @export
 * @interface MyReturnType1Wrapper
 */
export interface MyReturnType1Wrapper {
    /**
     *
     * @type {string}
     * @memberof MyReturnType1Wrapper
     */
    value: string;
}
/**
 *
 * @export
 * @interface MyReturnType2Wrapper
 */
export interface MyReturnType2Wrapper {
    /**
     *
     * @type {number}
     * @memberof MyReturnType2Wrapper
     */
    myDouble: number;
    /**
     *
     * @type {number}
     * @memberof MyReturnType2Wrapper
     */
    myFloat: number;
    /**
     *
     * @type {number}
     * @memberof MyReturnType2Wrapper
     */
    myInt: number;
    /**
     *
     * @type {string}
     * @memberof MyReturnType2Wrapper
     */
    myString: string;
}
/**
 *
 * @export
 * @interface MyReturnTypeWrapper
 */
export interface MyReturnTypeWrapper {
    /**
     *
     * @type {number}
     * @memberof MyReturnTypeWrapper
     */
    myInt: number;
    /**
     *
     * @type {string}
     * @memberof MyReturnTypeWrapper
     */
    myString: string;
}
/**
 *
 * @export
 * @interface OperatingSystemInfoForApi
 */
export interface OperatingSystemInfoForApi {
    /**
     *
     * @type {string}
     * @memberof OperatingSystemInfoForApi
     */
    architecture: string;
    /**
     *
     * @type {string}
     * @memberof OperatingSystemInfoForApi
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof OperatingSystemInfoForApi
     */
    version: string;
}
/**
 *
 * @export
 * @interface PagingMetadata
 */
export interface PagingMetadata {
    /**
     *
     * @type {number}
     * @memberof PagingMetadata
     */
    currentPage: number;
    /**
     *
     * @type {number}
     * @memberof PagingMetadata
     */
    entriesPerPage: number;
    /**
     *
     * @type {number}
     * @memberof PagingMetadata
     */
    numberOfItems: number;
    /**
     *
     * @type {number}
     * @memberof PagingMetadata
     */
    numberOfPages: number;
    /**
     *
     * @type {number}
     * @memberof PagingMetadata
     */
    timestampOfNewestEntryInEpochMillis?: number | null;
}
/**
 *
 * @export
 * @interface PermissionForApi
 */
export interface PermissionForApi {
    /**
     *
     * @type {string}
     * @memberof PermissionForApi
     */
    group: PermissionForApiGroupEnum;
    /**
     *
     * @type {string}
     * @memberof PermissionForApi
     */
    permissionType: PermissionForApiPermissionTypeEnum;
    /**
     *
     * @type {string}
     * @memberof PermissionForApi
     */
    serialized: string;
    /**
     *
     * @type {string}
     * @memberof PermissionForApi
     */
    subgroup?: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum PermissionForApiGroupEnum {
    General = 'GENERAL',
    UserData = 'USER_DATA',
    BlueprintsGeneral = 'BLUEPRINTS_GENERAL',
    BlueprintSpecific = 'BLUEPRINT_SPECIFIC'
}
/**
    * @export
    * @enum {string}
    */
export enum PermissionForApiPermissionTypeEnum {
    VdctAll = 'VDCT_ALL',
    ReadSystemLogs = 'READ_SYSTEM_LOGS',
    ReadSystemInfo = 'READ_SYSTEM_INFO',
    StopApplication = 'STOP_APPLICATION',
    ReadUserData = 'READ_USER_DATA',
    CreateUsers = 'CREATE_USERS',
    UpdateUsers = 'UPDATE_USERS',
    DeleteUsers = 'DELETE_USERS',
    AssignRolesToUsers = 'ASSIGN_ROLES_TO_USERS',
    CreateRoles = 'CREATE_ROLES',
    EditRoles = 'EDIT_ROLES',
    DeleteRoles = 'DELETE_ROLES',
    ReadLogsOfAllBlueprints = 'READ_LOGS_OF_ALL_BLUEPRINTS',
    ReadDebugAndTraceLogs = 'READ_DEBUG_AND_TRACE_LOGS',
    StartAnyBlueprint = 'START_ANY_BLUEPRINT',
    StopJobsOfAnyBlueprint = 'STOP_JOBS_OF_ANY_BLUEPRINT',
    ReadAllBlueprints = 'READ_ALL_BLUEPRINTS',
    ReadAttachmentsOfAllBlueprints = 'READ_ATTACHMENTS_OF_ALL_BLUEPRINTS',
    CreateSchedulesForAnyBlueprint = 'CREATE_SCHEDULES_FOR_ANY_BLUEPRINT',
    DeleteSchedulesOfAnyBlueprint = 'DELETE_SCHEDULES_OF_ANY_BLUEPRINT',
    UpdateSchedulesOfAnyBlueprint = 'UPDATE_SCHEDULES_OF_ANY_BLUEPRINT',
    ActivateAndDeactivateSchedulesOfAnyBlueprint = 'ACTIVATE_AND_DEACTIVATE_SCHEDULES_OF_ANY_BLUEPRINT',
    ReadBlueprint = 'READ_BLUEPRINT',
    StartBlueprint = 'START_BLUEPRINT',
    StopJobsOfBlueprint = 'STOP_JOBS_OF_BLUEPRINT',
    ActivateAndDeactivateSchedulesOfBlueprint = 'ACTIVATE_AND_DEACTIVATE_SCHEDULES_OF_BLUEPRINT',
    CreateSchedulesForBlueprint = 'CREATE_SCHEDULES_FOR_BLUEPRINT',
    DeleteSchedulesOfBlueprint = 'DELETE_SCHEDULES_OF_BLUEPRINT',
    UpdateSchedulesOfBlueprint = 'UPDATE_SCHEDULES_OF_BLUEPRINT',
    ReadLogsOfBlueprint = 'READ_LOGS_OF_BLUEPRINT',
    ReadAttachmentsOfBlueprint = 'READ_ATTACHMENTS_OF_BLUEPRINT'
}

/**
 *
 * @export
 * @interface RoleForApi
 */
export interface RoleForApi {
    /**
     *
     * @type {string}
     * @memberof RoleForApi
     */
    identifier: string;
    /**
     *
     * @type {string}
     * @memberof RoleForApi
     */
    name: string;
    /**
     *
     * @type {Array<PermissionForApi>}
     * @memberof RoleForApi
     */
    permissions: Array<PermissionForApi>;
}
/**
 *
 * @export
 * @interface RunningJobMetadataForApi
 */
export interface RunningJobMetadataForApi {
    /**
     *
     * @type {string}
     * @memberof RunningJobMetadataForApi
     */
    blueprintIdentifier: string;
    /**
     *
     * @type {string}
     * @memberof RunningJobMetadataForApi
     */
    jobIdentifier: string;
    /**
     *
     * @type {number}
     * @memberof RunningJobMetadataForApi
     */
    startedAtInEpochMillis: number;
    /**
     *
     * @type {string}
     * @memberof RunningJobMetadataForApi
     */
    status: RunningJobMetadataForApiStatusEnum;
    /**
     *
     * @type {string}
     * @memberof RunningJobMetadataForApi
     */
    triggeredBy: RunningJobMetadataForApiTriggeredByEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RunningJobMetadataForApiStatusEnum {
    Running = 'RUNNING',
    Cancelled = 'CANCELLED'
}
/**
    * @export
    * @enum {string}
    */
export enum RunningJobMetadataForApiTriggeredByEnum {
    Scheduler = 'SCHEDULER',
    User = 'USER',
    ApiCall = 'API_CALL',
    Request = 'REQUEST',
    Slab = 'SLAB'
}

/**
 *
 * @export
 * @interface RunningJobsMetadataResponse
 */
export interface RunningJobsMetadataResponse {
    /**
     *
     * @type {Array<RunningJobMetadataForApi>}
     * @memberof RunningJobsMetadataResponse
     */
    jobs: Array<RunningJobMetadataForApi>;
}
/**
 *
 * @export
 * @interface ScheduleMetadataForApi
 */
export interface ScheduleMetadataForApi {
    /**
     *
     * @type {string}
     * @memberof ScheduleMetadataForApi
     */
    deactivateEnd?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScheduleMetadataForApi
     */
    deactivateStart?: string | null;
    /**
     *
     * @type {string}
     * @memberof ScheduleMetadataForApi
     */
    identifier: string;
    /**
     *
     * @type {boolean}
     * @memberof ScheduleMetadataForApi
     */
    isCurrentlyDeactivated: boolean;
    /**
     *
     * @type {number}
     * @memberof ScheduleMetadataForApi
     */
    lastActualExecutionInEpochMillis?: number | null;
    /**
     *
     * @type {number}
     * @memberof ScheduleMetadataForApi
     */
    nextExecutionInEpochMillis?: number | null;
    /**
     *
     * @type {string}
     * @memberof ScheduleMetadataForApi
     */
    scheduleAsCronExpression?: string | null;
}
/**
 *
 * @export
 * @interface SetPasswordResponse
 */
export interface SetPasswordResponse {
    /**
     *
     * @type {string}
     * @memberof SetPasswordResponse
     */
    result: SetPasswordResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SetPasswordResponseResultEnum {
    Success = 'SUCCESS',
    TokenInvalid = 'TOKEN_INVALID',
    TokenExpired = 'TOKEN_EXPIRED'
}

/**
 *
 * @export
 * @interface SomeDataClass
 */
export interface SomeDataClass {
    /**
     *
     * @type {boolean}
     * @memberof SomeDataClass
     */
    isTrue: boolean;
}
/**
 *
 * @export
 * @interface StartJobResponse
 */
export interface StartJobResponse {
    /**
     *
     * @type {string}
     * @memberof StartJobResponse
     */
    result: StartJobResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StartJobResponseResultEnum {
    Success = 'SUCCESS',
    JobQueued = 'JOB_QUEUED',
    JobQueueFull = 'JOB_QUEUE_FULL',
    UnknownBlueprint = 'UNKNOWN_BLUEPRINT',
    Nothing = 'NOTHING'
}

/**
 *
 * @export
 * @interface StopApplicationResponse
 */
export interface StopApplicationResponse {
    /**
     *
     * @type {string}
     * @memberof StopApplicationResponse
     */
    result: StopApplicationResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StopApplicationResponseResultEnum {
    Success = 'SUCCESS',
    InvalidPassword = 'INVALID_PASSWORD',
    CouldNotStop = 'COULD_NOT_STOP'
}

/**
 *
 * @export
 * @interface StopJobResponse
 */
export interface StopJobResponse {
    /**
     *
     * @type {string}
     * @memberof StopJobResponse
     */
    result: StopJobResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StopJobResponseResultEnum {
    Success = 'SUCCESS',
    NotRunning = 'NOT_RUNNING',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface SystemInfoResponse
 */
export interface SystemInfoResponse {
    /**
     *
     * @type {CpuInfoForApi}
     * @memberof SystemInfoResponse
     */
    cpuInfo: CpuInfoForApi;
    /**
     *
     * @type {DiskSpaceInfoForApi}
     * @memberof SystemInfoResponse
     */
    diskSpaceInfo: DiskSpaceInfoForApi;
    /**
     *
     * @type {MemoryInfoForApi}
     * @memberof SystemInfoResponse
     */
    memoryInfo: MemoryInfoForApi;
    /**
     *
     * @type {OperatingSystemInfoForApi}
     * @memberof SystemInfoResponse
     */
    operatingSystemInfo: OperatingSystemInfoForApi;
}
/**
 *
 * @export
 * @interface UpdateBlueprintScheduleResponse
 */
export interface UpdateBlueprintScheduleResponse {
    /**
     *
     * @type {string}
     * @memberof UpdateBlueprintScheduleResponse
     */
    result: UpdateBlueprintScheduleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateBlueprintScheduleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidExpression = 'INVALID_EXPRESSION',
    InvalidIdentifier = 'INVALID_IDENTIFIER',
    UnknownError = 'UNKNOWN_ERROR',
    AlreadyExists = 'ALREADY_EXISTS'
}

/**
 *
 * @export
 * @interface UpdateRoleResponse
 */
export interface UpdateRoleResponse {
    /**
     *
     * @type {string}
     * @memberof UpdateRoleResponse
     */
    result: UpdateRoleResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateRoleResponseResultEnum {
    Success = 'SUCCESS',
    InvalidData = 'INVALID_DATA'
}

/**
 *
 * @export
 * @interface UpdateUserDataResponse
 */
export interface UpdateUserDataResponse {
    /**
     *
     * @type {string}
     * @memberof UpdateUserDataResponse
     */
    result: UpdateUserDataResponseResultEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateUserDataResponseResultEnum {
    Success = 'SUCCESS',
    InvalidEmail = 'INVALID_EMAIL',
    EmailNotUnique = 'EMAIL_NOT_UNIQUE',
    Error = 'ERROR'
}

/**
 *
 * @export
 * @interface UserForApi
 */
export interface UserForApi {
    /**
     *
     * @type {string}
     * @memberof UserForApi
     */
    emailAddress: string;
    /**
     *
     * @type {string}
     * @memberof UserForApi
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof UserForApi
     */
    name: string;
    /**
     *
     * @type {Array<RoleForApi>}
     * @memberof UserForApi
     */
    roles: Array<RoleForApi>;
    /**
     *
     * @type {string}
     * @memberof UserForApi
     */
    state: UserForApiStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UserForApiStateEnum {
    Active = 'ACTIVE',
    RegistrationPending = 'REGISTRATION_PENDING'
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/app/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {'SOFT' | 'HARD'} stopMode stop type
         * @param {string} password confirm with password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppStopPost: async (stopMode: 'SOFT' | 'HARD', password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stopMode' is not null or undefined
            assertParamExists('apiV1InternalAppStopPost', 'stopMode', stopMode)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV1InternalAppStopPost', 'password', password)
            const localVarPath = `/api/v1/internal/app/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (stopMode !== undefined) {
                localVarQueryParameter['stopMode'] = stopMode;
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['password'] = String(password);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppSystemGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/app/system`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsBaseGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/blueprints/base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/blueprints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} scheduleIds schedule ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleActivatePost: async (scheduleIds: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleIds' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleActivatePost', 'scheduleIds', scheduleIds)
            const localVarPath = `/api/v1/internal/blueprints/schedule/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scheduleIds) {
                localVarQueryParameter['scheduleIds'] = scheduleIds;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint identifier
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleCreatePost: async (blueprintIdentifier: string, newCronExpression: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintIdentifier' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleCreatePost', 'blueprintIdentifier', blueprintIdentifier)
            // verify required parameter 'newCronExpression' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleCreatePost', 'newCronExpression', newCronExpression)
            const localVarPath = `/api/v1/internal/blueprints/schedule/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (blueprintIdentifier !== undefined) {
                localVarQueryParameter['blueprintIdentifier'] = blueprintIdentifier;
            }

            if (newCronExpression !== undefined) {
                localVarQueryParameter['newCronExpression'] = newCronExpression;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} scheduleIdentifiers schedule identifiers
         * @param {string} [deactivationStart] deactivate timeframe start
         * @param {string} [deactivationEnd] deactivate timeframe end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleDeactivatePost: async (scheduleIdentifiers: Array<string>, deactivationStart?: string, deactivationEnd?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleIdentifiers' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleDeactivatePost', 'scheduleIdentifiers', scheduleIdentifiers)
            const localVarPath = `/api/v1/internal/blueprints/schedule/deactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scheduleIdentifiers) {
                localVarQueryParameter['scheduleIdentifiers'] = scheduleIdentifiers;
            }

            if (deactivationStart !== undefined) {
                localVarQueryParameter['deactivationStart'] = deactivationStart;
            }

            if (deactivationEnd !== undefined) {
                localVarQueryParameter['deactivationEnd'] = deactivationEnd;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} scheduleId schedule identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleDeletePost: async (scheduleId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleDeletePost', 'scheduleId', scheduleId)
            const localVarPath = `/api/v1/internal/blueprints/schedule/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scheduleId !== undefined) {
                localVarQueryParameter['scheduleId'] = scheduleId;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} scheduleId schedule id
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleUpdatePost: async (scheduleId: string, newCronExpression: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleUpdatePost', 'scheduleId', scheduleId)
            // verify required parameter 'newCronExpression' is not null or undefined
            assertParamExists('apiV1InternalBlueprintsScheduleUpdatePost', 'newCronExpression', newCronExpression)
            const localVarPath = `/api/v1/internal/blueprints/schedule/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (scheduleId !== undefined) {
                localVarQueryParameter['scheduleId'] = scheduleId;
            }

            if (newCronExpression !== undefined) {
                localVarQueryParameter['newCronExpression'] = newCronExpression;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {Array<string>} [blueprints] filter for jobs by blueprintIdentifier
         * @param {'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED'} [status] filter for jobs with this status
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsAllGet: async (page: number, entriesPerPage: number, blueprints?: Array<string>, status?: 'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED', rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiV1InternalJobsAllGet', 'page', page)
            // verify required parameter 'entriesPerPage' is not null or undefined
            assertParamExists('apiV1InternalJobsAllGet', 'entriesPerPage', entriesPerPage)
            const localVarPath = `/api/v1/internal/jobs/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (entriesPerPage !== undefined) {
                localVarQueryParameter['entriesPerPage'] = entriesPerPage;
            }

            if (blueprints) {
                localVarQueryParameter['blueprints'] = blueprints;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (rangeStartInEpochMillis !== undefined) {
                localVarQueryParameter['rangeStartInEpochMillis'] = rangeStartInEpochMillis;
            }

            if (rangeEndInEpochMillis !== undefined) {
                localVarQueryParameter['rangeEndInEpochMillis'] = rangeEndInEpochMillis;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} jobId job id
         * @param {string} locale locale code
         * @param {boolean} [prettyPrint] pretty print json?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsDownloadDataGet: async (jobId: string, locale: string, prettyPrint?: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('apiV1InternalJobsDownloadDataGet', 'jobId', jobId)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('apiV1InternalJobsDownloadDataGet', 'locale', locale)
            const localVarPath = `/api/v1/internal/jobs/download-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (prettyPrint !== undefined) {
                localVarQueryParameter['prettyPrint'] = prettyPrint;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} jobIdentifier job identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsGet: async (jobIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobIdentifier' is not null or undefined
            assertParamExists('apiV1InternalJobsGet', 'jobIdentifier', jobIdentifier)
            const localVarPath = `/api/v1/internal/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobIdentifier !== undefined) {
                localVarQueryParameter['jobIdentifier'] = jobIdentifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<string>} [blueprintIdentifiers] optional list of blueprint identifiers for which to get running jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsRunningGet: async (blueprintIdentifiers?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/jobs/running`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (blueprintIdentifiers) {
                localVarQueryParameter['blueprintIdentifiers'] = blueprintIdentifiers;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint that should be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsStartPost: async (blueprintIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blueprintIdentifier' is not null or undefined
            assertParamExists('apiV1InternalJobsStartPost', 'blueprintIdentifier', blueprintIdentifier)
            const localVarPath = `/api/v1/internal/jobs/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (blueprintIdentifier !== undefined) {
                localVarQueryParameter['blueprintIdentifier'] = blueprintIdentifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} jobIdentifier blueprint that should be executed
         * @param {'SOFT' | 'HARD'} type Cancellation type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsStopPost: async (jobIdentifier: string, type: 'SOFT' | 'HARD', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobIdentifier' is not null or undefined
            assertParamExists('apiV1InternalJobsStopPost', 'jobIdentifier', jobIdentifier)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('apiV1InternalJobsStopPost', 'type', type)
            const localVarPath = `/api/v1/internal/jobs/stop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobIdentifier !== undefined) {
                localVarQueryParameter['jobIdentifier'] = jobIdentifier;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} emailaddress email
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLoginPost: async (emailaddress: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailaddress' is not null or undefined
            assertParamExists('apiV1InternalLoginPost', 'emailaddress', emailaddress)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV1InternalLoginPost', 'password', password)
            const localVarPath = `/api/v1/internal/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emailaddress !== undefined && emailaddress !== null) {
                localVarHeaderParameter['emailaddress'] = String(emailaddress);
            }

            if (password !== undefined && password !== null) {
                localVarHeaderParameter['password'] = String(password);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogoutPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {string} subject subject
         * @param {'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL'} level level
         * @param {string} localeCode locale code
         * @param {'NEWEST_FIRST' | 'OLDEST_FIRST'} sort sort
         * @param {Array<string>} [loggableTypes] loggableTypes
         * @param {Array<string>} [blueprintIdentifiers] blueprint names
         * @param {Array<string>} [jobIds] is any jobId matching
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {number} [logsBeforeEpochMillis] logs after
         * @param {string} [freeText] free text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogsGet: async (page: number, entriesPerPage: number, subject: string, level: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL', localeCode: string, sort: 'NEWEST_FIRST' | 'OLDEST_FIRST', loggableTypes?: Array<string>, blueprintIdentifiers?: Array<string>, jobIds?: Array<string>, rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, logsBeforeEpochMillis?: number, freeText?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'page', page)
            // verify required parameter 'entriesPerPage' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'entriesPerPage', entriesPerPage)
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'subject', subject)
            // verify required parameter 'level' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'level', level)
            // verify required parameter 'localeCode' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'localeCode', localeCode)
            // verify required parameter 'sort' is not null or undefined
            assertParamExists('apiV1InternalLogsGet', 'sort', sort)
            const localVarPath = `/api/v1/internal/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (entriesPerPage !== undefined) {
                localVarQueryParameter['entriesPerPage'] = entriesPerPage;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }

            if (loggableTypes) {
                localVarQueryParameter['loggableTypes'] = loggableTypes;
            }

            if (blueprintIdentifiers) {
                localVarQueryParameter['blueprintIdentifiers'] = blueprintIdentifiers;
            }

            if (jobIds) {
                localVarQueryParameter['jobIds'] = jobIds;
            }

            if (localeCode !== undefined) {
                localVarQueryParameter['localeCode'] = localeCode;
            }

            if (rangeStartInEpochMillis !== undefined) {
                localVarQueryParameter['rangeStartInEpochMillis'] = rangeStartInEpochMillis;
            }

            if (rangeEndInEpochMillis !== undefined) {
                localVarQueryParameter['rangeEndInEpochMillis'] = rangeEndInEpochMillis;
            }

            if (logsBeforeEpochMillis !== undefined) {
                localVarQueryParameter['logsBeforeEpochMillis'] = logsBeforeEpochMillis;
            }

            if (freeText !== undefined) {
                localVarQueryParameter['freeText'] = freeText;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogsTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/logs/types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalRefreshPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} resourceId resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalResourcesResourceIdGet: async (resourceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resourceId' is not null or undefined
            assertParamExists('apiV1InternalResourcesResourceIdGet', 'resourceId', resourceId)
            const localVarPath = `/api/v1/internal/resources/{resourceId}`
                .replace(`{${"resourceId"}}`, encodeURIComponent(String(resourceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserAllGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/user/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} emailaddress email address
         * @param {string} oldpassword old password
         * @param {string} newpassword new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserChangePasswordPost: async (emailaddress: string, oldpassword: string, newpassword: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'emailaddress' is not null or undefined
            assertParamExists('apiV1InternalUserChangePasswordPost', 'emailaddress', emailaddress)
            // verify required parameter 'oldpassword' is not null or undefined
            assertParamExists('apiV1InternalUserChangePasswordPost', 'oldpassword', oldpassword)
            // verify required parameter 'newpassword' is not null or undefined
            assertParamExists('apiV1InternalUserChangePasswordPost', 'newpassword', newpassword)
            const localVarPath = `/api/v1/internal/user/change_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (emailaddress !== undefined && emailaddress !== null) {
                localVarHeaderParameter['emailaddress'] = String(emailaddress);
            }

            if (oldpassword !== undefined && oldpassword !== null) {
                localVarHeaderParameter['oldpassword'] = String(oldpassword);
            }

            if (newpassword !== undefined && newpassword !== null) {
                localVarHeaderParameter['newpassword'] = String(newpassword);
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} identifier identifier of the user to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserDelete: async (identifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'identifier' is not null or undefined
            assertParamExists('apiV1InternalUserDelete', 'identifier', identifier)
            const localVarPath = `/api/v1/internal/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (identifier !== undefined) {
                localVarQueryParameter['identifier'] = identifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} name username
         * @param {string} emailAddress email address
         * @param {Array<string>} roles roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserNewPost: async (name: string, emailAddress: string, roles: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1InternalUserNewPost', 'name', name)
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiV1InternalUserNewPost', 'emailAddress', emailAddress)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('apiV1InternalUserNewPost', 'roles', roles)
            const localVarPath = `/api/v1/internal/user/new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserPermissionsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/user/permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id user identifier
         * @param {string} name new name of user
         * @param {string} emailAddress new email of user
         * @param {Array<string>} roles new roles of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserPut: async (id: string, name: string, emailAddress: string, roles: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiV1InternalUserPut', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1InternalUserPut', 'name', name)
            // verify required parameter 'emailAddress' is not null or undefined
            assertParamExists('apiV1InternalUserPut', 'emailAddress', emailAddress)
            // verify required parameter 'roles' is not null or undefined
            assertParamExists('apiV1InternalUserPut', 'roles', roles)
            const localVarPath = `/api/v1/internal/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (emailAddress !== undefined) {
                localVarQueryParameter['emailAddress'] = emailAddress;
            }

            if (roles) {
                localVarQueryParameter['roles'] = roles;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} name name of the role
         * @param {Array<string>} accessRights access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesCreatePost: async (name: string, accessRights: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1InternalUserRolesCreatePost', 'name', name)
            // verify required parameter 'accessRights' is not null or undefined
            assertParamExists('apiV1InternalUserRolesCreatePost', 'accessRights', accessRights)
            const localVarPath = `/api/v1/internal/user/roles/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (accessRights) {
                localVarQueryParameter['accessRights'] = accessRights;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesDeletePost: async (roleIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdentifier' is not null or undefined
            assertParamExists('apiV1InternalUserRolesDeletePost', 'roleIdentifier', roleIdentifier)
            const localVarPath = `/api/v1/internal/user/roles/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleIdentifier !== undefined) {
                localVarQueryParameter['roleIdentifier'] = roleIdentifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} roleIdentifier role identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesDetailsGet: async (roleIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdentifier' is not null or undefined
            assertParamExists('apiV1InternalUserRolesDetailsGet', 'roleIdentifier', roleIdentifier)
            const localVarPath = `/api/v1/internal/user/roles/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleIdentifier !== undefined) {
                localVarQueryParameter['roleIdentifier'] = roleIdentifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/internal/user/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {string} name name of the role
         * @param {Array<string>} permissions access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesUpdatePost: async (roleIdentifier: string, name: string, permissions: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleIdentifier' is not null or undefined
            assertParamExists('apiV1InternalUserRolesUpdatePost', 'roleIdentifier', roleIdentifier)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiV1InternalUserRolesUpdatePost', 'name', name)
            // verify required parameter 'permissions' is not null or undefined
            assertParamExists('apiV1InternalUserRolesUpdatePost', 'permissions', permissions)
            const localVarPath = `/api/v1/internal/user/roles/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleIdentifier !== undefined) {
                localVarQueryParameter['roleIdentifier'] = roleIdentifier;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (permissions) {
                localVarQueryParameter['permissions'] = permissions;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} token token
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserSetPasswordPost: async (token: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('apiV1InternalUserSetPasswordPost', 'token', token)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('apiV1InternalUserSetPasswordPost', 'password', password)
            const localVarPath = `/api/v1/internal/user/set_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} userIdentifier the user\&#39;s identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserTokenGet: async (userIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userIdentifier' is not null or undefined
            assertParamExists('apiV1InternalUserTokenGet', 'userIdentifier', userIdentifier)
            const localVarPath = `/api/v1/internal/user/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userIdentifier !== undefined) {
                localVarQueryParameter['userIdentifier'] = userIdentifier;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} value value to be encrypted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUtilEncryptPost: async (value: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            assertParamExists('apiV1InternalUtilEncryptPost', 'value', value)
            const localVarPath = `/api/v1/internal/util/encrypt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} myString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelSimpleGetGet: async (myString: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'myString' is not null or undefined
            assertParamExists('apiV1SomelevelSimpleGetGet', 'myString', myString)
            const localVarPath = `/api/v1/somelevel/simple-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (myString !== undefined) {
                localVarQueryParameter['myString'] = myString;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} queryName the name description
         * @param {SomeDataClass} queryNested nested description
         * @param {string} headername the name description header
         * @param {SomeDataClass} headernested nested description header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelWebserviceBlueprintGetGet: async (queryName: string, queryNested: SomeDataClass, headername: string, headernested: SomeDataClass, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryName' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintGetGet', 'queryName', queryName)
            // verify required parameter 'queryNested' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintGetGet', 'queryNested', queryNested)
            // verify required parameter 'headername' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintGetGet', 'headername', headername)
            // verify required parameter 'headernested' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintGetGet', 'headernested', headernested)
            const localVarPath = `/api/v1/somelevel/webservice-blueprint-get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (queryName !== undefined) {
                localVarQueryParameter['queryName'] = queryName;
            }

            if (queryNested !== undefined) {
                localVarQueryParameter['queryNested'] = queryNested;
            }

            if (headername !== undefined && headername !== null) {
                localVarHeaderParameter['headername'] = String(headername);
            }

            if (headernested !== undefined && headernested !== null) {
                localVarHeaderParameter['headernested'] = String(JSON.stringify(headernested));
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} someId some id
         * @param {string} myheadervalue a value in the header
         * @param {MyInputTypeBodyWrapper} [myInputTypeBodyWrapper]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelWebserviceBlueprintPostPost: async (someId: string, myheadervalue: string, myInputTypeBodyWrapper?: MyInputTypeBodyWrapper, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'someId' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintPostPost', 'someId', someId)
            // verify required parameter 'myheadervalue' is not null or undefined
            assertParamExists('apiV1SomelevelWebserviceBlueprintPostPost', 'myheadervalue', myheadervalue)
            const localVarPath = `/api/v1/somelevel/webservice-blueprint-post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (someId !== undefined) {
                localVarQueryParameter['someId'] = someId;
            }

            if (myheadervalue !== undefined && myheadervalue !== null) {
                localVarHeaderParameter['myheadervalue'] = String(myheadervalue);
            }



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(myInputTypeBodyWrapper, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalAppInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalAppInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {'SOFT' | 'HARD'} stopMode stop type
         * @param {string} password confirm with password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalAppStopPost(stopMode: 'SOFT' | 'HARD', password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalAppStopPost(stopMode, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalAppSystemGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalAppSystemGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsBaseGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintsBaseDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsBaseGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlueprintsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} scheduleIds schedule ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsScheduleActivatePost(scheduleIds: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsScheduleActivatePost(scheduleIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint identifier
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsScheduleCreatePost(blueprintIdentifier: string, newCronExpression: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateBlueprintScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsScheduleCreatePost(blueprintIdentifier, newCronExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} scheduleIdentifiers schedule identifiers
         * @param {string} [deactivationStart] deactivate timeframe start
         * @param {string} [deactivationEnd] deactivate timeframe end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsScheduleDeactivatePost(scheduleIdentifiers: Array<string>, deactivationStart?: string, deactivationEnd?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeactivateScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsScheduleDeactivatePost(scheduleIdentifiers, deactivationStart, deactivationEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} scheduleId schedule identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsScheduleDeletePost(scheduleId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteBlueprintScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsScheduleDeletePost(scheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} scheduleId schedule id
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalBlueprintsScheduleUpdatePost(scheduleId: string, newCronExpression: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBlueprintScheduleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalBlueprintsScheduleUpdatePost(scheduleId, newCronExpression, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {Array<string>} [blueprints] filter for jobs by blueprintIdentifier
         * @param {'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED'} [status] filter for jobs with this status
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsAllGet(page: number, entriesPerPage: number, blueprints?: Array<string>, status?: 'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED', rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobsMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsAllGet(page, entriesPerPage, blueprints, status, rangeStartInEpochMillis, rangeEndInEpochMillis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} jobId job id
         * @param {string} locale locale code
         * @param {boolean} [prettyPrint] pretty print json?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsDownloadDataGet(jobId: string, locale: string, prettyPrint?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsDownloadDataGet(jobId, locale, prettyPrint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} jobIdentifier job identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsGet(jobIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsGet(jobIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} [blueprintIdentifiers] optional list of blueprint identifiers for which to get running jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsRunningGet(blueprintIdentifiers?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RunningJobsMetadataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsRunningGet(blueprintIdentifiers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint that should be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsStartPost(blueprintIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsStartPost(blueprintIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} jobIdentifier blueprint that should be executed
         * @param {'SOFT' | 'HARD'} type Cancellation type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalJobsStopPost(jobIdentifier: string, type: 'SOFT' | 'HARD', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StopJobResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalJobsStopPost(jobIdentifier, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} emailaddress email
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalLoginPost(emailaddress: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalLoginPost(emailaddress, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalLogoutPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {string} subject subject
         * @param {'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL'} level level
         * @param {string} localeCode locale code
         * @param {'NEWEST_FIRST' | 'OLDEST_FIRST'} sort sort
         * @param {Array<string>} [loggableTypes] loggableTypes
         * @param {Array<string>} [blueprintIdentifiers] blueprint names
         * @param {Array<string>} [jobIds] is any jobId matching
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {number} [logsBeforeEpochMillis] logs after
         * @param {string} [freeText] free text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalLogsGet(page: number, entriesPerPage: number, subject: string, level: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL', localeCode: string, sort: 'NEWEST_FIRST' | 'OLDEST_FIRST', loggableTypes?: Array<string>, blueprintIdentifiers?: Array<string>, jobIds?: Array<string>, rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, logsBeforeEpochMillis?: number, freeText?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalLogsGet(page, entriesPerPage, subject, level, localeCode, sort, loggableTypes, blueprintIdentifiers, jobIds, rangeStartInEpochMillis, rangeEndInEpochMillis, logsBeforeEpochMillis, freeText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalLogsTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoggablesMetaListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalLogsTypesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalRefreshPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalRefreshPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} resourceId resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalResourcesResourceIdGet(resourceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalResourcesResourceIdGet(resourceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserAllGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} emailaddress email address
         * @param {string} oldpassword old password
         * @param {string} newpassword new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserChangePasswordPost(emailaddress: string, oldpassword: string, newpassword: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserChangePasswordPost(emailaddress, oldpassword, newpassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} identifier identifier of the user to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserDelete(identifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserDelete(identifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} name username
         * @param {string} emailAddress email address
         * @param {Array<string>} roles roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserNewPost(name: string, emailAddress: string, roles: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserNewPost(name, emailAddress, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserPermissionsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllPermissionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserPermissionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id user identifier
         * @param {string} name new name of user
         * @param {string} emailAddress new email of user
         * @param {Array<string>} roles new roles of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserPut(id: string, name: string, emailAddress: string, roles: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserPut(id, name, emailAddress, roles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} name name of the role
         * @param {Array<string>} accessRights access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserRolesCreatePost(name: string, accessRights: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserRolesCreatePost(name, accessRights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserRolesDeletePost(roleIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserRolesDeletePost(roleIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} roleIdentifier role identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserRolesDetailsGet(roleIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllUsersWithRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserRolesDetailsGet(roleIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserRolesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {string} name name of the role
         * @param {Array<string>} permissions access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserRolesUpdatePost(roleIdentifier: string, name: string, permissions: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserRolesUpdatePost(roleIdentifier, name, permissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} token token
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserSetPasswordPost(token: string, password: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserSetPasswordPost(token, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} userIdentifier the user\&#39;s identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUserTokenGet(userIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUserTokenGet(userIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} value value to be encrypted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1InternalUtilEncryptPost(value: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EncryptStringResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1InternalUtilEncryptPost(value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} myString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SomelevelSimpleGetGet(myString: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyReturnTypeWrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SomelevelSimpleGetGet(myString, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} queryName the name description
         * @param {SomeDataClass} queryNested nested description
         * @param {string} headername the name description header
         * @param {SomeDataClass} headernested nested description header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SomelevelWebserviceBlueprintGetGet(queryName: string, queryNested: SomeDataClass, headername: string, headernested: SomeDataClass, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyReturnType1Wrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SomelevelWebserviceBlueprintGetGet(queryName, queryNested, headername, headernested, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} someId some id
         * @param {string} myheadervalue a value in the header
         * @param {MyInputTypeBodyWrapper} [myInputTypeBodyWrapper]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SomelevelWebserviceBlueprintPostPost(someId: string, myheadervalue: string, myInputTypeBodyWrapper?: MyInputTypeBodyWrapper, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyReturnType2Wrapper>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SomelevelWebserviceBlueprintPostPost(someId, myheadervalue, myInputTypeBodyWrapper, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppInfoGet(options?: any): AxiosPromise<AppInfo> {
            return localVarFp.apiV1InternalAppInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {'SOFT' | 'HARD'} stopMode stop type
         * @param {string} password confirm with password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppStopPost(stopMode: 'SOFT' | 'HARD', password: string, options?: any): AxiosPromise<StopApplicationResponse> {
            return localVarFp.apiV1InternalAppStopPost(stopMode, password, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalAppSystemGet(options?: any): AxiosPromise<SystemInfoResponse> {
            return localVarFp.apiV1InternalAppSystemGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsBaseGet(options?: any): AxiosPromise<BlueprintsBaseDataResponse> {
            return localVarFp.apiV1InternalBlueprintsBaseGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsGet(options?: any): AxiosPromise<BlueprintsListResponse> {
            return localVarFp.apiV1InternalBlueprintsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} scheduleIds schedule ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleActivatePost(scheduleIds: Array<string>, options?: any): AxiosPromise<ActivateScheduleResponse> {
            return localVarFp.apiV1InternalBlueprintsScheduleActivatePost(scheduleIds, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint identifier
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleCreatePost(blueprintIdentifier: string, newCronExpression: string, options?: any): AxiosPromise<CreateBlueprintScheduleResponse> {
            return localVarFp.apiV1InternalBlueprintsScheduleCreatePost(blueprintIdentifier, newCronExpression, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} scheduleIdentifiers schedule identifiers
         * @param {string} [deactivationStart] deactivate timeframe start
         * @param {string} [deactivationEnd] deactivate timeframe end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleDeactivatePost(scheduleIdentifiers: Array<string>, deactivationStart?: string, deactivationEnd?: string, options?: any): AxiosPromise<DeactivateScheduleResponse> {
            return localVarFp.apiV1InternalBlueprintsScheduleDeactivatePost(scheduleIdentifiers, deactivationStart, deactivationEnd, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} scheduleId schedule identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleDeletePost(scheduleId: string, options?: any): AxiosPromise<DeleteBlueprintScheduleResponse> {
            return localVarFp.apiV1InternalBlueprintsScheduleDeletePost(scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} scheduleId schedule id
         * @param {string} newCronExpression cronExpression
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalBlueprintsScheduleUpdatePost(scheduleId: string, newCronExpression: string, options?: any): AxiosPromise<UpdateBlueprintScheduleResponse> {
            return localVarFp.apiV1InternalBlueprintsScheduleUpdatePost(scheduleId, newCronExpression, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {Array<string>} [blueprints] filter for jobs by blueprintIdentifier
         * @param {'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED'} [status] filter for jobs with this status
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsAllGet(page: number, entriesPerPage: number, blueprints?: Array<string>, status?: 'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED', rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, options?: any): AxiosPromise<JobsMetadataResponse> {
            return localVarFp.apiV1InternalJobsAllGet(page, entriesPerPage, blueprints, status, rangeStartInEpochMillis, rangeEndInEpochMillis, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} jobId job id
         * @param {string} locale locale code
         * @param {boolean} [prettyPrint] pretty print json?
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsDownloadDataGet(jobId: string, locale: string, prettyPrint?: boolean, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1InternalJobsDownloadDataGet(jobId, locale, prettyPrint, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} jobIdentifier job identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsGet(jobIdentifier: string, options?: any): AxiosPromise<GetJobResponse> {
            return localVarFp.apiV1InternalJobsGet(jobIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {Array<string>} [blueprintIdentifiers] optional list of blueprint identifiers for which to get running jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsRunningGet(blueprintIdentifiers?: Array<string>, options?: any): AxiosPromise<RunningJobsMetadataResponse> {
            return localVarFp.apiV1InternalJobsRunningGet(blueprintIdentifiers, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} blueprintIdentifier blueprint that should be executed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsStartPost(blueprintIdentifier: string, options?: any): AxiosPromise<StartJobResponse> {
            return localVarFp.apiV1InternalJobsStartPost(blueprintIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} jobIdentifier blueprint that should be executed
         * @param {'SOFT' | 'HARD'} type Cancellation type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalJobsStopPost(jobIdentifier: string, type: 'SOFT' | 'HARD', options?: any): AxiosPromise<StopJobResponse> {
            return localVarFp.apiV1InternalJobsStopPost(jobIdentifier, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} emailaddress email
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLoginPost(emailaddress: string, password: string, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiV1InternalLoginPost(emailaddress, password, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1InternalLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} page number of requested result page
         * @param {number} entriesPerPage entries per page
         * @param {string} subject subject
         * @param {'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL'} level level
         * @param {string} localeCode locale code
         * @param {'NEWEST_FIRST' | 'OLDEST_FIRST'} sort sort
         * @param {Array<string>} [loggableTypes] loggableTypes
         * @param {Array<string>} [blueprintIdentifiers] blueprint names
         * @param {Array<string>} [jobIds] is any jobId matching
         * @param {number} [rangeStartInEpochMillis] date time range: start
         * @param {number} [rangeEndInEpochMillis] date time range: end
         * @param {number} [logsBeforeEpochMillis] logs after
         * @param {string} [freeText] free text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogsGet(page: number, entriesPerPage: number, subject: string, level: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL', localeCode: string, sort: 'NEWEST_FIRST' | 'OLDEST_FIRST', loggableTypes?: Array<string>, blueprintIdentifiers?: Array<string>, jobIds?: Array<string>, rangeStartInEpochMillis?: number, rangeEndInEpochMillis?: number, logsBeforeEpochMillis?: number, freeText?: string, options?: any): AxiosPromise<LogsResponse> {
            return localVarFp.apiV1InternalLogsGet(page, entriesPerPage, subject, level, localeCode, sort, loggableTypes, blueprintIdentifiers, jobIds, rangeStartInEpochMillis, rangeEndInEpochMillis, logsBeforeEpochMillis, freeText, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalLogsTypesGet(options?: any): AxiosPromise<LoggablesMetaListResponse> {
            return localVarFp.apiV1InternalLogsTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalRefreshPost(options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiV1InternalRefreshPost(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} resourceId resource id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalResourcesResourceIdGet(resourceId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiV1InternalResourcesResourceIdGet(resourceId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserAllGet(options?: any): AxiosPromise<GetAllUsersResponse> {
            return localVarFp.apiV1InternalUserAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} emailaddress email address
         * @param {string} oldpassword old password
         * @param {string} newpassword new password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserChangePasswordPost(emailaddress: string, oldpassword: string, newpassword: string, options?: any): AxiosPromise<ChangePasswordResponse> {
            return localVarFp.apiV1InternalUserChangePasswordPost(emailaddress, oldpassword, newpassword, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} identifier identifier of the user to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserDelete(identifier: string, options?: any): AxiosPromise<DeleteUserResponse> {
            return localVarFp.apiV1InternalUserDelete(identifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} name username
         * @param {string} emailAddress email address
         * @param {Array<string>} roles roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserNewPost(name: string, emailAddress: string, roles: Array<string>, options?: any): AxiosPromise<CreateUserResponse> {
            return localVarFp.apiV1InternalUserNewPost(name, emailAddress, roles, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserPermissionsGet(options?: any): AxiosPromise<GetAllPermissionsResponse> {
            return localVarFp.apiV1InternalUserPermissionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} id user identifier
         * @param {string} name new name of user
         * @param {string} emailAddress new email of user
         * @param {Array<string>} roles new roles of user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserPut(id: string, name: string, emailAddress: string, roles: Array<string>, options?: any): AxiosPromise<UpdateUserDataResponse> {
            return localVarFp.apiV1InternalUserPut(id, name, emailAddress, roles, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} name name of the role
         * @param {Array<string>} accessRights access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesCreatePost(name: string, accessRights: Array<string>, options?: any): AxiosPromise<CreateRoleResponse> {
            return localVarFp.apiV1InternalUserRolesCreatePost(name, accessRights, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesDeletePost(roleIdentifier: string, options?: any): AxiosPromise<DeleteRoleResponse> {
            return localVarFp.apiV1InternalUserRolesDeletePost(roleIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} roleIdentifier role identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesDetailsGet(roleIdentifier: string, options?: any): AxiosPromise<GetAllUsersWithRoleResponse> {
            return localVarFp.apiV1InternalUserRolesDetailsGet(roleIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesGet(options?: any): AxiosPromise<GetAllRolesResponse> {
            return localVarFp.apiV1InternalUserRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} roleIdentifier unique identifier of the role
         * @param {string} name name of the role
         * @param {Array<string>} permissions access rights of the role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserRolesUpdatePost(roleIdentifier: string, name: string, permissions: Array<string>, options?: any): AxiosPromise<UpdateRoleResponse> {
            return localVarFp.apiV1InternalUserRolesUpdatePost(roleIdentifier, name, permissions, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} token token
         * @param {string} password password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserSetPasswordPost(token: string, password: string, options?: any): AxiosPromise<SetPasswordResponse> {
            return localVarFp.apiV1InternalUserSetPasswordPost(token, password, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} userIdentifier the user\&#39;s identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUserTokenGet(userIdentifier: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiV1InternalUserTokenGet(userIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} value value to be encrypted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1InternalUtilEncryptPost(value: string, options?: any): AxiosPromise<EncryptStringResponse> {
            return localVarFp.apiV1InternalUtilEncryptPost(value, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} myString
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelSimpleGetGet(myString: string, options?: any): AxiosPromise<MyReturnTypeWrapper> {
            return localVarFp.apiV1SomelevelSimpleGetGet(myString, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} queryName the name description
         * @param {SomeDataClass} queryNested nested description
         * @param {string} headername the name description header
         * @param {SomeDataClass} headernested nested description header
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelWebserviceBlueprintGetGet(queryName: string, queryNested: SomeDataClass, headername: string, headernested: SomeDataClass, options?: any): AxiosPromise<MyReturnType1Wrapper> {
            return localVarFp.apiV1SomelevelWebserviceBlueprintGetGet(queryName, queryNested, headername, headernested, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {string} someId some id
         * @param {string} myheadervalue a value in the header
         * @param {MyInputTypeBodyWrapper} [myInputTypeBodyWrapper]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SomelevelWebserviceBlueprintPostPost(someId: string, myheadervalue: string, myInputTypeBodyWrapper?: MyInputTypeBodyWrapper, options?: any): AxiosPromise<MyReturnType2Wrapper> {
            return localVarFp.apiV1SomelevelWebserviceBlueprintPostPost(someId, myheadervalue, myInputTypeBodyWrapper, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1InternalAppStopPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalAppStopPostRequest
 */
export interface DefaultApiApiV1InternalAppStopPostRequest {
    /**
     * stop type
     * @type {'SOFT' | 'HARD'}
     * @memberof DefaultApiApiV1InternalAppStopPost
     */
    readonly stopMode: 'SOFT' | 'HARD'

    /**
     * confirm with password
     * @type {string}
     * @memberof DefaultApiApiV1InternalAppStopPost
     */
    readonly password: string
}

/**
 * Request parameters for apiV1InternalBlueprintsScheduleActivatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalBlueprintsScheduleActivatePostRequest
 */
export interface DefaultApiApiV1InternalBlueprintsScheduleActivatePostRequest {
    /**
     * schedule ids
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleActivatePost
     */
    readonly scheduleIds: Array<string>
}

/**
 * Request parameters for apiV1InternalBlueprintsScheduleCreatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalBlueprintsScheduleCreatePostRequest
 */
export interface DefaultApiApiV1InternalBlueprintsScheduleCreatePostRequest {
    /**
     * blueprint identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleCreatePost
     */
    readonly blueprintIdentifier: string

    /**
     * cronExpression
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleCreatePost
     */
    readonly newCronExpression: string
}

/**
 * Request parameters for apiV1InternalBlueprintsScheduleDeactivatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalBlueprintsScheduleDeactivatePostRequest
 */
export interface DefaultApiApiV1InternalBlueprintsScheduleDeactivatePostRequest {
    /**
     * schedule identifiers
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleDeactivatePost
     */
    readonly scheduleIdentifiers: Array<string>

    /**
     * deactivate timeframe start
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleDeactivatePost
     */
    readonly deactivationStart?: string

    /**
     * deactivate timeframe end
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleDeactivatePost
     */
    readonly deactivationEnd?: string
}

/**
 * Request parameters for apiV1InternalBlueprintsScheduleDeletePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalBlueprintsScheduleDeletePostRequest
 */
export interface DefaultApiApiV1InternalBlueprintsScheduleDeletePostRequest {
    /**
     * schedule identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleDeletePost
     */
    readonly scheduleId: string
}

/**
 * Request parameters for apiV1InternalBlueprintsScheduleUpdatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalBlueprintsScheduleUpdatePostRequest
 */
export interface DefaultApiApiV1InternalBlueprintsScheduleUpdatePostRequest {
    /**
     * schedule id
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleUpdatePost
     */
    readonly scheduleId: string

    /**
     * cronExpression
     * @type {string}
     * @memberof DefaultApiApiV1InternalBlueprintsScheduleUpdatePost
     */
    readonly newCronExpression: string
}

/**
 * Request parameters for apiV1InternalJobsAllGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsAllGetRequest
 */
export interface DefaultApiApiV1InternalJobsAllGetRequest {
    /**
     * number of requested result page
     * @type {number}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly page: number

    /**
     * entries per page
     * @type {number}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly entriesPerPage: number

    /**
     * filter for jobs by blueprintIdentifier
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly blueprints?: Array<string>

    /**
     * filter for jobs with this status
     * @type {'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED'}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly status?: 'RUNNING' | 'CANCELLED' | 'GREEN' | 'YELLOW' | 'RED'

    /**
     * date time range: start
     * @type {number}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly rangeStartInEpochMillis?: number

    /**
     * date time range: end
     * @type {number}
     * @memberof DefaultApiApiV1InternalJobsAllGet
     */
    readonly rangeEndInEpochMillis?: number
}

/**
 * Request parameters for apiV1InternalJobsDownloadDataGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsDownloadDataGetRequest
 */
export interface DefaultApiApiV1InternalJobsDownloadDataGetRequest {
    /**
     * job id
     * @type {string}
     * @memberof DefaultApiApiV1InternalJobsDownloadDataGet
     */
    readonly jobId: string

    /**
     * locale code
     * @type {string}
     * @memberof DefaultApiApiV1InternalJobsDownloadDataGet
     */
    readonly locale: string

    /**
     * pretty print json?
     * @type {boolean}
     * @memberof DefaultApiApiV1InternalJobsDownloadDataGet
     */
    readonly prettyPrint?: boolean
}

/**
 * Request parameters for apiV1InternalJobsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsGetRequest
 */
export interface DefaultApiApiV1InternalJobsGetRequest {
    /**
     * job identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalJobsGet
     */
    readonly jobIdentifier: string
}

/**
 * Request parameters for apiV1InternalJobsRunningGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsRunningGetRequest
 */
export interface DefaultApiApiV1InternalJobsRunningGetRequest {
    /**
     * optional list of blueprint identifiers for which to get running jobs
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalJobsRunningGet
     */
    readonly blueprintIdentifiers?: Array<string>
}

/**
 * Request parameters for apiV1InternalJobsStartPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsStartPostRequest
 */
export interface DefaultApiApiV1InternalJobsStartPostRequest {
    /**
     * blueprint that should be executed
     * @type {string}
     * @memberof DefaultApiApiV1InternalJobsStartPost
     */
    readonly blueprintIdentifier: string
}

/**
 * Request parameters for apiV1InternalJobsStopPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalJobsStopPostRequest
 */
export interface DefaultApiApiV1InternalJobsStopPostRequest {
    /**
     * blueprint that should be executed
     * @type {string}
     * @memberof DefaultApiApiV1InternalJobsStopPost
     */
    readonly jobIdentifier: string

    /**
     * Cancellation type
     * @type {'SOFT' | 'HARD'}
     * @memberof DefaultApiApiV1InternalJobsStopPost
     */
    readonly type: 'SOFT' | 'HARD'
}

/**
 * Request parameters for apiV1InternalLoginPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalLoginPostRequest
 */
export interface DefaultApiApiV1InternalLoginPostRequest {
    /**
     * email
     * @type {string}
     * @memberof DefaultApiApiV1InternalLoginPost
     */
    readonly emailaddress: string

    /**
     * password
     * @type {string}
     * @memberof DefaultApiApiV1InternalLoginPost
     */
    readonly password: string
}

/**
 * Request parameters for apiV1InternalLogsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalLogsGetRequest
 */
export interface DefaultApiApiV1InternalLogsGetRequest {
    /**
     * number of requested result page
     * @type {number}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly page: number

    /**
     * entries per page
     * @type {number}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly entriesPerPage: number

    /**
     * subject
     * @type {string}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly subject: string

    /**
     * level
     * @type {'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL'}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly level: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL'

    /**
     * locale code
     * @type {string}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly localeCode: string

    /**
     * sort
     * @type {'NEWEST_FIRST' | 'OLDEST_FIRST'}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly sort: 'NEWEST_FIRST' | 'OLDEST_FIRST'

    /**
     * loggableTypes
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly loggableTypes?: Array<string>

    /**
     * blueprint names
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly blueprintIdentifiers?: Array<string>

    /**
     * is any jobId matching
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly jobIds?: Array<string>

    /**
     * date time range: start
     * @type {number}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly rangeStartInEpochMillis?: number

    /**
     * date time range: end
     * @type {number}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly rangeEndInEpochMillis?: number

    /**
     * logs after
     * @type {number}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly logsBeforeEpochMillis?: number

    /**
     * free text
     * @type {string}
     * @memberof DefaultApiApiV1InternalLogsGet
     */
    readonly freeText?: string
}

/**
 * Request parameters for apiV1InternalResourcesResourceIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalResourcesResourceIdGetRequest
 */
export interface DefaultApiApiV1InternalResourcesResourceIdGetRequest {
    /**
     * resource id
     * @type {string}
     * @memberof DefaultApiApiV1InternalResourcesResourceIdGet
     */
    readonly resourceId: string
}

/**
 * Request parameters for apiV1InternalUserChangePasswordPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserChangePasswordPostRequest
 */
export interface DefaultApiApiV1InternalUserChangePasswordPostRequest {
    /**
     * email address
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserChangePasswordPost
     */
    readonly emailaddress: string

    /**
     * old password
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserChangePasswordPost
     */
    readonly oldpassword: string

    /**
     * new password
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserChangePasswordPost
     */
    readonly newpassword: string
}

/**
 * Request parameters for apiV1InternalUserDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserDeleteRequest
 */
export interface DefaultApiApiV1InternalUserDeleteRequest {
    /**
     * identifier of the user to be deleted
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserDelete
     */
    readonly identifier: string
}

/**
 * Request parameters for apiV1InternalUserNewPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserNewPostRequest
 */
export interface DefaultApiApiV1InternalUserNewPostRequest {
    /**
     * username
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserNewPost
     */
    readonly name: string

    /**
     * email address
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserNewPost
     */
    readonly emailAddress: string

    /**
     * roles
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalUserNewPost
     */
    readonly roles: Array<string>
}

/**
 * Request parameters for apiV1InternalUserPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserPutRequest
 */
export interface DefaultApiApiV1InternalUserPutRequest {
    /**
     * user identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserPut
     */
    readonly id: string

    /**
     * new name of user
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserPut
     */
    readonly name: string

    /**
     * new email of user
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserPut
     */
    readonly emailAddress: string

    /**
     * new roles of user
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalUserPut
     */
    readonly roles: Array<string>
}

/**
 * Request parameters for apiV1InternalUserRolesCreatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserRolesCreatePostRequest
 */
export interface DefaultApiApiV1InternalUserRolesCreatePostRequest {
    /**
     * name of the role
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserRolesCreatePost
     */
    readonly name: string

    /**
     * access rights of the role
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalUserRolesCreatePost
     */
    readonly accessRights: Array<string>
}

/**
 * Request parameters for apiV1InternalUserRolesDeletePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserRolesDeletePostRequest
 */
export interface DefaultApiApiV1InternalUserRolesDeletePostRequest {
    /**
     * unique identifier of the role
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserRolesDeletePost
     */
    readonly roleIdentifier: string
}

/**
 * Request parameters for apiV1InternalUserRolesDetailsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserRolesDetailsGetRequest
 */
export interface DefaultApiApiV1InternalUserRolesDetailsGetRequest {
    /**
     * role identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserRolesDetailsGet
     */
    readonly roleIdentifier: string
}

/**
 * Request parameters for apiV1InternalUserRolesUpdatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserRolesUpdatePostRequest
 */
export interface DefaultApiApiV1InternalUserRolesUpdatePostRequest {
    /**
     * unique identifier of the role
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserRolesUpdatePost
     */
    readonly roleIdentifier: string

    /**
     * name of the role
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserRolesUpdatePost
     */
    readonly name: string

    /**
     * access rights of the role
     * @type {Array<string>}
     * @memberof DefaultApiApiV1InternalUserRolesUpdatePost
     */
    readonly permissions: Array<string>
}

/**
 * Request parameters for apiV1InternalUserSetPasswordPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserSetPasswordPostRequest
 */
export interface DefaultApiApiV1InternalUserSetPasswordPostRequest {
    /**
     * token
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserSetPasswordPost
     */
    readonly token: string

    /**
     * password
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserSetPasswordPost
     */
    readonly password: string
}

/**
 * Request parameters for apiV1InternalUserTokenGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUserTokenGetRequest
 */
export interface DefaultApiApiV1InternalUserTokenGetRequest {
    /**
     * the user\&#39;s identifier
     * @type {string}
     * @memberof DefaultApiApiV1InternalUserTokenGet
     */
    readonly userIdentifier: string
}

/**
 * Request parameters for apiV1InternalUtilEncryptPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1InternalUtilEncryptPostRequest
 */
export interface DefaultApiApiV1InternalUtilEncryptPostRequest {
    /**
     * value to be encrypted
     * @type {string}
     * @memberof DefaultApiApiV1InternalUtilEncryptPost
     */
    readonly value: string
}

/**
 * Request parameters for apiV1SomelevelSimpleGetGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1SomelevelSimpleGetGetRequest
 */
export interface DefaultApiApiV1SomelevelSimpleGetGetRequest {
    /**
     *
     * @type {string}
     * @memberof DefaultApiApiV1SomelevelSimpleGetGet
     */
    readonly myString: string
}

/**
 * Request parameters for apiV1SomelevelWebserviceBlueprintGetGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1SomelevelWebserviceBlueprintGetGetRequest
 */
export interface DefaultApiApiV1SomelevelWebserviceBlueprintGetGetRequest {
    /**
     * the name description
     * @type {string}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintGetGet
     */
    readonly queryName: string

    /**
     * nested description
     * @type {SomeDataClass}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintGetGet
     */
    readonly queryNested: SomeDataClass

    /**
     * the name description header
     * @type {string}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintGetGet
     */
    readonly headername: string

    /**
     * nested description header
     * @type {SomeDataClass}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintGetGet
     */
    readonly headernested: SomeDataClass
}

/**
 * Request parameters for apiV1SomelevelWebserviceBlueprintPostPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiV1SomelevelWebserviceBlueprintPostPostRequest
 */
export interface DefaultApiApiV1SomelevelWebserviceBlueprintPostPostRequest {
    /**
     * some id
     * @type {string}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintPostPost
     */
    readonly someId: string

    /**
     * a value in the header
     * @type {string}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintPostPost
     */
    readonly myheadervalue: string

    /**
     *
     * @type {MyInputTypeBodyWrapper}
     * @memberof DefaultApiApiV1SomelevelWebserviceBlueprintPostPost
     */
    readonly myInputTypeBodyWrapper?: MyInputTypeBodyWrapper
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalAppInfoGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalAppInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalAppStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalAppStopPost(requestParameters: DefaultApiApiV1InternalAppStopPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalAppStopPost(requestParameters.stopMode, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalAppSystemGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalAppSystemGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsBaseGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsBaseGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalBlueprintsScheduleActivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsScheduleActivatePost(requestParameters: DefaultApiApiV1InternalBlueprintsScheduleActivatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsScheduleActivatePost(requestParameters.scheduleIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalBlueprintsScheduleCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsScheduleCreatePost(requestParameters: DefaultApiApiV1InternalBlueprintsScheduleCreatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsScheduleCreatePost(requestParameters.blueprintIdentifier, requestParameters.newCronExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalBlueprintsScheduleDeactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsScheduleDeactivatePost(requestParameters: DefaultApiApiV1InternalBlueprintsScheduleDeactivatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsScheduleDeactivatePost(requestParameters.scheduleIdentifiers, requestParameters.deactivationStart, requestParameters.deactivationEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalBlueprintsScheduleDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsScheduleDeletePost(requestParameters: DefaultApiApiV1InternalBlueprintsScheduleDeletePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsScheduleDeletePost(requestParameters.scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalBlueprintsScheduleUpdatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalBlueprintsScheduleUpdatePost(requestParameters: DefaultApiApiV1InternalBlueprintsScheduleUpdatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalBlueprintsScheduleUpdatePost(requestParameters.scheduleId, requestParameters.newCronExpression, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsAllGet(requestParameters: DefaultApiApiV1InternalJobsAllGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsAllGet(requestParameters.page, requestParameters.entriesPerPage, requestParameters.blueprints, requestParameters.status, requestParameters.rangeStartInEpochMillis, requestParameters.rangeEndInEpochMillis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsDownloadDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsDownloadDataGet(requestParameters: DefaultApiApiV1InternalJobsDownloadDataGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsDownloadDataGet(requestParameters.jobId, requestParameters.locale, requestParameters.prettyPrint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsGet(requestParameters: DefaultApiApiV1InternalJobsGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsGet(requestParameters.jobIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsRunningGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsRunningGet(requestParameters: DefaultApiApiV1InternalJobsRunningGetRequest = {}, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsRunningGet(requestParameters.blueprintIdentifiers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsStartPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsStartPost(requestParameters: DefaultApiApiV1InternalJobsStartPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsStartPost(requestParameters.blueprintIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalJobsStopPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalJobsStopPost(requestParameters: DefaultApiApiV1InternalJobsStopPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalJobsStopPost(requestParameters.jobIdentifier, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalLoginPost(requestParameters: DefaultApiApiV1InternalLoginPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalLoginPost(requestParameters.emailaddress, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalLogoutPost(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalLogsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalLogsGet(requestParameters: DefaultApiApiV1InternalLogsGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalLogsGet(requestParameters.page, requestParameters.entriesPerPage, requestParameters.subject, requestParameters.level, requestParameters.localeCode, requestParameters.sort, requestParameters.loggableTypes, requestParameters.blueprintIdentifiers, requestParameters.jobIds, requestParameters.rangeStartInEpochMillis, requestParameters.rangeEndInEpochMillis, requestParameters.logsBeforeEpochMillis, requestParameters.freeText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalLogsTypesGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalLogsTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalRefreshPost(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalRefreshPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalResourcesResourceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalResourcesResourceIdGet(requestParameters: DefaultApiApiV1InternalResourcesResourceIdGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalResourcesResourceIdGet(requestParameters.resourceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserAllGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserChangePasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserChangePasswordPost(requestParameters: DefaultApiApiV1InternalUserChangePasswordPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserChangePasswordPost(requestParameters.emailaddress, requestParameters.oldpassword, requestParameters.newpassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserDelete(requestParameters: DefaultApiApiV1InternalUserDeleteRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserDelete(requestParameters.identifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserNewPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserNewPost(requestParameters: DefaultApiApiV1InternalUserNewPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserNewPost(requestParameters.name, requestParameters.emailAddress, requestParameters.roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserPermissionsGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserPermissionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserPut(requestParameters: DefaultApiApiV1InternalUserPutRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserPut(requestParameters.id, requestParameters.name, requestParameters.emailAddress, requestParameters.roles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserRolesCreatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserRolesCreatePost(requestParameters: DefaultApiApiV1InternalUserRolesCreatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserRolesCreatePost(requestParameters.name, requestParameters.accessRights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserRolesDeletePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserRolesDeletePost(requestParameters: DefaultApiApiV1InternalUserRolesDeletePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserRolesDeletePost(requestParameters.roleIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserRolesDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserRolesDetailsGet(requestParameters: DefaultApiApiV1InternalUserRolesDetailsGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserRolesDetailsGet(requestParameters.roleIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserRolesGet(options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserRolesUpdatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserRolesUpdatePost(requestParameters: DefaultApiApiV1InternalUserRolesUpdatePostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserRolesUpdatePost(requestParameters.roleIdentifier, requestParameters.name, requestParameters.permissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserSetPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserSetPasswordPost(requestParameters: DefaultApiApiV1InternalUserSetPasswordPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserSetPasswordPost(requestParameters.token, requestParameters.password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUserTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUserTokenGet(requestParameters: DefaultApiApiV1InternalUserTokenGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUserTokenGet(requestParameters.userIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1InternalUtilEncryptPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1InternalUtilEncryptPost(requestParameters: DefaultApiApiV1InternalUtilEncryptPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1InternalUtilEncryptPost(requestParameters.value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1SomelevelSimpleGetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1SomelevelSimpleGetGet(requestParameters: DefaultApiApiV1SomelevelSimpleGetGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1SomelevelSimpleGetGet(requestParameters.myString, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1SomelevelWebserviceBlueprintGetGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1SomelevelWebserviceBlueprintGetGet(requestParameters: DefaultApiApiV1SomelevelWebserviceBlueprintGetGetRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1SomelevelWebserviceBlueprintGetGet(requestParameters.queryName, requestParameters.queryNested, requestParameters.headername, requestParameters.headernested, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {DefaultApiApiV1SomelevelWebserviceBlueprintPostPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiV1SomelevelWebserviceBlueprintPostPost(requestParameters: DefaultApiApiV1SomelevelWebserviceBlueprintPostPostRequest, options?: any) {
        return DefaultApiFp(this.configuration).apiV1SomelevelWebserviceBlueprintPostPost(requestParameters.someId, requestParameters.myheadervalue, requestParameters.myInputTypeBodyWrapper, options).then((request) => request(this.axios, this.basePath));
    }
}
