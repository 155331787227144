import { BlueprintBaseDataForApi, BlueprintMetadataForApi } from "../client";
import { useIntl } from "react-intl";
import { epochMillisToLocaleDateTimeString, formatJobStatistics } from "../util/StringUtil";
import React from "react";
import { ImanButtonWithIcon } from "./wrappers/Buttons";
import { ImanCardActions } from "./wrappers/ImanCard";
import { ImanListItem } from "./wrappers/ImanList";
import {
    CaptionedData,
    CaptionedList,
    ImanExpandable,
    ImanStack,
    ProtectedComponent,
} from "./wrappers/ImanLayout";
import { H6 } from "./wrappers/ImanTypography";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Routes } from "../functionality/Routes";
import { Permissions } from "../functionality/PermissionsUtil";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import EventIcon from "@mui/icons-material/Event";
import { ImanChip } from "./wrappers/ImanChip";

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
export function BlueprintInfoCard(props: {
    blueprint: BlueprintBaseDataForApi;
    additionalData: BlueprintMetadataForApi | undefined;
    startBlueprint: () => void;
    refreshBlueprints: () => void;
}): JSX.Element {
    const history = useHistory();
    const intl = useIntl();

    return (
        <ImanExpandable
            id={"iman-blueprint-" + props.blueprint.identifier}
            key={props.blueprint.identifier}
            width="100%"
            summary={
                <ImanStack direction="row" spacing={2}>
                    <H6>{props.blueprint.name}</H6>
                    <Typography variant="body1">{props.blueprint.shortDescription}</Typography>
                    {props.blueprint.currentlyRunning ? (
                        <ImanChip
                            highlight
                            text={intl.formatMessage({
                                id: "blueprints.currentlyRunning",
                                defaultMessage: "currently running",
                            })}
                            color="success"
                        />
                    ) : null}
                    {props.blueprint.queueSize > 0 ? (
                        <ImanChip
                            highlight={false}
                            text={intl.formatMessage(
                                {
                                    id: "blueprints.numberEntriesInQueue",
                                    defaultMessage: "+{number} in queue",
                                },
                                {
                                    number: props.blueprint.queueSize,
                                }
                            )}
                            color="warning"
                        />
                    ) : null}
                </ImanStack>
            }
            footer={
                <React.Fragment>
                    <ImanCardActions>
                        <ProtectedComponent
                            restrictedToAnyOf={[
                                Permissions.StartAnyBlueprint,
                                Permissions.StartBlueprint(props.blueprint.identifier),
                            ]}
                        >
                            <ImanButtonWithIcon
                                color={"primary"}
                                onClick={() => {
                                    props.startBlueprint();
                                }}
                                className={"iman-button-start"}
                                icon={<PlayCircleOutlineIcon />}
                                text={intl.formatMessage({
                                    id: "blueprints.start",
                                    defaultMessage: "Start",
                                })}
                            />
                        </ProtectedComponent>
                        <ImanButtonWithIcon
                            color={"primary"}
                            onClick={() => {
                                history.push(
                                    Routes.getSchedulerEditRoute(props.blueprint.identifier)
                                );
                            }}
                            icon={<EventIcon />}
                            text={intl.formatMessage({
                                id: "blueprints.editSchedule",
                                defaultMessage: "Edit scheduling",
                            })}
                        />
                    </ImanCardActions>
                </React.Fragment>
            }
        >
            <BlueprintDetails blueprint={props.blueprint} additionalData={props.additionalData} />
        </ImanExpandable>
    );
}

// eslint-disable-next-line max-lines-per-function
function BlueprintDetails(props: {
    blueprint: BlueprintBaseDataForApi;
    additionalData: BlueprintMetadataForApi | undefined;
}): JSX.Element {
    const intl = useIntl();
    const lastExecutionAsLocalDateTimeString = props.additionalData
        ? epochMillisToLocaleDateTimeString(
              props.additionalData.lastRun?.startedAtInEpochMillis,
              intl
          )
        : "";
    const nextExecutionAsLocalDateTimeString = props.additionalData
        ? epochMillisToLocaleDateTimeString(
              props.additionalData.nextRun?.nextExecutionInEpochMillis,
              intl
          )
        : "";
    const jobStatisticsAsString = props.additionalData
        ? formatJobStatistics(props.additionalData.jobStatistics, intl)
        : "";
    return (
        <table className="iman4-blueprint-details">
            <tr>
                <td colSpan={2} rowSpan={3} className="iman4-col-50">
                    <CaptionedData
                        i18nId={"blueprints.description"}
                        defaultCaption={"Blueprint description"}
                    >
                        {props.blueprint.longDescription}
                    </CaptionedData>
                </td>
                <td colSpan={2}>
                    <CaptionedList i18nId="blueprints.configuration" defaultCaption="Configuration">
                        {props.additionalData
                            ? props.additionalData.schedules.map((sched) => {
                                  return (
                                      <ImanListItem key={sched.identifier}>
                                          {sched.scheduleAsCronExpression}
                                      </ImanListItem>
                                  );
                              })
                            : ""}
                    </CaptionedList>
                </td>
            </tr>
            <tr>
                {/*removed first*/}
                {/*removed second*/}
                <td>
                    <CaptionedData
                        i18nId="blueprints.lastExecution"
                        defaultCaption="Last execution"
                    >
                        {lastExecutionAsLocalDateTimeString}
                    </CaptionedData>
                </td>
                <td>
                    <CaptionedData
                        i18nId="blueprints.nextExecution"
                        defaultCaption="Next execution"
                    >
                        {nextExecutionAsLocalDateTimeString}
                    </CaptionedData>
                </td>
            </tr>
            <tr>
                {/*removed first*/}
                {/*removed second*/}
                <td>
                    <CaptionedData
                        i18nId="blueprints.jobStatistics"
                        defaultCaption="fastest/average/longest runtime"
                    >
                        {jobStatisticsAsString}
                    </CaptionedData>
                </td>
                <td>
                    <CaptionedData
                        i18nId="blueprints.connectedSystems"
                        defaultCaption="Connected Systems"
                    >
                        {props.blueprint.connectedSystems.length > 0 ? (
                            props.blueprint.connectedSystems.join(", ")
                        ) : (
                            <i>
                                {intl.formatMessage({
                                    id: "blueprints.connectedSystems.none",
                                    defaultMessage: "none",
                                })}
                            </i>
                        )}
                    </CaptionedData>
                </td>
            </tr>
        </table>
    );
}
