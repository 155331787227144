import React, { ReactNode, useState } from "react";
import { Button, CircularProgress, IconButton } from "@mui/material";
import { ImanCircularIndicator } from "./Indicators";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ImanConfirmationDialog } from "./ImanLayout";
import { CancelOutlined, Check, Delete, Download, Edit } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";

// eslint-disable-next-line max-lines-per-function
export function ImanButton(props: {
    children: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
    className?: string;
    size?: "small" | "medium" | "large";
    variant?: "outlined" | "filled" | "text";
    isFetching?: boolean;
    type?: "submit";
    disabled?: boolean;
    ariaLabel?: string;
    id?: string;
}): JSX.Element {
    const isFetching = props.isFetching ?? false;
    // map to mui variant
    let variant: "outlined" | "contained" | "text" = "contained";
    switch (props.variant) {
        case "filled":
            variant = "contained";
            break;
        case "outlined":
            variant = "outlined";
            break;
        case "text":
            variant = "text";
            break;
    }
    return (
        <Button
            variant={variant}
            size={props.size}
            color={props.color}
            disableRipple
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (props.onClick !== undefined) {
                    props.onClick(event);
                }
            }}
            type={props.type === "submit" ? "submit" : "button"}
            disabled={props.disabled}
            sx={{ borderRadius: "21px", paddingX: "21px" }}
            className={props.className}
            aria-label={props.ariaLabel}
            id={props.id}
        >
            {props.children}
            {isFetching && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "primary",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            )}
        </Button>
    );
}

export function ImanDangerButton(props: {
    children: React.ReactNode;
    onClick?: () => void;
}): JSX.Element {
    return (
        <ImanButton color={"error"} onClick={props.onClick}>
            {props.children}
        </ImanButton>
    );
}

export function ImanWarningButton(props: {
    children: React.ReactNode;
    onClick?: () => void;
}): JSX.Element {
    return (
        <ImanButton color={"warning"} onClick={props.onClick}>
            {props.children}
        </ImanButton>
    );
}

export function ImanButtonWithIcon(props: {
    icon: ReactNode;
    text: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    color?: "primary" | "secondary";
}): JSX.Element {
    return (
        <ImanButton
            variant="text"
            color={props.color}
            onClick={props.onClick}
            className={"iman4-button-with-icon " + props.className}
        >
            {props.icon}
            <span>{props.text}</span>
        </ImanButton>
    );
}

export function ImanSubmitButton(props: {
    children?: React.ReactNode;
    isActive?: boolean;
    isFetching?: boolean;
    onClick?: () => void;
    ariaLabel?: string;
}): JSX.Element {
    const isActive = props.isActive ?? true;
    const isFetching = props.isFetching ?? false;
    return (
        <ImanButton
            type="submit"
            color="primary"
            disabled={!isActive || props.isFetching}
            onClick={props.onClick}
            ariaLabel={props.ariaLabel}
        >
            {props.children}
            {isFetching && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: "primary",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                    }}
                />
            )}
        </ImanButton>
    );
}

export function ButtonSpinner(): JSX.Element {
    return <ImanCircularIndicator color="secondary" />;
}

export function IconButtonMore(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-more"
        >
            <MoreVertIcon />
        </ImanIconButton>
    );
}

export function IconButtonPause(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-pause"
        >
            <PauseIcon />
        </ImanIconButton>
    );
}

export function IconButtonPlay(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-play"
        >
            <PlayArrowIcon />
        </ImanIconButton>
    );
}

export function IconButtonEdit(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-edit"
        >
            <Edit />
        </ImanIconButton>
    );
}

export function IconButtonDelete(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-delete"
        >
            <Delete />
        </ImanIconButton>
    );
}

export function IconButtonCancel(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation}
            className="iman-button-cancel"
        >
            <CancelOutlined />
        </ImanIconButton>
    );
}

export function IconButtonDownload(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation}
            className="iman-button-download"
        >
            <Download />
        </ImanIconButton>
    );
}

export function IconButtonKey(props: {
    onClick?: () => void;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <ImanIconButton
            onClick={props.onClick}
            stopPropagation={props.stopPropagation ? props.stopPropagation : false}
            className="iman-button-key"
        >
            <KeyIcon />
        </ImanIconButton>
    );
}

function ImanIconButton(props: {
    className: string;
    onClick?: () => void;
    children: React.ReactNode;
    stopPropagation?: boolean;
}): JSX.Element {
    return (
        <IconButton
            onClick={(event) => {
                if (props.stopPropagation) {
                    event.stopPropagation();
                }
                if (props.onClick !== undefined) {
                    props.onClick();
                }
            }}
            className={props.className}
        >
            {props.children}
        </IconButton>
    );
}

export function IconButtonSubmit(props: {
    onClick?: () => void;
    isActive?: boolean;
    isFetching?: boolean;
}): JSX.Element {
    return (
        <ImanSubmitButton
            onClick={props.onClick}
            isFetching={props.isFetching}
            isActive={props.isActive}
        >
            <Check />
        </ImanSubmitButton>
    );
}

export function ButtonWithConfirmationDialog<T>(props: {
    buttonChildren: React.ReactNode;
    dialogTitle: string;
    dialogMessage: React.ReactNode;
    onConfirm: () => Promise<T>;
    onResponse: (result: T) => void;
    buttonDisabled?: boolean;
}): JSX.Element {
    const [isWaitingForActivation, setIsWaitingForActivation] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <ImanButton
                variant="outlined"
                disabled={props.buttonDisabled}
                isFetching={isWaitingForActivation}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
            >
                {props.buttonChildren}
            </ImanButton>
            <ImanConfirmationDialog
                isOpen={isDialogOpen}
                onCancel={() => setIsDialogOpen(false)}
                onConfirm={() => {
                    setIsWaitingForActivation(true);
                    props.onConfirm().then((result) => {
                        props.onResponse(result);
                        setIsWaitingForActivation(false);
                        setIsDialogOpen(false);
                    });
                }}
                title={props.dialogTitle}
                message={props.dialogMessage}
            />
        </React.Fragment>
    );
}
