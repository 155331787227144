import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ImanCard } from "../wrappers/ImanCard";
import { H4 } from "../wrappers/ImanTypography";

export function OrdersToSapChart(): JSX.Element {
    const ordersToSap = generateOrdersTestData();
    return (
        <ImanCard>
            <H4>
                <strong>Aufträge pro Stunde</strong>
            </H4>
            <BarChart
                width={750}
                height={480}
                data={ordersToSap}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                    dataKey="orders"
                    fill="#0E32EE"
                    activeBar={<Rectangle fill="#0E32EE" stroke="blue" />}
                />
            </BarChart>
        </ImanCard>
    );
}

const randomize = (data: Array<number>, scale: number, offset: number): Array<number> => {
    return data.map((nb) => {
        const randFactor = Math.random() - 0.5;
        // Don't offset small values
        if (nb < 50) {
            return nb + randFactor * scale;
        }
        return nb + randFactor * scale + offset;
    });
};

const generateOrdersTestData = () => {
    const orders1 = [
        22, 15, 17, 22, 18, 45, 137, 225, 351, 300, 280, 330, 560, 450, 360, 410, 560, 480, 520,
        680, 540, 333, 67, 24,
    ];
    const orders2 = randomize(orders1, 100, 0);
    const orders3 = randomize(orders1, 100, 500);
    const orders4 = randomize(orders3, 100, -80);

    const orders = orders1;
    orders.push(...orders2);
    orders.push(...orders3);
    orders.push(...orders4);

    const start = new Date(Date.parse("2024-06-05 00:00:00"));
    return orders.map((nb, index) => {
        const date = new Date(start.getTime() + 1000 * 60 * 60 * index);
        return {
            timestamp: date.toLocaleDateString() + " " + date.toLocaleTimeString(),
            // no negative values and only integers
            orders: Math.max(0, Math.round(nb)),
        };
    });
};
