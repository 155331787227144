import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { recoilHasToken, recoilToken } from "../recoilStore";
import { ImanButton } from "./wrappers/Buttons";
import { useImanApi } from "../util/ApiUtil";
import { ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { ImanList } from "./wrappers/ImanList";
import { ImanNavigationItem } from "./wrappers/ImanNavigation";
import { HasAnyPermissionOfTypes, ImanDrawer } from "./wrappers/ImanLayout";
import { BarChart } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import EventIcon from "@mui/icons-material/Event";
import { Routes } from "../functionality/Routes";
import { PermissionForApiPermissionTypeEnum } from "../client";

// eslint-disable-next-line max-lines-per-function
export function Navigation(props: { isOpen: boolean; appBarHeight: number }): JSX.Element {
    const [, setToken] = useRecoilState(recoilToken);
    const hasToken = useRecoilValue(recoilHasToken);

    const imanApi = useImanApi();
    const history = useHistory();

    const invalidateTokenAndRedirect = function () {
        setToken("");
        history.push(Routes.LOGIN);
    };

    return (
        <ImanDrawer isOpen={props.isOpen} appBarHeight={props.appBarHeight}>
            <ImanList>
                <ImanNavigationItem
                    link={Routes.DASHBOARD}
                    messageId="nav.dashboard"
                    messageDefault="Dashboard"
                    icon={<BarChart />}
                />
                {hasToken && (
                    <div>
                        <HasAnyPermissionOfTypes
                            restrictedToAnyOf={[
                                PermissionForApiPermissionTypeEnum.ReadAllBlueprints,
                                PermissionForApiPermissionTypeEnum.ReadBlueprint,
                            ]}
                        >
                            <ImanNavigationItem
                                link={Routes.JOBS}
                                messageId="nav.jobs"
                                messageDefault="Jobs"
                                icon={<ViewListOutlinedIcon />}
                                ariaLabel={"nav-jobs"}
                            />
                            <ImanNavigationItem
                                link={Routes.BLUEPRINTS}
                                messageId="nav.blueprints"
                                messageDefault="Blueprints"
                                icon={<AssignmentIcon />}
                                ariaLabel={"nav-blueprints"}
                            />
                            <ImanNavigationItem
                                link={Routes.SCHEDULER}
                                messageId="nav.scheduler"
                                messageDefault="Scheduler"
                                icon={<EventIcon />}
                                ariaLabel={"nav-scheduler"}
                            />
                        </HasAnyPermissionOfTypes>
                        <HasAnyPermissionOfTypes
                            restrictedToAnyOf={[
                                PermissionForApiPermissionTypeEnum.ReadSystemLogs,
                                PermissionForApiPermissionTypeEnum.ReadLogsOfAllBlueprints,
                                PermissionForApiPermissionTypeEnum.ReadLogsOfBlueprint,
                            ]}
                        >
                            <ImanNavigationItem
                                link={Routes.LOGS}
                                messageId="nav.logs"
                                messageDefault="Logs"
                                icon={<SubjectIcon />}
                                ariaLabel={"nav-logs"}
                            />
                        </HasAnyPermissionOfTypes>
                    </div>
                )}
            </ImanList>
            <ImanRow>
                <ImanGridCell xs={6} xl={3}>
                    {hasToken ? (
                        <ImanButton
                            onClick={() => {
                                imanApi.postLogout(invalidateTokenAndRedirect, (reason) => {
                                    console.log("TODO Could not logout", reason);
                                    invalidateTokenAndRedirect();
                                });
                            }}
                            color="secondary"
                        >
                            <FormattedMessage id="logout.button" defaultMessage="Logout" />
                        </ImanButton>
                    ) : (
                        ""
                    )}
                </ImanGridCell>
            </ImanRow>
        </ImanDrawer>
    );
}
