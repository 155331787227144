export function groupBy<K, V>(values: V[], keyGetter: (value: V) => K): Map<K, V[]> {
    const collector = new Map<K, V[]>();
    values.forEach((value) => {
        const key = keyGetter(value);
        const currentList = collector.get(key);
        if (currentList === undefined) {
            collector.set(key, [value]);
        } else {
            currentList.push(value);
        }
    });
    return collector;
}

export function associateBy<K, V>(values: V[], keyGetter: (value: V) => K): Map<K, V> {
    const collector = new Map<K, V>();
    values.forEach((value) => {
        const key = keyGetter(value);
        collector.set(key, value);
    });
    return collector;
}
