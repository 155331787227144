export interface ResourceRouteParams {
    resource_id: string;
}

export interface ScheduleRouteParams {
    blueprint_identifier: string;
}

export interface JobRouteParams {
    job_identifier: string;
}

const nameOf = <T>(name: keyof T) => name;

export class Parameters {
    static SEPARATOR = ":";
    public static RESOURCE_ID = `${Parameters.SEPARATOR}${nameOf<ResourceRouteParams>(
        "resource_id"
    )}`;
    public static BLUEPRINT_IDENTIFIER = `${Parameters.SEPARATOR}${nameOf<ScheduleRouteParams>(
        "blueprint_identifier"
    )}`;
    public static JOB_IDENTIFIER = `${Parameters.SEPARATOR}${nameOf<JobRouteParams>(
        "job_identifier"
    )}`;
}

export class Routes {
    public static ADMIN = "/admin";
    public static BLUEPRINTS = "/blueprints";
    public static DASHBOARD = "/dashboard";
    public static JOBS = "/jobs";
    public static JOB_DETAILS = `/jobs/${Parameters.JOB_IDENTIFIER}`;
    public static LOGS = "/logs";
    public static LOGIN = "/login";
    public static PROFILE = "/profile";
    public static RESOURCE = `/resource/${Parameters.RESOURCE_ID}`;
    public static SET_PASSWORD = "/set_password";
    public static SCHEDULER = "/scheduler";
    public static SCHEDULER_EDIT = `/scheduler/${Parameters.BLUEPRINT_IDENTIFIER}`;

    public static getSchedulerEditRoute(blueprintIdentifier: string): string {
        return Routes.SCHEDULER_EDIT.replace(Parameters.BLUEPRINT_IDENTIFIER, blueprintIdentifier);
    }

    public static getJobDetailsRoute(jobIdentifier: string): string {
        return Routes.JOB_DETAILS.replace(Parameters.JOB_IDENTIFIER, jobIdentifier);
    }
}

export class QUERY_PARAMETER_KEYS {
    public static TOKEN = "token";
}
