import { extractNumberOrUndefined } from "../../util/LinkUtil";
import { JobForApiStatusEnum } from "../../client";

export enum JobStatus {
    RUNNING = "RUNNING",
    CANCELLED = "CANCELLED",
    DONE_GREEN = "GREEN",
    DONE_YELLOW = "YELLOW",
    DONE_RED = "RED",
}

export function jobStatusToJobResult(jobStatus: JobStatus): JobForApiStatusEnum | undefined {
    switch (jobStatus) {
        case JobStatus.RUNNING:
            return JobForApiStatusEnum.Running;
        case JobStatus.CANCELLED:
            return JobForApiStatusEnum.Cancelled;
        case JobStatus.DONE_GREEN:
            return JobForApiStatusEnum.Green;
        case JobStatus.DONE_YELLOW:
            return JobForApiStatusEnum.Yellow;
        case JobStatus.DONE_RED:
            return JobForApiStatusEnum.Red;
    }
}

export interface JobsFilterParameters {
    jobStatus: JobStatus | null;
    blueprintIdentifier: string | null;
    rangeStartInEpochMillis: number | undefined;
    rangeEndInEpochMillis: number | undefined;
}

const KEY_STATUS = "status";
const KEY_BLUEPRINT = "blueprint";
const KEY_START = "start";
const KEY_END = "end";

function extractJobStatusEnum(urlSearchParams: URLSearchParams, key: string): JobStatus | null {
    const value = urlSearchParams.get(key);
    if (value === null) return null;

    const jobStatus = Object.values(JobStatus).find((it) => {
        return it.toString() === value;
    });
    return jobStatus !== undefined ? jobStatus : null;
}

export function extractJobFilterParametersFromUrlSearchParams(
    urlParams: URLSearchParams
): JobsFilterParameters {
    const blueprint = urlParams.get(KEY_BLUEPRINT);
    return {
        jobStatus: extractJobStatusEnum(urlParams, KEY_STATUS),
        blueprintIdentifier: blueprint ? blueprint : null,
        rangeStartInEpochMillis: extractNumberOrUndefined(urlParams, KEY_START) ?? undefined,
        rangeEndInEpochMillis: extractNumberOrUndefined(urlParams, KEY_END) ?? undefined,
    };
}

export function jobsFilterParametersToUrlSearchParams(
    filterParams: JobsFilterParameters
): URLSearchParams {
    const params = new URLSearchParams();

    if (filterParams.blueprintIdentifier !== null) {
        params.set(KEY_BLUEPRINT, filterParams.blueprintIdentifier);
    }

    if (filterParams.rangeStartInEpochMillis !== undefined) {
        params.set(KEY_START, filterParams.rangeStartInEpochMillis.toString());
    }

    if (filterParams.rangeEndInEpochMillis !== undefined) {
        params.set(KEY_END, filterParams.rangeEndInEpochMillis.toString());
    }

    if (filterParams.jobStatus !== null) {
        params.set(KEY_STATUS, filterParams.jobStatus);
    }

    return params;
}
