import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FileData, useImanApi } from "../util/ApiUtil";
import { useRecoilValue } from "recoil";
import { recoilHasToken } from "../recoilStore";
import { ResourceRouteParams } from "../functionality/Routes";

export function ResourceComponent(): JSX.Element {
    const params = useParams<ResourceRouteParams>();
    const [content, setContent] = useState<FileData | null>(null);
    const [objectUrl, setObjectUrl] = useState<string | null>(null);
    const hasToken = useRecoilValue(recoilHasToken);
    const api = useImanApi();
    useEffect(() => {
        if (hasToken) {
            api.fetchFileResource(params.resource_id).then((response) => {
                setContent(response);
                // const blob = new Blob([response.data]);
                const blob = new Blob([response.data], { type: response.contentType });
                setObjectUrl(URL.createObjectURL(blob));
            });
        }
    }, []);

    return (
        <div>
            <div>
                <a download={params.resource_id} href={objectUrl ? objectUrl : ""}>
                    Download
                </a>
            </div>
            <ContentRenderer content={content?.data} contentType={content?.contentType} />
        </div>
    );
}

// TODO: set supported contentTypes to appropriate values as soon as backend is able to recognize
// them correctly
function ContentRenderer(props: {
    content: ArrayBuffer | undefined;
    contentType: string | undefined;
}): JSX.Element {
    if (
        props.content !== undefined &&
        props.contentType !== undefined &&
        ["application/xml", "text/plain", "text/csv", "text/xml", "*/*"].includes(props.contentType)
    ) {
        const nbCols = 80;
        const str = new TextDecoder().decode(props.content);
        return (
            <div>
                <h5>{props.contentType}</h5>
                <textarea disabled rows={Math.ceil(str.length / nbCols) + 1} cols={nbCols}>
                    {str}
                </textarea>
            </div>
        );
    } else {
        return <div />;
    }
}
