import { atom, selector } from "recoil";
import { extractIdentifierFromToken } from "./util/StringUtil";
import { ImanApi } from "./util/ApiUtil";
import { AppInfo, RoleForApi, UserForApi } from "./client";
import { UserPermission } from "./functionality/frontendAccessRightsDepr";

export const recoilLocaleSetByUser = atom({
    key: "locale", // unique ID (with respect to other atoms/selectors)
    default: "en", // default value (aka initial value)
});

export const recoilAppInfo = atom<AppInfo>({
    key: "appInfo",
    default: {
        company: "",
        environment: "dev",
        supportedLocales: ["en"],
    },
});

export const recoilToken = atom({
    key: "token",
    default: "",
});

export const recoilLoggedInUser = atom<UserForApi | null>({
    key: "loggedInUser",
    default: null,
});

export const recoilImanApi = atom<ImanApi | undefined>({
    key: "imanApi",
    default: undefined,
});

export const recoilHasToken = selector({
    key: "hasToken",
    get: ({ get }) => {
        const token = get(recoilToken);
        return token.length > 0;
    },
});

export const recoilUserPermissions = atom<UserPermission[]>({
    key: "userPermissions",
    default: [],
});

export const recoilRolesOfLoggedInUser = atom<RoleForApi[]>({
    key: "userRoles",
    default: [],
});

export const recoilUserIdentifier = selector({
    key: "userIdentifier",
    get: ({ get }) => {
        const token = get(recoilToken);
        return extractIdentifierFromToken(token);
    },
});
