import { RunningJobMetadataForApi, StopJobResponseResultEnum } from "../client";
import { FormattedMessage, IntlShape } from "react-intl";
import { epochMillisToLocaleDateTimeString } from "../util/StringUtil";
import { LabeledInfoListItem } from "./LabeledInfoListItem";
import React, { useState } from "react";
import { ImanButton, ImanDangerButton, ImanWarningButton } from "./wrappers/Buttons";
import { ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { ImanApi, useImanApi } from "../util/ApiUtil";
import { MessageBoxError, MessageBoxSuccess } from "./wrappers/MessageBoxes";
import { ImanCard, ImanCardActions, ImanCardContent, ImanCardTitle } from "./wrappers/ImanCard";
import { ImanList } from "./wrappers/ImanList";
import { ProtectedComponent } from "./wrappers/ImanLayout";
import { Permissions } from "../functionality/PermissionsUtil";

export type RunningJobMetaProps = {
    data: RunningJobMetadataForApi;
    intl: IntlShape;
    fetchJobs: () => void;
};

export function RunningJobMetaCard(props: RunningJobMetaProps): JSX.Element {
    const jobMeta = props.data;
    const scheduledStartAsString = epochMillisToLocaleDateTimeString(
        jobMeta.startedAtInEpochMillis,
        props.intl
    );
    const imanApi = useImanApi();

    return (
        <ImanCard>
            <ImanCardContent>
                <ImanCardTitle>{jobMeta.jobIdentifier}</ImanCardTitle>
                <strong className="text-danger">
                    <FormattedMessage
                        id="jobs.currentlyRunning"
                        defaultMessage="Currently running"
                    />
                </strong>
                <ImanList>
                    <LabeledInfoListItem
                        i18nId="jobs.blueprintIdentifier"
                        defaultMessage="Blueprint"
                        data={jobMeta.blueprintIdentifier}
                    />
                    <LabeledInfoListItem
                        i18nId="jobs.status"
                        defaultMessage="Status"
                        data={jobMeta.status}
                    />
                    <LabeledInfoListItem
                        i18nId="jobs.startedAt"
                        defaultMessage="Started at"
                        data={scheduledStartAsString}
                    />
                    <LabeledInfoListItem
                        i18nId="jobs.triggeredBy"
                        defaultMessage="Started by"
                        data={jobMeta.triggeredBy}
                    />
                </ImanList>
            </ImanCardContent>
            <ImanCardActions>
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.StopAnyBlueprint,
                        Permissions.StopJobsOfBlueprint(jobMeta.blueprintIdentifier),
                    ]}
                >
                    <CancelButtonsRow
                        imanApi={imanApi}
                        jobIdentifier={jobMeta.jobIdentifier}
                        triggerRefresh={props.fetchJobs}
                    />
                </ProtectedComponent>
            </ImanCardActions>
        </ImanCard>
    );
}

// eslint-disable-next-line max-lines-per-function
export function CancelButtonsRow(props: {
    imanApi: ImanApi;
    jobIdentifier: string;
    triggerRefresh: () => void;
}): JSX.Element {
    const [stage, setStage] = useState<"FIRST" | "SELECT_TYPE" | "CONFIRM_HARD">("FIRST");
    const [messageType, setMessageType] = useState<MessageType | null>(null);
    const cancelJob = (type: "SOFT" | "HARD") => {
        props.imanApi.postJobCancel(
            props.jobIdentifier,
            type,
            (result) => {
                switch (result) {
                    case StopJobResponseResultEnum.Success:
                        props.triggerRefresh();
                        setMessageType(MessageType.Success);
                        break;
                    case StopJobResponseResultEnum.NotRunning:
                        setMessageType(MessageType.Error);
                        break;
                    case StopJobResponseResultEnum.Error:
                        setMessageType(MessageType.Error);
                        break;
                }
                setStage("FIRST");
            },
            () => {
                setMessageType(MessageType.Error);
                setStage("FIRST");
            }
        );
    };
    return (
        <ImanRow>
            {stage === "FIRST" ? (
                <ImanGridCell>
                    <ImanDangerButton
                        onClick={() => {
                            setStage("SELECT_TYPE");
                        }}
                    >
                        <FormattedMessage id={"jobs.cancel"} defaultMessage="Cancel" />
                    </ImanDangerButton>
                </ImanGridCell>
            ) : null}
            {stage === "SELECT_TYPE" ? (
                <div>
                    <ImanGridCell>
                        <FormattedMessage
                            id={"jobs.cancel.selectType"}
                            defaultMessage="Which kind of cancel?"
                        />
                    </ImanGridCell>
                    <ImanGridCell>
                        <ImanWarningButton
                            onClick={() => {
                                cancelJob("SOFT");
                            }}
                        >
                            <FormattedMessage id={"jobs.cancel.soft"} defaultMessage="Soft" />
                        </ImanWarningButton>
                    </ImanGridCell>
                    <ImanGridCell>
                        <ImanDangerButton
                            onClick={() => {
                                setStage("CONFIRM_HARD");
                            }}
                        >
                            <FormattedMessage id={"jobs.cancel.hard"} defaultMessage="Hard" />
                        </ImanDangerButton>
                    </ImanGridCell>
                </div>
            ) : null}
            {stage === "CONFIRM_HARD" ? (
                <div>
                    <ImanGridCell>
                        <FormattedMessage
                            id={"jobs.cancel.hard.areYouSure"}
                            defaultMessage="Are you sure?"
                        />
                    </ImanGridCell>
                    <ImanGridCell>
                        <ImanButton
                            onClick={() => {
                                setStage("FIRST");
                            }}
                            color={"secondary"}
                        >
                            <FormattedMessage
                                id={"jobs.cancel.hard.areYouSure.back"}
                                defaultMessage="Back"
                            />
                        </ImanButton>
                    </ImanGridCell>
                    <ImanGridCell>
                        <ImanDangerButton
                            onClick={() => {
                                cancelJob("HARD");
                            }}
                        >
                            <FormattedMessage
                                id={"jobs.cancel.hard.areYouSure.yes"}
                                defaultMessage="Yes"
                            />
                        </ImanDangerButton>
                    </ImanGridCell>
                </div>
            ) : null}
            <CancelMessage messageType={messageType} />
        </ImanRow>
    );
}

enum MessageType {
    Success,
    Error,
}

function CancelMessage(props: { messageType: MessageType | null }): JSX.Element {
    if (props.messageType === MessageType.Success) {
        return (
            <MessageBoxSuccess>
                <FormattedMessage id="jobs.cancel.message.success" defaultMessage="Success" />
            </MessageBoxSuccess>
        );
    } else if (props.messageType === MessageType.Error) {
        return (
            <MessageBoxError>
                <FormattedMessage id="jobs.cancel.message.error" defaultMessage="Error" />
            </MessageBoxError>
        );
    } else {
        return <div />;
    }
}
