import { BlueprintMetadataForApi, ScheduleMetadataForApi } from "../client";

export function allSchedulesPaused(schedules: Array<ScheduleMetadataForApi>): boolean {
    if (schedules.length === 0) {
        return false;
    }
    return schedules.every((sched) => sched.isCurrentlyDeactivated);
}

export function noSchedulePaused(schedules: Array<ScheduleMetadataForApi>): boolean {
    if (schedules.length === 0) {
        return false;
    }
    return schedules.every((sched) => !sched.isCurrentlyDeactivated);
}

export function extractAllSchedules(
    blueprints: Array<BlueprintMetadataForApi>
): Array<ScheduleMetadataForApi> {
    return blueprints.flatMap((bp) => bp.schedules);
}
