import { CreateUserResponseResultEnum } from "../client";

export enum FrontendCreateUserResponseType {
    SUCCESS,
    IDENTIFIER_NOT_UNIQUE,
    INVALID_EMAIL_ADDRESS,
    EMAIL_ADDRESS_NOT_UNIQUE,
    ERROR,
}

export function toFrontendType(
    apiResponseType: CreateUserResponseResultEnum
): FrontendCreateUserResponseType {
    switch (apiResponseType) {
        case CreateUserResponseResultEnum.InvalidEmailAddress:
            return FrontendCreateUserResponseType.INVALID_EMAIL_ADDRESS;
        case CreateUserResponseResultEnum.EmailAddressNotUnique:
            return FrontendCreateUserResponseType.EMAIL_ADDRESS_NOT_UNIQUE;
        case CreateUserResponseResultEnum.Success:
            return FrontendCreateUserResponseType.SUCCESS;
        case CreateUserResponseResultEnum.IdentifierNotUnique:
            return FrontendCreateUserResponseType.IDENTIFIER_NOT_UNIQUE;
        case CreateUserResponseResultEnum.UnknownError:
            return FrontendCreateUserResponseType.ERROR;
    }
}
