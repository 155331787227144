import {
    PermissionForApi,
    PermissionForApiPermissionTypeEnum,
    PermissionForApiGroupEnum,
} from "../client";
import { UserPermission } from "./frontendAccessRightsDepr";
import { groupBy } from "./CollectionUtil";

export class Permissions {
    public static VdctAll: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.VdctAll,
        subgroup: null,
    };

    public static CreateSchedulesForAllBlueprints: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.CreateSchedulesForAnyBlueprint,
        subgroup: null,
    };
    public static CreateScheduleForBlueprint: (identifier: string) => UserPermission = (
        identifier
    ) => {
        return {
            type: PermissionForApiPermissionTypeEnum.CreateSchedulesForBlueprint,
            subgroup: identifier,
        };
    };

    public static UpdateSchedulesOfAnyBlueprint: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.UpdateSchedulesOfAnyBlueprint,
        subgroup: null,
    };
    public static UpdateSchedulesOfBlueprint: (identifier: string) => UserPermission = (
        identifier
    ) => {
        return {
            type: PermissionForApiPermissionTypeEnum.UpdateSchedulesOfBlueprint,
            subgroup: identifier,
        };
    };

    public static DeleteSchedulesOfAnyBlueprint: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.DeleteSchedulesOfAnyBlueprint,
        subgroup: null,
    };
    public static DeleteSchedulesOfBlueprint: (identifier: string) => UserPermission = (
        identifier
    ) => {
        return {
            type: PermissionForApiPermissionTypeEnum.DeleteSchedulesOfBlueprint,
            subgroup: identifier,
        };
    };

    public static ActivateAndDeactivateSchedulesOfAnyBlueprint: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.ActivateAndDeactivateSchedulesOfAnyBlueprint,
        subgroup: null,
    };
    public static ActivateAndDeactivateSchedulesOfBlueprint: (
        identifier: string
    ) => UserPermission = (identifier) => {
        return {
            type: PermissionForApiPermissionTypeEnum.ActivateAndDeactivateSchedulesOfBlueprint,
            subgroup: identifier,
        };
    };

    public static StopAnyBlueprint: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.StopJobsOfAnyBlueprint,
        subgroup: null,
    };
    public static StopJobsOfBlueprint: (identifier: string) => UserPermission = (identifier) => {
        return {
            type: PermissionForApiPermissionTypeEnum.StopJobsOfBlueprint,
            subgroup: identifier,
        };
    };

    public static StartAnyBlueprint: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.StartAnyBlueprint,
        subgroup: null,
    };
    public static StartBlueprint: (identifier: string) => UserPermission = (identifier) => {
        return {
            type: PermissionForApiPermissionTypeEnum.StartBlueprint,
            subgroup: identifier,
        };
    };

    public static ReadAttachmentsOfAllBlueprints: UserPermission = {
        type: PermissionForApiPermissionTypeEnum.ReadAttachmentsOfAllBlueprints,
        subgroup: null,
    };
    public static ReadAttachmentsOfBlueprint: (identifier: string) => UserPermission = (
        identifier
    ) => {
        return {
            type: PermissionForApiPermissionTypeEnum.ReadAttachmentsOfBlueprint,
            subgroup: identifier,
        };
    };
}

export function groupByMainGroup(
    accessRights: PermissionForApi[]
): Map<PermissionForApiGroupEnum, PermissionForApi[]> {
    return groupBy(accessRights, (right) => right.group);
}

export function groupBySubgroup(
    accessRights: PermissionForApi[] | undefined
): Map<string, PermissionForApi[]> {
    if (accessRights === undefined) {
        return new Map<string, PermissionForApi[]>();
    }
    return groupBy(accessRights, (right) => {
        const subgroup = right.subgroup;
        if (subgroup === null || subgroup === undefined) {
            return "";
        } else {
            return subgroup;
        }
    });
}

export function mapToUserAccessRightFrontend(PermissionForApi: PermissionForApi): UserPermission {
    return {
        type: PermissionForApi.permissionType,
        subgroup: PermissionForApi.subgroup !== undefined ? PermissionForApi.subgroup : null,
    };
}

export function hasAnyRequiredPermission(
    rightsRestriction: UserPermission[],
    userPermissions: UserPermission[]
): boolean {
    const permissionRestriction = rightsRestriction.slice();
    permissionRestriction.push(Permissions.VdctAll);
    return isAnyIncluded(
        permissionRestriction,
        userPermissions,
        (lhs, rhs) => lhs.type === rhs.type && lhs.subgroup === rhs.subgroup
    );
}

export function isAnyIncluded<T>(
    arr1: T[],
    arr2: T[],
    areEqual: (lhs: T, rhs: T) => boolean
): boolean {
    return (
        arr1.findIndex((lhs) => {
            return arr2.findIndex((rhs) => areEqual(lhs, rhs)) !== -1;
        }) !== -1
    );
}

export function hasAnyRequiredPermissionOfType(
    rightsRestriction: PermissionForApiPermissionTypeEnum[],
    userPermissions: UserPermission[]
): boolean {
    const permissionRestriction = rightsRestriction.slice();
    permissionRestriction.push(PermissionForApiPermissionTypeEnum.VdctAll);

    return isAnyIncluded(
        permissionRestriction,
        userPermissions.map((permission) => permission.type),
        (lhs, rhs) => lhs === rhs
    );
}
