import React from "react";
import { List, ListItem, ListItemButton } from "@mui/material";

export function ImanList(props: { children?: React.ReactNode }): JSX.Element {
    return <List>{props.children}</List>;
}

export function ImanListItem(props: {
    divider?: boolean;
    children?: React.ReactNode;
}): JSX.Element {
    return (
        <ListItem divider={props.divider} disablePadding>
            {props.children}
        </ListItem>
    );
}

export function ImanListItemButton(props: {
    onClick?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <ListItemButton disabled={props.disabled} onClick={props.onClick}>
            {props.children}
        </ListItemButton>
    );
}
