import { PagingMetadata } from "../client";
import { PagingLink } from "./PagingLink";
import React from "react";
import { calculatePagingSettings } from "../util/LinkUtil";
import { ImanGridCell } from "./wrappers/ImanGrid";
import { TablePagination } from "@mui/material";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ImanTableRow } from "./wrappers/ImanTable";

const numberOfPagesInFrontOfCurrentPage = 5;
const numberOfPagesAfterCurrentPage = 4;

export function PagingControl(props: {
    pagingInfo: PagingMetadata;
    currentPage: number;
    linkProducer: (page: number, entriesPerPage: number) => string;
}): JSX.Element {
    const history = useHistory();
    return (
        <ImanTableRow>
            <TablePagination
                count={props.pagingInfo.numberOfItems}
                page={props.pagingInfo.currentPage - 1}
                onPageChange={(event, newPage) => {
                    history.push(props.linkProducer(newPage + 1, props.pagingInfo.entriesPerPage));
                }}
                rowsPerPage={props.pagingInfo.entriesPerPage}
                onRowsPerPageChange={(event) =>
                    history.push(
                        props.linkProducer(
                            props.pagingInfo.currentPage,
                            parseInt(event.target.value, 10)
                        )
                    )
                }
                labelRowsPerPage={
                    <FormattedMessage
                        id="paging.entriesPerPage"
                        defaultMessage="Entries per page"
                    />
                }
            />
        </ImanTableRow>
    );
}

export function PagingControlDepr(props: {
    pagingInfo: PagingMetadata;
    currentPage: number;
    linkProducer: (page: number) => string;
}): JSX.Element {
    const pagingSettings = calculatePagingSettings(
        props.pagingInfo.currentPage,
        props.pagingInfo.numberOfPages,
        numberOfPagesInFrontOfCurrentPage,
        numberOfPagesAfterCurrentPage
    );

    const pagesInFrame = Array.from(
        new Array(pagingSettings.numberOfPagesInFrame),
        (val, index) => pagingSettings.firstPageInFrame + index
    );

    if (props.pagingInfo.numberOfPages > 1) {
        return (
            <ImanGridCell>
                <PagingLink
                    pageNumber={1}
                    emphasize={false}
                    entriesPerPage={props.pagingInfo.entriesPerPage}
                    label={"|<<"}
                    linkProducer={() => props.linkProducer(1)}
                />
                {pagesInFrame.map((page: number) => {
                    return (
                        <PagingLink
                            key={page}
                            pageNumber={page}
                            entriesPerPage={props.pagingInfo.entriesPerPage}
                            emphasize={page === props.currentPage}
                            linkProducer={() => props.linkProducer(page)}
                        />
                    );
                })}
                <PagingLink
                    pageNumber={props.pagingInfo.numberOfPages}
                    entriesPerPage={props.pagingInfo.entriesPerPage}
                    emphasize={false}
                    label={">>|"}
                    linkProducer={() => props.linkProducer(props.pagingInfo.numberOfPages)}
                />
            </ImanGridCell>
        );
    } else {
        return <div />;
    }
}
