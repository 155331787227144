import React, { useEffect, useState } from "react";
import {
    CaptionedData,
    ImanBox,
    ImanConfirmationDialog,
    ImanDivider,
    ImanStack,
    ProtectedComponent,
} from "./wrappers/ImanLayout";
import { useParams } from "react-router";
import { ImanCard } from "./wrappers/ImanCard";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { Body1, H4, H6 } from "./wrappers/ImanTypography";
import { useImanApi } from "../util/ApiUtil";
import {
    ActivateScheduleResponseResultEnum,
    BlueprintMetadataForApi,
    CreateBlueprintScheduleResponse,
    CreateBlueprintScheduleResponseResultEnum,
    DeactivateScheduleResponseResultEnum,
    ScheduleMetadataForApi,
} from "../client";
import { ImanGrid, ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { epochMillisToLocaleDateTimeString } from "../util/StringUtil";
import {
    Alert,
    AlertColor,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import {
    IconButtonCancel,
    IconButtonDelete,
    IconButtonEdit,
    IconButtonPause,
    IconButtonPlay,
    IconButtonSubmit,
    ImanButton,
} from "./wrappers/Buttons";
import { ImanForm } from "./wrappers/form/Form";
import { SimpleInput } from "./wrappers/form/TextInputs";
import { allSchedulesPaused, noSchedulePaused } from "../util/ScheduleUtil";
import { Routes, ScheduleRouteParams } from "../functionality/Routes";
import { useRecoilValue } from "recoil";
import { recoilUserPermissions } from "../recoilStore";
import { UserPermission } from "../functionality/frontendAccessRightsDepr";
import { IconAlarm, IconAlarmOff, IconArrowBack } from "./wrappers/IconWrappers";
import { Permissions } from "../functionality/PermissionsUtil";

function extractAllScheduleIdentifiers(blueprint: BlueprintMetadataForApi | undefined): string[] {
    const allScheduleIdentifiers = blueprint?.schedules?.map((sched) => sched.identifier);
    if (allScheduleIdentifiers === undefined) {
        return [];
    } else {
        return allScheduleIdentifiers;
    }
}

export function ScheduleEditPage(): JSX.Element {
    const api = useImanApi();
    const { blueprint_identifier } = useParams<ScheduleRouteParams>();

    const [blueprint, setBlueprint] = useState<BlueprintMetadataForApi | undefined>(undefined);

    const fetchBlueprint = () => {
        // TODO: fetch only one blueprint! api currently missing
        api.fetchBlueprints((response) => {
            const receivedBlueprint = response.blueprints.find(
                (bp) => bp.identifier === blueprint_identifier
            );
            setBlueprint(receivedBlueprint);
        });
    };

    useEffect(() => {
        fetchBlueprint();
    }, []);

    return <ScheduleEditPageComponent blueprint={blueprint} fetchBlueprint={fetchBlueprint} />;
}

// eslint-disable-next-line max-lines-per-function
export function ScheduleEditPageComponent(props: {
    blueprint: BlueprintMetadataForApi | undefined;
    fetchBlueprint: () => void;
}): JSX.Element {
    const [isInAddScheduleMode, setIsInAddScheduleMode] = useState(false);

    return (
        <ImanBox>
            <ImanStack align="start">
                <Link to={Routes.SCHEDULER}>
                    <IconArrowBack />
                    <FormattedMessage id="scheduler.edit.back" defaultMessage="Back to scheduler" />
                </Link>
                <ImanCard width="100%">
                    {props.blueprint !== undefined && (
                        <React.Fragment>
                            <ImanStack align="start">
                                <BlueprintSchedulesHeader
                                    blueprint={props.blueprint}
                                    fetchBlueprint={props.fetchBlueprint}
                                />
                                <BlueprintInfo blueprint={props.blueprint} />
                                <ImanDivider />
                                <React.Fragment>
                                    <ImanStack direction="row">
                                        <H6>
                                            <FormattedMessage
                                                id="scheduler.edit.configuration"
                                                defaultMessage="Configuration"
                                            />
                                        </H6>
                                        <ProtectedComponent
                                            restrictedToAnyOf={[
                                                Permissions.CreateSchedulesForAllBlueprints,
                                                Permissions.CreateScheduleForBlueprint(
                                                    props.blueprint.identifier
                                                ),
                                            ]}
                                        >
                                            <ImanButton
                                                variant="outlined"
                                                disabled={isInAddScheduleMode}
                                                onClick={() => setIsInAddScheduleMode(true)}
                                            >
                                                <FormattedMessage
                                                    id="blueprints.schedules.add"
                                                    defaultMessage="Add"
                                                />
                                            </ImanButton>
                                        </ProtectedComponent>
                                    </ImanStack>
                                    <Schedules
                                        blueprintIdentifier={props.blueprint.identifier}
                                        schedules={props.blueprint.schedules}
                                        refreshBlueprints={() => {
                                            props.fetchBlueprint();
                                        }}
                                        isInAddScheduleMode={isInAddScheduleMode}
                                        onAddingDone={() => setIsInAddScheduleMode(false)}
                                    />
                                </React.Fragment>
                            </ImanStack>
                        </React.Fragment>
                    )}
                </ImanCard>
            </ImanStack>
        </ImanBox>
    );
}

// eslint-disable-next-line max-lines-per-function
export function BlueprintSchedulesHeader(props: {
    blueprint: BlueprintMetadataForApi;
    fetchBlueprint: () => void;
}): JSX.Element {
    return (
        <ImanStack direction="row" justifyContent="space-between" fullWidth>
            <ImanStack align="start">
                <H4 textAlign="left">{props.blueprint.name}</H4>
                <Body1>Eine kurze Beschreibung des Blueprints.</Body1>
            </ImanStack>
            <ProtectedComponent
                restrictedToAnyOf={[Permissions.ActivateAndDeactivateSchedulesOfAnyBlueprint]}
            >
                <ImanStack>
                    <DeactivateAllButtonAndDialog
                        blueprint={props.blueprint}
                        fetchBlueprint={props.fetchBlueprint}
                    />
                    <ActivateAllButtonAndDialog
                        blueprint={props.blueprint}
                        fetchBlueprint={props.fetchBlueprint}
                    />
                </ImanStack>
            </ProtectedComponent>
        </ImanStack>
    );
}

function DeactivateAllButtonAndDialog(props: {
    blueprint: BlueprintMetadataForApi;
    fetchBlueprint: () => void;
}): JSX.Element {
    const intl = useIntl();
    const api = useImanApi();
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const allScheduleIdentifiers = extractAllScheduleIdentifiers(props.blueprint);
    const allPaused = allSchedulesPaused(props.blueprint.schedules);

    return (
        <React.Fragment>
            <ImanButton
                variant="outlined"
                disabled={allPaused}
                isFetching={isWaitingForResponse}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
            >
                <FormattedMessage id="blueprints.schedule.pauseAll" defaultMessage="Unpause all" />
            </ImanButton>
            <ImanConfirmationDialog
                isOpen={isDialogOpen}
                onCancel={() => setIsDialogOpen(false)}
                onConfirm={() => {
                    setIsWaitingForResponse(true);
                    api.pauseSchedules(allScheduleIdentifiers).then(() => {
                        props.fetchBlueprint();
                        setIsWaitingForResponse(false);
                        setIsDialogOpen(false);
                    });
                }}
                title={intl.formatMessage({
                    id: "blueprints.schedule.pauseAll.title",
                    defaultMessage: "Pause all?",
                })}
                message={intl.formatMessage({
                    id: "blueprints.schedule.pauseAll.message",
                    defaultMessage: "This will pause all schedules.",
                })}
            />
        </React.Fragment>
    );
}

function ActivateAllButtonAndDialog(props: {
    blueprint: BlueprintMetadataForApi;
    fetchBlueprint: () => void;
}): JSX.Element {
    const intl = useIntl();
    const api = useImanApi();
    const [isWaitingForActivation, setIsWaitingForActivation] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const allScheduleIdentifiers = extractAllScheduleIdentifiers(props.blueprint);
    const nonePaused = noSchedulePaused(props.blueprint.schedules);

    return (
        <React.Fragment>
            <ImanButton
                variant="outlined"
                disabled={nonePaused}
                isFetching={isWaitingForActivation}
                onClick={() => {
                    setIsDialogOpen(true);
                }}
            >
                <FormattedMessage
                    id="blueprints.schedule.unpauseAll"
                    defaultMessage="Unpause all"
                />
            </ImanButton>
            <ImanConfirmationDialog
                isOpen={isDialogOpen}
                onCancel={() => setIsDialogOpen(false)}
                onConfirm={() => {
                    setIsWaitingForActivation(true);
                    api.unpauseSchedules(allScheduleIdentifiers).then(() => {
                        props.fetchBlueprint();
                        setIsWaitingForActivation(false);
                        setIsDialogOpen(false);
                    });
                }}
                title={intl.formatMessage({
                    id: "blueprints.schedule.unpauseAll.title",
                    defaultMessage: "Unpause all?",
                })}
                message={intl.formatMessage({
                    id: "blueprints.schedule.unpauseAll.message",
                    defaultMessage: "This will unpause all schedules.",
                })}
            />
        </React.Fragment>
    );
}

function BlueprintInfo(props: { blueprint: BlueprintMetadataForApi }): JSX.Element {
    const intl = useIntl();
    return (
        <ImanGrid>
            <ImanRow>
                <ImanGridCell xs={12} sm={6}>
                    <Body1>
                        Eine lange Beschreibung des Blueprints. Diese ist wirklich deutlich länger
                        als die kurze Beschreibung. Es gibt mehrere Sätze, Satzzeichen und sogar
                        Nebensätze, die wenig zum Inhalt beitragen. Eine wirklich gelungene lange
                        Beschreibung!
                    </Body1>
                </ImanGridCell>
                <ImanGridCell sm={2} />
                <ImanGridCell xs={12} sm={6} md={2} textAlign="left">
                    <CaptionedData i18nId="scheduler.table.lastRun" defaultCaption="latest run">
                        {epochMillisToLocaleDateTimeString(
                            props.blueprint.lastRun?.startedAtInEpochMillis,
                            intl
                        )}
                    </CaptionedData>
                </ImanGridCell>
                <ImanGridCell xs={12} sm={6} md={2} textAlign="left">
                    <CaptionedData i18nId="scheduler.table.nextRun" defaultCaption="next run">
                        {epochMillisToLocaleDateTimeString(
                            props.blueprint.nextRun?.nextExecutionInEpochMillis,
                            intl
                        )}
                    </CaptionedData>
                </ImanGridCell>
            </ImanRow>
        </ImanGrid>
    );
}

function Schedules(props: {
    blueprintIdentifier: string;
    schedules: Array<ScheduleMetadataForApi>;
    refreshBlueprints: () => void;
    isInAddScheduleMode: boolean;
    onAddingDone: () => void;
}): JSX.Element {
    return (
        <ImanGrid>
            <ImanGridCell xs={12} md={9} lg={8} xl={6}>
                {props.schedules.length === 0 && (
                    <FormattedMessage
                        id="blueprints.schedules.empty"
                        defaultMessage="Currently no schedules"
                    />
                )}
                <TableContainer>
                    <Table>
                        <TableBody>
                            <ProtectedComponent
                                restrictedToAnyOf={[
                                    Permissions.CreateSchedulesForAllBlueprints,
                                    Permissions.CreateScheduleForBlueprint(
                                        props.blueprintIdentifier
                                    ),
                                ]}
                            >
                                <AddNewScheduleForm
                                    blueprintIdentifier={props.blueprintIdentifier}
                                    refreshBlueprints={props.refreshBlueprints}
                                    isInAddScheduleMode={props.isInAddScheduleMode}
                                    onAddingDone={props.onAddingDone}
                                />
                            </ProtectedComponent>
                            {props.schedules.map((schedule) => {
                                return (
                                    <EditableSchedule
                                        key={schedule.identifier}
                                        schedule={schedule}
                                        refreshBlueprints={props.refreshBlueprints}
                                        blueprintIdentifier={props.blueprintIdentifier}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ImanGridCell>
        </ImanGrid>
    );
}

// eslint-disable-next-line max-lines-per-function
function EditableSchedule(props: {
    schedule: ScheduleMetadataForApi;
    refreshBlueprints: () => void;
    blueprintIdentifier: string;
}): JSX.Element {
    const accessRights = useRecoilValue(recoilUserPermissions);
    const [mode, setMode] = useState<"show" | "edit" | "delete" | "pause" | "unpause">("show");
    switch (mode) {
        case "show":
            return (
                <EditableScheduleShow
                    schedule={props.schedule}
                    setMode={setMode}
                    refreshBlueprints={props.refreshBlueprints}
                    accessRights={accessRights}
                    blueprintIdentifier={props.blueprintIdentifier}
                />
            );
        case "edit":
            return (
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.UpdateSchedulesOfAnyBlueprint,
                        Permissions.UpdateSchedulesOfBlueprint(props.blueprintIdentifier),
                    ]}
                >
                    <EditableScheduleEdit
                        schedule={props.schedule}
                        onDone={() => setMode("show")}
                        refreshBlueprints={props.refreshBlueprints}
                    />
                </ProtectedComponent>
            );
        case "delete":
            return (
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.DeleteSchedulesOfAnyBlueprint,
                        Permissions.DeleteSchedulesOfBlueprint(props.blueprintIdentifier),
                    ]}
                >
                    <EditableScheduleDelete
                        schedule={props.schedule}
                        onDone={() => setMode("show")}
                        refreshBlueprints={props.refreshBlueprints}
                    />
                </ProtectedComponent>
            );
        case "pause":
            return (
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.ActivateAndDeactivateSchedulesOfAnyBlueprint,
                        Permissions.ActivateAndDeactivateSchedulesOfBlueprint(
                            props.blueprintIdentifier
                        ),
                    ]}
                >
                    <EditableSchedulePause
                        schedule={props.schedule}
                        onDone={() => {
                            setMode("show");
                            props.refreshBlueprints();
                        }}
                    />
                </ProtectedComponent>
            );
        case "unpause":
            return (
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.ActivateAndDeactivateSchedulesOfAnyBlueprint,
                        Permissions.ActivateAndDeactivateSchedulesOfBlueprint(
                            props.blueprintIdentifier
                        ),
                    ]}
                >
                    <EditableScheduleUnpause
                        schedule={props.schedule}
                        onDone={() => {
                            setMode("show");
                            props.refreshBlueprints();
                        }}
                    />
                </ProtectedComponent>
            );
    }
}

function EditableScheduleTableRow(props: {
    firstCell: JSX.Element;
    secondCell: JSX.Element;
}): JSX.Element {
    return (
        <TableRow>
            <TableCell>{props.firstCell}</TableCell>
            <TableCell align="right">{props.secondCell}</TableCell>
        </TableRow>
    );
}

function EditableSchedulePause(props: {
    schedule: ScheduleMetadataForApi;
    onDone: () => void;
}): JSX.Element {
    const api = useImanApi();
    const [isWaiting, setIsWaiting] = useState(false);
    return (
        <EditableScheduleTableRow
            firstCell={
                <React.Fragment>
                    <FormattedMessage
                        id="blueprints.schedule.pause.confirm"
                        defaultMessage="Pause {cron}?"
                        values={{
                            cron: <u>{props.schedule.scheduleAsCronExpression}</u>,
                        }}
                    />
                </React.Fragment>
            }
            secondCell={
                <React.Fragment>
                    <IconButtonSubmit
                        isFetching={isWaiting}
                        onClick={() => {
                            setIsWaiting(true);
                            api.pauseSchedules([props.schedule.identifier]).then((response) => {
                                if (
                                    response.result === DeactivateScheduleResponseResultEnum.Success
                                ) {
                                    props.onDone();
                                    setIsWaiting(false);
                                }
                            });
                        }}
                    />
                    <IconButtonCancel
                        onClick={() => {
                            props.onDone();
                        }}
                    />
                </React.Fragment>
            }
        />
    );
}

function EditableScheduleUnpause(props: {
    schedule: ScheduleMetadataForApi;
    onDone: () => void;
}): JSX.Element {
    const api = useImanApi();
    const [isWaiting, setIsWaiting] = useState(false);
    return (
        <EditableScheduleTableRow
            firstCell={
                <React.Fragment>
                    <FormattedMessage
                        id="blueprints.schedule.unpause.confirm"
                        defaultMessage="Unpause {cron}?"
                        values={{
                            cron: <u>{props.schedule.scheduleAsCronExpression}</u>,
                        }}
                    />
                </React.Fragment>
            }
            secondCell={
                <React.Fragment>
                    <IconButtonSubmit
                        isFetching={isWaiting}
                        onClick={() => {
                            setIsWaiting(true);
                            api.unpauseSchedules([props.schedule.identifier]).then((response) => {
                                if (
                                    response.result === ActivateScheduleResponseResultEnum.Success
                                ) {
                                    props.onDone();
                                    setIsWaiting(false);
                                }
                            });
                        }}
                    />
                    <IconButtonCancel
                        onClick={() => {
                            props.onDone();
                        }}
                    />
                </React.Fragment>
            }
        />
    );
}

function EditableScheduleShow(props: {
    schedule: ScheduleMetadataForApi;
    setMode: (newMode: "edit" | "delete" | "pause" | "unpause") => void;
    refreshBlueprints: () => void;
    accessRights: UserPermission[];
    blueprintIdentifier: string;
}): JSX.Element {
    const isPaused = props.schedule.isCurrentlyDeactivated;
    return (
        <EditableScheduleTableRow
            firstCell={
                <React.Fragment>
                    <ImanStack direction="row" spacing={2}>
                        {isPaused ? <IconAlarmOff /> : <IconAlarm />}
                        <span>{props.schedule.scheduleAsCronExpression}</span>
                        {isPaused && (
                            <span>
                                (
                                <i>
                                    <FormattedMessage
                                        id="blueprints.schedule.isPaused"
                                        defaultMessage="Paused"
                                    />
                                </i>
                                )
                            </span>
                        )}
                    </ImanStack>
                </React.Fragment>
            }
            secondCell={
                <EditableScheduleShowSecondColumn
                    isPaused={isPaused}
                    setMode={props.setMode}
                    blueprintIdentifier={props.blueprintIdentifier}
                />
            }
        />
    );
}

function EditableScheduleShowSecondColumn(props: {
    isPaused: boolean;
    setMode: (newMode: "edit" | "delete" | "pause" | "unpause") => void;
    blueprintIdentifier: string;
}): JSX.Element {
    return (
        <React.Fragment>
            <ProtectedComponent
                restrictedToAnyOf={[
                    Permissions.ActivateAndDeactivateSchedulesOfAnyBlueprint,
                    Permissions.ActivateAndDeactivateSchedulesOfBlueprint(
                        props.blueprintIdentifier
                    ),
                ]}
            >
                {props.isPaused ? (
                    <IconButtonPlay onClick={() => props.setMode("unpause")} />
                ) : (
                    <IconButtonPause onClick={() => props.setMode("pause")} />
                )}
            </ProtectedComponent>
            <ProtectedComponent
                restrictedToAnyOf={[
                    Permissions.UpdateSchedulesOfAnyBlueprint,
                    Permissions.UpdateSchedulesOfBlueprint(props.blueprintIdentifier),
                ]}
            >
                <IconButtonEdit onClick={() => props.setMode("edit")} />
            </ProtectedComponent>
            <ProtectedComponent
                restrictedToAnyOf={[
                    Permissions.DeleteSchedulesOfAnyBlueprint,
                    Permissions.DeleteSchedulesOfBlueprint(props.blueprintIdentifier),
                ]}
            >
                <IconButtonDelete onClick={() => props.setMode("delete")} />
            </ProtectedComponent>
        </React.Fragment>
    );
}

function EditableScheduleDelete(props: {
    schedule: ScheduleMetadataForApi;
    onDone: () => void;
    refreshBlueprints: () => void;
}): JSX.Element {
    const api = useImanApi();

    return (
        <EditableScheduleTableRow
            firstCell={
                <ImanStack direction="row" spacing={2}>
                    <IconAlarm />
                    <span>{props.schedule.scheduleAsCronExpression}</span>
                    <span>
                        <FormattedMessage
                            id="blueprints.schedules.delete.message"
                            defaultMessage="Delete?"
                        />
                    </span>
                </ImanStack>
            }
            secondCell={
                <React.Fragment>
                    <IconButtonSubmit
                        onClick={() => {
                            api.deleteSchedule(props.schedule.identifier).then(() => {
                                props.refreshBlueprints();
                            });
                        }}
                    />
                    <IconButtonCancel
                        onClick={() => {
                            props.onDone();
                        }}
                    />
                </React.Fragment>
            }
        />
    );
}

function EditableScheduleEdit(props: {
    schedule: ScheduleMetadataForApi;
    onDone: () => void;
    refreshBlueprints: () => void;
}): JSX.Element {
    const [newValue, setNewValue] = useState(props.schedule.scheduleAsCronExpression);
    const [isWaiting, setIsWaiting] = useState(false);
    const api = useImanApi();

    return (
        <EditableScheduleTableRow
            firstCell={
                <ImanForm
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <SimpleInput
                        labelI18nIdentifier="blueprints.edit.schedule"
                        defaultLabel="Schedule"
                        isValueValid={true}
                        value={newValue ? newValue : ""}
                        onChange={(event) => {
                            setNewValue(event.target.value);
                        }}
                    />
                </ImanForm>
            }
            secondCell={
                <React.Fragment>
                    <IconButtonSubmit
                        isFetching={isWaiting}
                        isActive={newValue !== props.schedule.scheduleAsCronExpression}
                        onClick={() => {
                            if (newValue !== null && newValue !== undefined) {
                                setIsWaiting(true);
                            }
                            if (newValue !== null && newValue !== undefined) {
                                api.updateSchedule(props.schedule.identifier, newValue).then(() => {
                                    setIsWaiting(false);
                                    props.refreshBlueprints();
                                    props.onDone();
                                });
                            }
                        }}
                    />
                    <IconButtonCancel onClick={() => props.onDone()} />
                </React.Fragment>
            }
        />
    );
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
function AddNewScheduleForm(props: {
    blueprintIdentifier: string;
    refreshBlueprints: () => void;
    isInAddScheduleMode: boolean;
    onAddingDone: () => void;
}): JSX.Element {
    const [newValue, setNewValue] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [resultType, setResultType] =
        useState<CreateBlueprintScheduleResponseResultEnum | null>(null);
    const [isWaitingForAnswer, setIsWaiting] = useState(false);
    const api = useImanApi();

    function handleResponse(response: CreateBlueprintScheduleResponse) {
        setIsWaiting(false);
        setResultType(response.result);
        setSnackbarOpen(true);
        if (response.result === CreateBlueprintScheduleResponseResultEnum.Success) {
            setNewValue("");
            props.onAddingDone();
            props.refreshBlueprints();
        }
    }

    if (!props.isInAddScheduleMode) {
        return <React.Fragment />;
    } else {
        return (
            <EditableScheduleTableRow
                firstCell={
                    <ImanForm
                        onSubmit={(event) => {
                            event.preventDefault();
                        }}
                    >
                        {resultType !== null && (
                            <ResultSnackbar
                                result={resultType}
                                snackbarOpen={snackbarOpen}
                                setSnackbarOpen={setSnackbarOpen}
                            />
                        )}
                        <ImanStack direction="row">
                            <SimpleInput
                                labelI18nIdentifier="blueprints.schedule.add.label"
                                defaultLabel="New schedule"
                                placeholder="* * * ? * * *"
                                isValueValid={
                                    resultType === null ||
                                    resultType === CreateBlueprintScheduleResponseResultEnum.Success
                                }
                                value={newValue ? newValue : ""}
                                onChange={(event) => {
                                    setNewValue(event.target.value);
                                }}
                            />
                        </ImanStack>
                    </ImanForm>
                }
                secondCell={
                    <React.Fragment>
                        <IconButtonSubmit
                            isFetching={isWaitingForAnswer}
                            isActive={newValue !== ""}
                            onClick={() => {
                                if (newValue !== null && newValue !== undefined) {
                                    setIsWaiting(true);
                                    api.createSchedule(props.blueprintIdentifier, newValue).then(
                                        (response) => handleResponse(response)
                                    );
                                }
                            }}
                        />
                        <IconButtonCancel
                            onClick={() => {
                                setNewValue("");
                                props.onAddingDone();
                            }}
                        />
                    </React.Fragment>
                }
            />
        );
    }
}

function ResultSnackbar(props: {
    result: CreateBlueprintScheduleResponseResultEnum;
    snackbarOpen: boolean;
    setSnackbarOpen: (newValue: boolean) => void;
}): JSX.Element {
    const messageData = calculateMessageData(props.result);

    return (
        <Snackbar
            open={props.snackbarOpen}
            autoHideDuration={6000}
            onClose={() => {
                props.setSnackbarOpen(false);
            }}
        >
            <Alert
                onClose={() => props.setSnackbarOpen(false)}
                severity={messageData.severity}
                sx={{ width: "100%" }}
            >
                <FormattedMessage
                    id={messageData.messageId}
                    defaultMessage={messageData.messageDefault}
                />
            </Alert>
        </Snackbar>
    );
}

interface MessageData {
    messageId: string;
    messageDefault: string;
    severity: AlertColor;
}

function calculateMessageData(result: CreateBlueprintScheduleResponseResultEnum): MessageData {
    switch (result) {
        case CreateBlueprintScheduleResponseResultEnum.Success:
            return {
                messageId: "blueprints.edit.schedule.success",
                messageDefault: "",
                severity: "success",
            };
        case CreateBlueprintScheduleResponseResultEnum.InvalidExpression:
            return {
                messageId: "blueprints.edit.schedule.error.invalidexpression",
                messageDefault: "",
                severity: "error",
            };
        default:
            return {
                messageId: "blueprints.edit.schedule.error.unknownerror",
                messageDefault: "",
                severity: "error",
            };
    }
}
