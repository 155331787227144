import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#0E32EE",
            // light: "#546BFF",
            // dark: "#0635B5",
        },
        secondary: {
            main: "#505767",
            light: "#CED9E7"[150],
            dark: "#505767",
        },
        text: {
            primary: "#000000DE",
            // secondary: "#0000008A",
            // disabled: "#00000061",
        },
        action: {
            disabled: "#00000042",
            hover: "#0000000A",
            selected: "#00000014",
        },
        background: {
            default: "#E6EAF0",
        },
    },
    typography: {
        fontSize: 14,
        h1: {
            fontSize: "96px",
        },
        h2: {
            fontSize: "60px",
        },
        h3: {
            fontSize: "48px",
        },
        h4: {
            fontSize: "34px",
        },
        h5: {
            fontSize: "20px",
            fontWeight: 300,
        },
        h6: {
            fontSize: "20px",
        },
        body1: {
            fontSize: "16px",
        },
        body2: {
            fontSize: "14px",
        },
        subtitle1: {
            fontSize: "16px",
        },
        subtitle2: {
            fontSize: "14px",
        },
        button: {
            fontSize: "14px",
            textTransform: undefined,
        },
        caption: {
            fontSize: "12px",
        },
        overline: {
            fontSize: "12px",
        },
    },
    transitions: {
        // Deactivate transitions to avoid rendering artifacts (blinking) and weak performance
        create: () => "none",
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                // Deactivate ripple to avoid rendering issues and weak performance
                disableRipple: true,
            },
        },
    },
});
