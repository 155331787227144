import { FormEvent } from "react";
import React from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";

export function ImanForm(props: {
    children: React.ReactNode;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    submitButton?: React.ReactNode;
}): JSX.Element {
    return (
        <form onSubmit={props.onSubmit}>
            {props.children}
            {props.submitButton ? <Box padding={4}>{props.submitButton}</Box> : null}
        </form>
    );
}

export function ImanCheckBox(props: {
    label: string;
    isChecked: boolean;
    onClick: () => void;
}): JSX.Element {
    return (
        <FormControlLabel
            control={<Checkbox checked={props.isChecked} onClick={props.onClick} />}
            label={props.label}
        />
    );
}
