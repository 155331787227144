import { FormattedMessage } from "react-intl";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ImanSubmitButton } from "./wrappers/Buttons";
import { useImanApi } from "../util/ApiUtil";
import { useHistory } from "react-router-dom";
import { ImanForm } from "./wrappers/form/Form";
import { SimpleInput } from "./wrappers/form/TextInputs";
import { CenteredCard, ImanCardTitle } from "./wrappers/ImanCard";
import { ImanContainer, ImanLogoImage, ImanStack } from "./wrappers/ImanLayout";
import { H6, Subtitle2 } from "./wrappers/ImanTypography";
import { useRecoilValue } from "recoil";
import { recoilAppInfo } from "../recoilStore";
import { Routes } from "../functionality/Routes";

export function LoginPage(): JSX.Element {
    const appInfo = useRecoilValue(recoilAppInfo);
    return (
        <ImanContainer maxWidth="sm">
            <CenteredCard
                headerContent={<ImanLogoImage width="65%" />}
                footerContent={
                    <ImanStack align="start" spacing={0}>
                        <H6>{appInfo.company}</H6>
                        <Subtitle2>{appInfo.environment}</Subtitle2>
                    </ImanStack>
                }
            >
                <ImanStack spacing={1}>
                    <ImanCardTitle>
                        <FormattedMessage id="login.title" defaultMessage="Login" />
                    </ImanCardTitle>
                    <LoginFormContainer destination={Routes.DASHBOARD} />
                </ImanStack>
            </CenteredCard>
        </ImanContainer>
    );
}

function LoginFormLayout(
    submitLogin: (event: React.FormEvent<HTMLFormElement>) => void,
    inputRef: React.RefObject<HTMLInputElement>,
    showError: boolean,
    emailAddress: string,
    setEmailAddress: (value: ((prevState: string) => string) | string) => void,
    password: string,
    setPassword: (value: ((prevState: string) => string) | string) => void,
    areBothInputsFilled: boolean,
    isFetching: boolean
) {
    return (
        <ImanForm
            onSubmit={submitLogin}
            submitButton={
                <ImanSubmitButton
                    isActive={areBothInputsFilled}
                    isFetching={isFetching}
                    ariaLabel={"login-submit"}
                >
                    <FormattedMessage id="login.submit" defaultMessage="Submit" />
                </ImanSubmitButton>
            }
        >
            <ImanStack>
                <SimpleInput
                    labelI18nIdentifier="login.emailAddress"
                    defaultLabel="Identifier"
                    inputRef={inputRef}
                    isValueValid={!showError}
                    required={true}
                    value={emailAddress}
                    onChange={(event) => {
                        setEmailAddress(event.target.value);
                    }}
                />
                <SimpleInput
                    password
                    labelI18nIdentifier="login.password"
                    defaultLabel="Password"
                    value={password}
                    isValueValid={!showError}
                    required={true}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                />
            </ImanStack>
        </ImanForm>
    );
}

// eslint-disable-next-line max-lines-per-function
function LoginFormContainer(props: { destination: string }): JSX.Element {
    const history = useHistory();
    const imanApi = useImanApi();
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [isFetching, setIsFetching] = useState(false);
    const [hasLoginFailed, setHasLoginFailed] = useState(false);

    const areBothInputsFilled = emailAddress.length > 0 && password.length > 0;
    const showError = hasLoginFailed && !areBothInputsFilled;

    const submitLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsFetching(true);
        imanApi.postLogin(
            emailAddress,
            password,
            () => {
                setEmailAddress("");
                setPassword("");
                setIsFetching(false);
                setHasLoginFailed(false);

                // go to destination
                history.push(props.destination);
            },
            () => {
                setPassword("");
                setIsFetching(false);
                setHasLoginFailed(true);
            }
        );
    };

    return LoginFormLayout(
        submitLogin,
        inputRef,
        showError,
        emailAddress,
        setEmailAddress,
        password,
        setPassword,
        areBothInputsFilled,
        isFetching
    );
}
