import { QUERY_PARAMETER_KEYS, Routes } from "./Routes";

export function createPasswordLink(location: Location, token: string): string {
    const params = new URLSearchParams();
    params.set(QUERY_PARAMETER_KEYS.TOKEN, token);
    const paramsAsString = params.toString();
    return window.location.origin + Routes.SET_PASSWORD + "?" + paramsAsString;
}

export function getTokenFromSearchParameters(
    searchParameters: URLSearchParams
): string | undefined {
    return searchParameters.get(QUERY_PARAMETER_KEYS.TOKEN) ?? undefined;
}

export function isRepeatedPasswordValid(newPassword1: string, newPassword2: string): boolean {
    return newPassword2.length > 0 && newPassword1 === newPassword2;
}
