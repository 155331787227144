import {
    DefaultApiApiV1InternalJobsAllGetRequest,
    DefaultApiApiV1InternalLogsGetRequest,
    JobForApiStatusEnum,
    LogEntryApiLevelEnum,
} from "../client";
import {
    JobsFilterParameters,
    jobsFilterParametersToUrlSearchParams,
} from "../functionality/jobs/JobsUtil";

const KEY_PAGE = "page";
const KEY_ENTRIES_PER_PAGE = "entriesPerPage";
const KEY_BLUEPRINT = "blueprint";
const KEY_START = "start";
const KEY_END = "end";
const KEY_RESULT = "result";
const KEY_SORT = "sort";

const KEY_LEVEL = "level";
const KEY_SUBJECT = "subject";
const KEY_FREE_TEXT = "freeText";
const KEY_AFTER = "after";
const KEY_JOB_IDS = "jobIds";
const KEY_BLUEPRINTS = "blueprints";
const KEY_LOGGABLES = "loggables";
const LIST_SEPARATOR = "||";

export function createJobsLinkDepr(filterParams: DefaultApiApiV1InternalJobsAllGetRequest): string {
    return "/jobs?" + jobsFilterParamsToURLSearchParams(filterParams).toString();
}

export function createJobsLink(
    filterParams: JobsFilterParameters,
    paginationParameters: PaginationParameters
): string {
    const urlSearchParams = jobsFilterParametersToUrlSearchParams(filterParams);
    const urlSearchParamsFull = addPaginationParametersToUrlSearchParams(
        urlSearchParams,
        paginationParameters
    );

    return "/jobs?" + urlSearchParamsFull.toString();
}

export function createLogsLink(filterParams: DefaultApiApiV1InternalLogsGetRequest): string {
    return "/logs?" + logsFilterParamsToURLSearchParams(filterParams).toString();
}

export function jobsFilterParamsToURLSearchParams(
    filterParams: DefaultApiApiV1InternalJobsAllGetRequest
): URLSearchParams {
    const params = new URLSearchParams();

    params.set(KEY_PAGE, filterParams.page.toString());
    params.set(KEY_ENTRIES_PER_PAGE, filterParams.entriesPerPage.toString());

    if (filterParams.blueprints !== undefined) {
        // TODO: handle multiple blueprints
        params.set(KEY_BLUEPRINT, filterParams.blueprints[0]);
    }

    if (filterParams.rangeStartInEpochMillis !== undefined) {
        params.set(KEY_START, filterParams.rangeStartInEpochMillis.toString());
    }

    if (filterParams.rangeEndInEpochMillis !== undefined) {
        params.set(KEY_END, filterParams.rangeEndInEpochMillis.toString());
    }

    if (filterParams.status !== undefined) {
        params.set(KEY_RESULT, filterParams.status);
    }

    return params;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function logsFilterParamsToURLSearchParams(
    filterParams: DefaultApiApiV1InternalLogsGetRequest
): URLSearchParams {
    const params = new URLSearchParams();

    params.set(KEY_PAGE, filterParams.page.toString());
    params.set(KEY_ENTRIES_PER_PAGE, filterParams.entriesPerPage.toString());

    if (filterParams.level !== undefined) {
        params.set(KEY_LEVEL, filterParams.level);
    }

    if (filterParams.subject !== undefined) {
        params.set(KEY_SUBJECT, filterParams.subject);
    }

    if (filterParams.freeText !== undefined && filterParams.freeText.trim().length > 0) {
        params.set(KEY_FREE_TEXT, filterParams.freeText);
    }

    if (filterParams.logsBeforeEpochMillis !== undefined) {
        params.set(KEY_AFTER, filterParams.logsBeforeEpochMillis.toString());
    }

    if (filterParams.rangeStartInEpochMillis !== undefined) {
        params.set(KEY_START, filterParams.rangeStartInEpochMillis.toString());
    }

    if (filterParams.rangeEndInEpochMillis !== undefined) {
        params.set(KEY_END, filterParams.rangeEndInEpochMillis.toString());
    }

    params.set(KEY_SORT, filterParams.sort);

    if (filterParams.jobIds !== undefined && filterParams.jobIds.length > 0) {
        addListToParams(params, KEY_JOB_IDS, filterParams.jobIds);
    }

    if (filterParams.loggableTypes !== undefined && filterParams.loggableTypes.length > 0) {
        addListToParams(params, KEY_LOGGABLES, filterParams.loggableTypes);
    }

    if (
        filterParams.blueprintIdentifiers !== undefined &&
        filterParams.blueprintIdentifiers.length > 0
    ) {
        addListToParams(params, KEY_BLUEPRINTS, filterParams.blueprintIdentifiers);
    }

    return params;
}

function addListToParams(params: URLSearchParams, key: string, list: string[]): URLSearchParams {
    params.set(key, encodeURI(list.join(LIST_SEPARATOR)));
    return params;
}

function extractList(urlSearchParams: URLSearchParams, key: string): string[] | undefined {
    const rawValue = urlSearchParams.get(key);
    if (rawValue === null) return undefined;

    const decoded = decodeURI(rawValue);
    return decoded.split(LIST_SEPARATOR);
}

export function extractNumberOrUndefined(
    urlSearchParams: URLSearchParams,
    key: string
): number | undefined {
    const value = urlSearchParams.get(key);
    if (value === null) return undefined;
    const parsedValue = parseInt(value);
    if (isNaN(parsedValue)) return undefined;
    return parsedValue;
}

function extractStatusEnum(
    urlSearchParams: URLSearchParams,
    key: string
): JobForApiStatusEnum | undefined {
    const value = urlSearchParams.get(key);
    if (value === null) return undefined;

    return Object.values(JobForApiStatusEnum).find((it) => {
        return it.toString() === value;
    });
}

function extractLevelEnum(urlSearchParams: URLSearchParams): LogEntryApiLevelEnum | undefined {
    const value = urlSearchParams.get(KEY_LEVEL);
    if (value === null) return undefined;

    return Object.values(LogEntryApiLevelEnum).find((it) => {
        return it.toString() === value;
    });
}

function extractSubject(urlSearchParams: URLSearchParams): string {
    const value = urlSearchParams.get(KEY_SUBJECT);
    if (value === null) return "/";

    return value;
}

function extractSort(urlSearchParams: URLSearchParams): "NEWEST_FIRST" | "OLDEST_FIRST" {
    const value = urlSearchParams.get(KEY_SORT);
    if (value === "NEWEST_FIRST") {
        return "NEWEST_FIRST";
    } else if (value === "OLDEST_FIRST") {
        return "OLDEST_FIRST";
    } else {
        return "NEWEST_FIRST";
    }
}

function extractFreeText(urlSearchParams: URLSearchParams): string | undefined {
    const value = urlSearchParams.get(KEY_FREE_TEXT);
    if (value === null) return undefined;

    return value;
}

export function urlSearchParamsToJobsFilterParams(
    urlParams: URLSearchParams
): DefaultApiApiV1InternalJobsAllGetRequest | null {
    const page = extractNumberOrUndefined(urlParams, KEY_PAGE);
    const entriesPerPage = extractNumberOrUndefined(urlParams, KEY_ENTRIES_PER_PAGE);

    if (page === undefined || entriesPerPage === undefined) return null;

    // TODO: switch to multiple blueprints
    const blueprint = urlParams.get(KEY_BLUEPRINT);
    return {
        blueprints: blueprint ? [blueprint] : undefined,
        entriesPerPage: entriesPerPage,
        page: page,
        rangeStartInEpochMillis: extractNumberOrUndefined(urlParams, KEY_START) ?? undefined,
        rangeEndInEpochMillis: extractNumberOrUndefined(urlParams, KEY_END) ?? undefined,
        status: extractStatusEnum(urlParams, KEY_RESULT),
    };
}

export function urlSearchParamsToLogsFilterParams(
    urlParams: URLSearchParams,
    localeCode: string
): DefaultApiApiV1InternalLogsGetRequest | null {
    const page = extractNumberOrUndefined(urlParams, KEY_PAGE);
    const entriesPerPage = extractNumberOrUndefined(urlParams, KEY_ENTRIES_PER_PAGE);

    if (page === undefined || entriesPerPage === undefined) return null;

    return {
        entriesPerPage: entriesPerPage,
        level: extractLevelEnum(urlParams) ?? "TRACE",
        localeCode: localeCode,
        page: page,
        subject: extractSubject(urlParams),
        freeText: extractFreeText(urlParams),
        logsBeforeEpochMillis: extractNumberOrUndefined(urlParams, KEY_AFTER),
        rangeStartInEpochMillis: extractNumberOrUndefined(urlParams, KEY_START),
        rangeEndInEpochMillis: extractNumberOrUndefined(urlParams, KEY_END),
        jobIds: extractList(urlParams, KEY_JOB_IDS),
        blueprintIdentifiers: extractList(urlParams, KEY_BLUEPRINTS),
        loggableTypes: extractList(urlParams, KEY_LOGGABLES),
        sort: extractSort(urlParams),
    };
}

type PagingSettings = {
    currentPage: number;
    firstPageInFrame: number;
    lastPageInFrame: number;
    numberOfPagesInFrame: number;
};

export interface PaginationParameters {
    page: number;
    entriesPerPage: number;
}

export function extractPaginationParametersFromUrlSearchParams(
    urlSearchParams: URLSearchParams,
    defaultEntriesPerPage: number
): PaginationParameters {
    const page = extractNumberOrUndefined(urlSearchParams, KEY_PAGE) ?? 1;
    const entriesPerPage =
        extractNumberOrUndefined(urlSearchParams, KEY_ENTRIES_PER_PAGE) ?? defaultEntriesPerPage;
    return {
        page: page,
        entriesPerPage: entriesPerPage,
    };
}

export function addPaginationParametersToUrlSearchParams(
    urlSearchParams: URLSearchParams,
    paginationParameters: PaginationParameters
): URLSearchParams {
    urlSearchParams.set(KEY_PAGE, paginationParameters.page.toString());
    urlSearchParams.set(KEY_ENTRIES_PER_PAGE, paginationParameters.entriesPerPage.toString());
    return urlSearchParams;
}

export function calculatePagingSettings(
    currentPage: number,
    numberOfPages: number,
    pagesAtStart: number,
    pagesAtEnd: number
): PagingSettings {
    let firstPageInFrame = Math.max(1, currentPage - pagesAtStart);
    let lastPageInFrame = Math.min(currentPage + pagesAtEnd, numberOfPages);

    const actualPagesAtStart = currentPage - firstPageInFrame;
    if (actualPagesAtStart < pagesAtStart) {
        const diff = pagesAtStart - actualPagesAtStart;
        lastPageInFrame = Math.min(lastPageInFrame + diff, numberOfPages);
    }

    const actualPagesAtEnd = lastPageInFrame - currentPage;
    if (actualPagesAtEnd < pagesAtEnd) {
        const diff = pagesAtEnd - actualPagesAtEnd;
        firstPageInFrame = Math.max(1, firstPageInFrame - diff);
    }

    return {
        currentPage: currentPage,
        firstPageInFrame: firstPageInFrame,
        lastPageInFrame: lastPageInFrame,
        numberOfPagesInFrame: lastPageInFrame - firstPageInFrame + 1,
    };
}
