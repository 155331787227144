import { useIntl } from "react-intl";
import React, { ChangeEvent, KeyboardEventHandler } from "react";
import { ImanButton } from "../Buttons";
import { InputAdornment, styled, TextField, TextFieldProps } from "@mui/material";

export function SimpleInput(props: {
    labelI18nIdentifier: string;
    defaultLabel: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    isValueValid?: boolean;
    password?: boolean;
    required?: boolean;
    placeholder?: string;
    disabled?: boolean;
    inputRef?: React.Ref<HTMLInputElement>;
    id?: string;
    fullwidth?: boolean;
}): JSX.Element {
    const intl = useIntl();
    const showError = props.isValueValid === undefined ? false : !props.isValueValid;
    const labelText = intl.formatMessage({
        id: props.labelI18nIdentifier,
        defaultMessage: props.defaultLabel,
    });

    return (
        <StyledTextField
            id={props.id}
            fullWidth={props.fullwidth ? props.fullwidth : false}
            required={props.required}
            placeholder={props.placeholder}
            disabled={props.disabled}
            label={labelText}
            inputRef={props.inputRef}
            value={props.value}
            onChange={props.onChange}
            error={showError}
            variant="filled"
            type={props.password ? "password" : "text"}
        />
    );
}

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
    minWidth: "325px",
}));

export function TextInputWithButton(props: {
    value: string;
    onClick: () => void;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    labelText?: string;
    children: React.ReactNode;
    onKeyUp?: KeyboardEventHandler<HTMLDivElement>;
}): JSX.Element {
    const disabled = props.onChange === undefined;
    return (
        <TextField
            variant="outlined"
            sx={{ width: "100%" }}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            disabled={disabled}
            label={props.labelText}
            onKeyUp={props.onKeyUp}
            InputProps={{
                endAdornment: (
                    // TODO: find another way to align the buttons of AutoComplete and TextInputWithButton
                    <InputAdornment position="end" sx={{ marginRight: "23px" }}>
                        <ImanButton color={"primary"} onClick={props.onClick}>
                            {props.children}
                        </ImanButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}
