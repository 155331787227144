import { BlueprintBaseDataForApi, BlueprintMetadataForApi } from "../client";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { BlueprintInfoCard } from "./BlueprintInfoCard";
import { useImanApi } from "../util/ApiUtil";
import { useRecoilValue } from "recoil";
import { recoilHasToken } from "../recoilStore";
import { ImanButton } from "./wrappers/Buttons";
import { ImanBox, ImanStack } from "./wrappers/ImanLayout";
import { ImanPageHeader } from "./wrappers/ImanPage";

type RefreshButtonProps = {
    refreshAction: () => void;
};

function RefreshButton(props: RefreshButtonProps) {
    return (
        <ImanButton onClick={props.refreshAction} color="secondary">
            <FormattedMessage id={"blueprints.refresh"} defaultMessage={"↻"} />
        </ImanButton>
    );
}

// eslint-disable-next-line max-lines-per-function
export function BlueprintsPage(): JSX.Element {
    const [didFetchData, setDidFetchData] = useState(false);
    const [additionalData, setAdditionalData] = useState<BlueprintMetadataForApi[]>([]);
    const [blueprintsBaseData, setBlueprintsBaseData] = useState<BlueprintBaseDataForApi[]>([]);
    const hasToken = useRecoilValue(recoilHasToken);

    const imanApi = useImanApi();

    function fetchBlueprintsLocal() {
        imanApi.fetchBlueprints((receivedBlueprints) => {
            setAdditionalData(receivedBlueprints.blueprints);
        });
        imanApi
            .fetchBlueprintsBaseData()
            .then((response) => setBlueprintsBaseData(response.blueprints));
    }

    useEffect(() => {
        if (!didFetchData && hasToken) {
            fetchBlueprintsLocal();
            setDidFetchData(true);
        }
    }, [hasToken]);

    return (
        <ImanBox className="iman-schedules-page">
            <ImanStack align="start" spacing={6}>
                <ImanPageHeader titleId="blueprints.header" titleDefaultMessage="Blueprints">
                    <RefreshButton
                        refreshAction={() => {
                            fetchBlueprintsLocal();
                        }}
                    />
                </ImanPageHeader>
                <ImanStack align="start" spacing={5}>
                    {blueprintsBaseData.map((bp) => {
                        return (
                            <BlueprintInfoCard
                                key={bp.identifier}
                                blueprint={bp}
                                additionalData={additionalData.find(
                                    (bpAdd) => bpAdd.identifier === bp.identifier
                                )}
                                startBlueprint={() => {
                                    // eslint-disable-next-line sonarjs/no-identical-functions
                                    imanApi.startJob(bp.identifier, () => {
                                        fetchBlueprintsLocal();
                                    });
                                }}
                                refreshBlueprints={() => fetchBlueprintsLocal()}
                            />
                        );
                    })}
                </ImanStack>
            </ImanStack>
        </ImanBox>
    );
}
