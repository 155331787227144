import React from "react";
import { BsTrash } from "react-icons/bs";
import { CustomBadge } from "./wrappers/CustomBadge";
import { SubmittableTextInput } from "./wrappers/SubmittableTextInput";
import { AutoCompleteInput } from "./AutoComplete";
import { ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { ImanButton } from "./wrappers/Buttons";

function ValuesRow(props: { values: string[]; setValues: (newValues: string[]) => void }) {
    return (
        <ImanRow>
            {props.values.map((value) => {
                return (
                    <ImanGridCell key={value} xs={6}>
                        <CustomBadge>
                            <ImanRow>
                                <ImanGridCell xs={10}>{value}</ImanGridCell>
                                <ImanGridCell xs={2}>
                                    <ImanButton
                                        variant="outlined"
                                        size="small"
                                        onClick={() => {
                                            const newValues = props.values.filter(
                                                (s) => s !== value
                                            );
                                            props.setValues(newValues);
                                        }}
                                    >
                                        <BsTrash />
                                    </ImanButton>
                                </ImanGridCell>
                            </ImanRow>
                        </CustomBadge>
                    </ImanGridCell>
                );
            })}
        </ImanRow>
    );
}

function InputRow(props: {
    id?: string;
    label?: string;
    values: string[];
    setValues: (newValues: string[]) => void;
    suggestionsProvider?: (currentValue: string) => string[];
}): JSX.Element {
    return (
        <React.Fragment>
            {props.suggestionsProvider === undefined ? (
                <SubmittableTextInput
                    label={props.label}
                    submitValue={(newValue) => {
                        const newValues = [...props.values, newValue];
                        props.setValues(newValues);
                    }}
                />
            ) : (
                <AutoCompleteInput
                    id={props.id + "-autocomplete"}
                    label={props.label}
                    submitValue={(newValue) => {
                        const newValues = [...props.values, newValue];
                        props.setValues(newValues);
                    }}
                    fetchSuggestions={(currentValue) => {
                        if (props.suggestionsProvider !== undefined) {
                            const suggestions = props.suggestionsProvider(currentValue);
                            return suggestions.filter((suggestion) => {
                                return props.values.indexOf(suggestion) === -1;
                            });
                        } else {
                            return [];
                        }
                    }}
                />
            )}
        </React.Fragment>
    );
}

export function MultiEntriesInput(props: {
    label?: string;
    values: string[];
    setValues: (newValues: string[]) => void;
    placeholder?: string;
    suggestionsProvider?: (currentValue: string) => string[];
}): JSX.Element {
    return (
        <React.Fragment>
            <InputRow
                label={props.label}
                values={props.values}
                setValues={props.setValues}
                suggestionsProvider={props.suggestionsProvider}
            />
            <ValuesRow values={props.values} setValues={props.setValues} />
        </React.Fragment>
    );
}
