import { FormattedMessage } from "react-intl";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { recoilHasToken } from "../recoilStore";
import { ImanRow } from "./wrappers/ImanGrid";
import { Routes } from "../functionality/Routes";
import { ImanBox, ImanStack, ProtectedComponent } from "./wrappers/ImanLayout";
import { Permissions } from "../functionality/PermissionsUtil";
import { ProductDataToShopChart } from "./statistics/ProductDataToShopChart";
import { ImanPageHeader } from "./wrappers/ImanPage";
import { OrdersToSapChart } from "./statistics/OrdersToSapChart";

export function DashboardPage(): JSX.Element {
    const hasToken = useRecoilValue(recoilHasToken);

    return (
        <ImanBox>
            <ImanPageHeader titleId={"dashboard.title"} titleDefaultMessage={"Dashboard"} />
            <ImanStack>
                <ProtectedComponent restrictedToAnyOf={[Permissions.VdctAll]}>
                    <OrdersToSapChart />
                    <ProductDataToShopChart />
                </ProtectedComponent>
            </ImanStack>
            {hasToken ? (
                ""
            ) : (
                <ImanRow>
                    <Link to={Routes.LOGIN}>
                        <FormattedMessage id="login.title" defaultMessage="Login" />
                    </Link>
                </ImanRow>
            )}
        </ImanBox>
    );
}
