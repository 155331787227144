import {
    ActivateScheduleResponse,
    AppInfo,
    AuthResponse,
    BlueprintsBaseDataResponse,
    BlueprintsListResponse,
    ChangePasswordResponse,
    Configuration,
    CreateBlueprintScheduleResponse,
    CreateRoleResponse,
    CreateUserResponse,
    DeactivateScheduleResponse,
    DefaultApi,
    DefaultApiApiV1InternalJobsAllGetRequest,
    DefaultApiApiV1InternalLogsGetRequest,
    DeleteBlueprintScheduleResponse,
    DeleteRoleResponse,
    DeleteUserResponse,
    GetAllPermissionsResponse,
    GetAllRolesResponse,
    GetAllUsersWithRoleResponse,
    GetJobResponse,
    JobsMetadataResponse,
    LogsResponse,
    RoleForApi,
    RunningJobMetadataForApi,
    SetPasswordResponseResultEnum,
    StopApplicationResponse,
    StopJobResponseResultEnum,
    SystemInfoResponse,
    UpdateBlueprintScheduleResponse,
    UpdateRoleResponse,
    UpdateUserDataResponse,
    UserForApi,
} from "../client";
import {
    recoilHasToken,
    recoilLoggedInUser,
    recoilRolesOfLoggedInUser,
    recoilToken,
    recoilUserPermissions,
} from "../recoilStore";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import axios from "axios";
import {
    FrontendAccessRightsDepr,
    UserPermission,
} from "../functionality/frontendAccessRightsDepr";
import { useHistory } from "react-router-dom";
import { Routes } from "../functionality/Routes";
import { mapToUserAccessRightFrontend } from "../functionality/PermissionsUtil";
import { JobsFilterParameters, jobStatusToJobResult } from "../functionality/jobs/JobsUtil";
import { PaginationParameters } from "./LinkUtil";

// eslint-disable-next-line sonarjs/cognitive-complexity
export function useRefresh(didInitialRefresh: boolean, onDidInitialRefresh: () => void): void {
    const [didIntervalSetup, setDidIntervalSetup] = useState(false);
    const imanApi = useImanApi();
    const history = useHistory();

    const hasToken = useRecoilValue(recoilHasToken);

    if (hasToken && !didInitialRefresh) {
        onDidInitialRefresh();
    }

    const isOnLoginPage = window.location.pathname.includes(Routes.LOGIN);

    // We do not want to refresh if we are on the login page
    if (isOnLoginPage && !didInitialRefresh) {
        onDidInitialRefresh();
    }
    if (!didInitialRefresh && !hasToken && !isOnLoginPage) {
        imanApi.refreshAccessToken().catch(() => {
            history.push(Routes.LOGIN);
        });
        onDidInitialRefresh();
    }

    useEffect(() => {
        if (hasToken && !didIntervalSetup) {
            setInterval(() => {
                if (!isOnLoginPage) {
                    imanApi.refreshAccessToken().catch(() => {
                        history.push(Routes.DASHBOARD);
                    });
                }
            }, 90000);
            setDidIntervalSetup(true);
        }
    }, [hasToken]);
}

function isRefreshUrl(url: string | undefined): boolean {
    if (url === undefined) return false;
    return url.includes("refresh");
}

export function useImanApi(): ImanApi {
    const [token, setToken] = useRecoilState(recoilToken);
    const [, setLoggedInUser] = useRecoilState(recoilLoggedInUser);
    const [, setAccessRights] = useRecoilState(recoilUserPermissions);
    const [, setUserRoles] = useRecoilState(recoilRolesOfLoggedInUser);
    return new ImanApi(token, setToken, setLoggedInUser, setAccessRights, setUserRoles);
}

export class ImanApi {
    setToken: (token: string) => void;
    setLoggedInUser: (token: UserForApi) => void;
    setAccessRights: (rights: UserPermission[]) => void;
    setUserRoles: (roles: RoleForApi[]) => void;
    token: string | undefined;
    axiosInitialized = false;
    static currentlyRefreshing = false;

    constructor(
        token: string,
        setToken: (token: string) => void,
        setLoggedInUser: (user: UserForApi) => void,
        setAccessRights: (rights: UserPermission[]) => void,
        setUserRoles: (roles: RoleForApi[]) => void
    ) {
        this.token = token;
        this.setToken = setToken;
        this.setLoggedInUser = setLoggedInUser;
        this.setAccessRights = setAccessRights;
        this.setUserRoles = setUserRoles;
    }

    setTokenInternal(authResponse: AuthResponse): void {
        this.token = authResponse.jwt;
        this.setToken(authResponse.jwt);
        this.setLoggedInUser(authResponse.user);
        this.setAccessRights(
            authResponse.permissions.map((permission) => mapToUserAccessRightFrontend(permission))
        );
        this.setUserRoles(authResponse.user.roles);
    }

    hasToken(): boolean {
        return this.token !== undefined && this.token.length > 0;
    }

    hasEmptyAuthHeader(authHeader: string | undefined): boolean {
        return authHeader === "Bearer ";
    }

    // eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
    getDefaultApiInternal(): DefaultApi {
        if (!this.axiosInitialized) {
            // If there is no token: try to refresh before doing request
            axios.interceptors.request.use((requestConfig) => {
                const hasEmptyAuthHeader = this.hasEmptyAuthHeader(
                    "Authorization"
                    // requestConfig.headers["Authorization"]
                );
                if (!this.hasToken() && !isRefreshUrl(requestConfig.url) && hasEmptyAuthHeader) {
                    return this.getDefaultApiInternal()
                        .apiV1InternalRefreshPost()
                        .then((refreshResponse) => {
                            this.setTokenInternal(refreshResponse.data);
                            return requestConfig;
                        })
                        .catch(() => {
                            return requestConfig;
                        });
                } else {
                    return requestConfig;
                }
            });
            axios.interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    const originalConfig = error.config;
                    if (
                        error.response.status === 401 &&
                        !originalConfig._retry &&
                        !isRefreshUrl(originalConfig.url)
                    ) {
                        originalConfig._retry = true;
                        return this.getDefaultApiInternal()
                            .apiV1InternalRefreshPost()
                            .then((refreshResponse) => {
                                const jwt = refreshResponse.data.jwt;
                                this.setTokenInternal(refreshResponse.data);
                                originalConfig.headers.Authorization = `Bearer ${jwt}`;
                                return axios(originalConfig);
                            })
                            .catch(() => {
                                // nothing
                            });
                    }
                }
            );
            this.axiosInitialized = true;
        }

        const configuration = new Configuration({
            accessToken: this.token,
            basePath: " ",
        });
        return new DefaultApi(configuration);
    }

    getAllUsers(onSuccess: (users: UserForApi[]) => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalUserAllGet()
            .then((response) => {
                onSuccess(response.data.allUsers);
            })
            .catch(() => {
                console.log("Could not get users.");
            });
    }

    createNewUser(
        userIdentifier: string,
        name: string,
        emailAddress: string,
        accessRights: FrontendAccessRightsDepr,
        roles: RoleForApi[],
        onSuccess: (data: CreateUserResponse) => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalUserNewPost({
                name: name,
                emailAddress: emailAddress,
                roles: roles.map((role) => {
                    return role.identifier;
                }),
            })
            .then((response) => onSuccess(response.data));
    }

    updateUser(
        identifier: string,
        name: string,
        emailAddress: string,
        roles: string[]
    ): Promise<UpdateUserDataResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserPut({
                id: identifier,
                emailAddress: emailAddress,
                name: name,
                roles: roles,
            })
            .then((response) => response.data);
    }

    getAllRoles(): Promise<GetAllRolesResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserRolesGet()
            .then((response) => response.data);
    }

    createRole(name: string, accessRights: string[]): Promise<CreateRoleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserRolesCreatePost({
                name: name,
                accessRights: accessRights,
            })
            .then((response) => response.data);
    }

    getPasswordToken(
        userIdentifier: string,
        onSuccess: (token: string) => void,
        onFail: () => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalUserTokenGet({
                userIdentifier: userIdentifier,
            })
            .then((response) => {
                onSuccess(response.data);
            })
            .catch(() => {
                onFail();
            });
    }

    postLogin(
        emailAddress: string,
        password: string,
        onSuccess: (token: string) => void,
        onFailure: (reason: Error) => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalLoginPost({
                emailaddress: emailAddress,
                password: password,
            })
            .then((response) => {
                this.setTokenInternal(response.data);
                onSuccess(response.data.jwt);
            })
            .catch((reason) => {
                onFailure(reason);
            });
    }

    refreshAccessToken(): Promise<void> {
        if (!ImanApi.currentlyRefreshing) {
            ImanApi.currentlyRefreshing = true;
            return this.getDefaultApiInternal()
                .apiV1InternalRefreshPost()
                .then((response) => {
                    this.setTokenInternal(response.data);
                    ImanApi.currentlyRefreshing = false;
                })
                .catch(() => {
                    console.log("Could not refresh");
                    ImanApi.currentlyRefreshing = false;
                });
        }
        return Promise.reject();
    }

    fetchInfo(onSuccess: (info: AppInfo) => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalAppInfoGet()
            .then((response) => {
                onSuccess(response.data);
                // setSupportedLocales(response.data.supportedLocales);
            })
            .catch((reason) => console.log(`Could not fetch basic info from app: ${reason}`));
    }

    fetchBlueprints(callback: (blueprints: BlueprintsListResponse) => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalBlueprintsGet()
            .then((promise) => {
                callback(promise.data);
            })
            .catch((reason) => console.log("Could not fetch blueprints", reason));
    }

    fetchBlueprintsBaseData(): Promise<BlueprintsBaseDataResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsBaseGet()
            .then((promise) => promise.data);
    }

    startJob(blueprintIdentifier: string, onSuccess: () => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalJobsStartPost({
                blueprintIdentifier: blueprintIdentifier,
            })
            .then(() => {
                onSuccess();
                // fetchBlueprintsBound();
            })
            .catch((reason) => {
                console.log("Could not start blueprint", reason);
            });
    }

    fetchCurrentlyRunningJobs(
        blueprintIdentifiers: string[],
        setRunningJobs: (value: RunningJobMetadataForApi[]) => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalJobsRunningGet({
                blueprintIdentifiers: blueprintIdentifiers,
            })
            .then((promise) => {
                setRunningJobs(promise.data.jobs);
            })
            .catch((reason) => {
                console.error(`Error while trying to fetch running jobs: ${reason}`);
            });
    }

    fetchDoneJobs(
        currentPage: number,
        filterParams: DefaultApiApiV1InternalJobsAllGetRequest,
        setFetchResult: (data: JobsMetadataResponse) => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalJobsAllGet(filterParams)
            .then((promise) => {
                const data = promise.data;
                setFetchResult(data);
            })
            .catch((reason) => {
                console.error(`Error while trying to fetch done jobs: ${reason}`);
            });
    }

    getJobs(
        filterParams: JobsFilterParameters,
        paginationParameters: PaginationParameters
    ): Promise<JobsMetadataResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalJobsAllGet({
                blueprints:
                    filterParams.blueprintIdentifier !== null
                        ? [filterParams.blueprintIdentifier]
                        : undefined,
                entriesPerPage: paginationParameters.entriesPerPage,
                page: paginationParameters.page,
                rangeStartInEpochMillis: filterParams.rangeStartInEpochMillis,
                rangeEndInEpochMillis: filterParams.rangeEndInEpochMillis,
                status:
                    filterParams.jobStatus !== null
                        ? jobStatusToJobResult(filterParams.jobStatus)
                        : undefined,
            })
            .then((promise) => {
                return promise.data;
            });
    }

    getJobDetails(jobIdentifier: string): Promise<GetJobResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalJobsGet({
                jobIdentifier: jobIdentifier,
            })
            .then((response) => response.data);
    }

    fetchLoggableNames(setAllLoggableNames: (value: string[]) => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalLogsTypesGet()
            .then((response) => {
                setAllLoggableNames(response.data.loggableNames);
            })
            .catch((msg) => {
                console.error("Could not fetch loggable names", msg);
            });
    }

    fetchLogEntries(
        setResults: (data: LogsResponse) => void,
        onError: (msg: string) => void,
        params: DefaultApiApiV1InternalLogsGetRequest
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalLogsGet(params)
            .then((promise) => {
                setResults(promise.data);
            })
            .catch((reason) => {
                onError(reason);
            });
    }

    postChangePassword(
        emailAddress: string,
        oldPassword: string,
        newPassword: string,
        onSuccess: (response: ChangePasswordResponse) => void,
        onFailure: (reason: Error) => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalUserChangePasswordPost({
                emailaddress: emailAddress,
                newpassword: newPassword,
                oldpassword: oldPassword,
            })
            .then((response) => onSuccess(response.data))
            .catch((reason) => onFailure(reason));
    }

    postLogout(onSuccess: () => void, onFailure: (reason: Error) => void): void {
        this.getDefaultApiInternal()
            .apiV1InternalLogoutPost()
            .then(() => {
                onSuccess();
            })
            .catch((reason) => {
                onFailure(reason);
            });
    }

    postSetPassword(
        password: string,
        token: string,
        onSuccess: (result: SetPasswordResponseResultEnum) => void,
        onFailure: () => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalUserSetPasswordPost({
                password: password,
                token: token,
            })
            .then((response) => {
                onSuccess(response.data.result);
            })
            .catch(() => {
                onFailure();
            });
    }

    postJobCancel(
        jobIdentifier: string,
        type: "SOFT" | "HARD",
        onSuccess: (result: StopJobResponseResultEnum) => void,
        onFailure: () => void
    ): void {
        this.getDefaultApiInternal()
            .apiV1InternalJobsStopPost({
                jobIdentifier: jobIdentifier,
                type: type,
            })
            .then((response) => onSuccess(response.data.result))
            .catch(() => onFailure());
    }

    postEncryptValue(rawValue: string): Promise<string> {
        return this.getDefaultApiInternal()
            .apiV1InternalUtilEncryptPost({ value: rawValue })
            .then((response) => response.data.encryptedString);
    }

    fetchFileResource(resourceId: string): Promise<FileData> {
        return this.getDefaultApiInternal()
            .apiV1InternalResourcesResourceIdGet(
                {
                    resourceId: resourceId,
                },
                // when we don't set the response type, axios messes up the encoding
                { responseType: "arraybuffer" }
            )
            .then((response) => {
                return {
                    data: response.data,
                    contentType: response.headers["content-type"],
                };
            });
    }

    createSchedule(
        blueprintIdentifier: string,
        newCronExpression: string
    ): Promise<CreateBlueprintScheduleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsScheduleCreatePost({
                blueprintIdentifier: blueprintIdentifier,
                newCronExpression: newCronExpression,
            })
            .then((response) => response.data);
    }

    deleteSchedule(scheduleIdentifier: string): Promise<DeleteBlueprintScheduleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsScheduleDeletePost({
                scheduleId: scheduleIdentifier,
            })
            .then((response) => response.data);
    }

    updateSchedule(
        scheduleIdentifier: string,
        newCronExpression: string
    ): Promise<UpdateBlueprintScheduleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsScheduleUpdatePost({
                scheduleId: scheduleIdentifier,
                newCronExpression: newCronExpression,
            })
            .then((response) => response.data);
    }

    pauseSchedules(scheduleIdentifiers: string[]): Promise<DeactivateScheduleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsScheduleDeactivatePost({
                scheduleIdentifiers: scheduleIdentifiers,
            })
            .then((response) => response.data);
    }

    unpauseSchedules(scheduleIdentifiers: string[]): Promise<ActivateScheduleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalBlueprintsScheduleActivatePost({
                scheduleIds: scheduleIdentifiers,
            })
            .then((response) => response.data);
    }

    getSystemInfo(): Promise<SystemInfoResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalAppSystemGet()
            .then((response) => response.data);
    }

    getAllPermissions(): Promise<GetAllPermissionsResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserPermissionsGet()
            .then((response) => response.data);
    }

    updateRole(
        roleIdentifier: string,
        name: string,
        permissions: string[]
    ): Promise<UpdateRoleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserRolesUpdatePost({
                roleIdentifier: roleIdentifier,
                name: name,
                permissions: permissions,
            })
            .then((response) => response.data);
    }

    getAllUsersWithRole(roleIdentifier: string): Promise<GetAllUsersWithRoleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserRolesDetailsGet({
                roleIdentifier: roleIdentifier,
            })
            .then((response) => response.data);
    }

    deleteRole(roleIdentifier: string): Promise<DeleteRoleResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserRolesDeletePost({
                roleIdentifier: roleIdentifier,
            })
            .then((response) => response.data);
    }

    deleteUser(userIdentifier: string): Promise<DeleteUserResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalUserDelete({
                identifier: userIdentifier,
            })
            .then((response) => response.data);
    }

    stopApplication(password: string, mode: "SOFT" | "HARD"): Promise<StopApplicationResponse> {
        return this.getDefaultApiInternal()
            .apiV1InternalAppStopPost({
                stopMode: mode,
                password: password,
            })
            .then((response) => response.data);
    }

    downloadJobData(jobId: string): Promise<BlobWithFilename> {
        const filename = jobId + ".zip";
        return this.getDefaultApiInternal()
            .apiV1InternalJobsDownloadDataGet(
                {
                    jobId: jobId,
                    locale: "de",
                    prettyPrint: false,
                },
                // when we don't set the response type, axios messes up the encoding
                { responseType: "arraybuffer" }
            )
            .then((response) => {
                const contentType = response.headers["Content-type"];
                const blob = new Blob([response.data], { type: contentType });
                return {
                    blob: blob,
                    filename: filename,
                };
            });
    }
}

// https://stackoverflow.com/questions/65415480/how-do-you-trigger-a-file-download-in-react
export function triggerDownload(blobWithFilename: BlobWithFilename): void {
    const url = URL.createObjectURL(blobWithFilename.blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = blobWithFilename.filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
}

export interface FileData {
    data: ArrayBuffer;
    contentType: string;
}

export interface BlobWithFilename {
    blob: Blob;
    filename: string;
}
