import { Box, Card, CardActions, CardProps, Grid, styled } from "@mui/material";
import React from "react";
import { ImanDivider } from "./ImanLayout";
import { H2 } from "./ImanTypography";

export function CenteredCard(props: {
    children: React.ReactNode;
    headerContent?: React.ReactNode;
    footerContent?: React.ReactNode;
}): JSX.Element {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: "100vh" }}
        >
            <ImanCard headerContent={props.headerContent} footerContent={props.footerContent}>
                {props.children}
            </ImanCard>
        </Grid>
    );
}

export function ImanCard(props: {
    children?: React.ReactNode;
    className?: string;
    headerContent?: React.ReactNode;
    footerContent?: React.ReactNode;
    width?: string;
}): JSX.Element {
    return (
        <StyledCard className={props.className} elevation={4} sx={{ width: props.width }}>
            {props.headerContent ? <ImanCardHeader>{props.headerContent}</ImanCardHeader> : null}

            <ImanCardContent paddingY={4}>{props.children}</ImanCardContent>

            {props.footerContent ? <ImanDivider /> : null}
            {props.footerContent ? <CardBottom>{props.footerContent}</CardBottom> : null}
        </StyledCard>
    );
}

export const StyledCard = styled(Card)<CardProps>(() => ({
    borderRadius: "15px",
}));

export function ImanCardActions(props: {
    children?: React.ReactNode;
    justify?: "start" | "end";
}): JSX.Element {
    const justify = props.justify ? props.justify : "start";
    return <CardActions sx={{ justifyContent: justify }}>{props.children}</CardActions>;
}

function CardBottom(props: { children: React.ReactNode }): JSX.Element {
    return (
        <ImanCardContent paddingX={5} paddingY={5}>
            {props.children}
        </ImanCardContent>
    );
}

export function ImanCardHeaderWithLogo(): JSX.Element {
    return (
        <ImanCardHeader>
            <img
                style={{ width: "40%" }}
                src="assets/logo-proclane-iman.png"
                alt="Proclane IntegrationMan 4"
            />
        </ImanCardHeader>
    );
}

export function ImanCardHeader(props: { children: React.ReactNode }): JSX.Element {
    return (
        <PrimaryBox
            sx={{
                width: "100%",
                maxWidth: "100%",
                paddingTop: "30px",
                paddingBottom: "20px",
            }}
        >
            {props.children}
        </PrimaryBox>
    );
}

const PrimaryBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

export function ImanCardTitle(props: { children?: React.ReactNode }): JSX.Element {
    return <H2>{props.children}</H2>;
}

export function ImanCardContent(props: {
    children?: React.ReactNode;
    paddingX?: number;
    paddingY?: number;
}): JSX.Element {
    return (
        <Box
            paddingX={props.paddingX ? props.paddingX : 4}
            paddingY={props.paddingY ? props.paddingY : 2}
        >
            {props.children}
        </Box>
    );
}
