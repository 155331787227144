import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, Rectangle, Tooltip, XAxis, YAxis } from "recharts";
import { ImanCard } from "../wrappers/ImanCard";
import { H4 } from "../wrappers/ImanTypography";

export function ProductDataToShopChart(): JSX.Element {
    return (
        <ImanCard>
            <H4>
                <strong>Produktupdates vs eingesparte Requests</strong>
            </H4>
            <BarChart
                title={"Produktanlage und -Updates"}
                width={750}
                height={480}
                data={productDataToShop}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                    dataKey="updatesInShop"
                    fill="#0E32EE"
                    activeBar={<Rectangle fill="#0E32EE" stroke="blue" />}
                />
                <Bar
                    dataKey="cachedProducts"
                    fill="#82ca9d"
                    activeBar={<Rectangle fill="gold" stroke="purple" />}
                />
            </BarChart>
        </ImanCard>
    );
}

const productDataToShop = [
    {
        timestamp: "2024-05-05 09:00:00",
        updatesInShop: 1000,
        cachedProducts: 0,
    },
    {
        timestamp: "2024-05-06 09:00:00",
        updatesInShop: 300,
        cachedProducts: 950,
    },
    {
        timestamp: "2024-05-07 09:00:00",
        updatesInShop: 57,
        cachedProducts: 1193,
    },
    {
        timestamp: "2024-05-08 09:00:00",
        updatesInShop: 12,
        cachedProducts: 1238,
    },
    {
        timestamp: "2024-05-09 09:00:00",
        updatesInShop: 0,
        cachedProducts: 1250,
    },
    {
        timestamp: "2024-05-10 09:00:00",
        updatesInShop: 40,
        cachedProducts: 1210,
    },
    {
        timestamp: "2024-05-11 09:00:00",
        updatesInShop: 10,
        cachedProducts: 1240,
    },
];
