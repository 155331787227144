import React from "react";
import { Alert } from "@mui/material";

export function MessageBoxError(props: { children: React.ReactNode }): JSX.Element {
    return <Alert severity="error">{props.children}</Alert>;
}

export function MessageBoxSuccess(props: { children: React.ReactNode }): JSX.Element {
    return <Alert severity="success">{props.children}</Alert>;
}
