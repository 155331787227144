import { IntlShape } from "react-intl";
import { JobStatistics } from "../client";
import { epochMillisToDate } from "./DateUtil";

export function boolToStringRepresentation(bool: boolean): string {
    return bool ? "✅" : "❌";
}

export function epochMillisToLocaleDateTimeString(
    epochMillis: number | null | undefined,
    intl: IntlShape
): string {
    const date = epochMillisToDate(epochMillis);
    return dateToLocaleDateTimeString(date, intl, false);
}

export function epochMillisToLocaleDateTimeStringWithMillis(
    epochMillis: number | null | undefined,
    intl: IntlShape
): string {
    const date = epochMillisToDate(epochMillis);
    return dateToLocaleDateTimeString(date, intl, true);
}

export function dateToLocaleDateTimeString(
    date: Date | null,
    intl: IntlShape,
    withMillis = false
): string {
    if (date === null) {
        return intl.formatMessage({ id: "general.empty", defaultMessage: "no value" });
    }

    let formatted = intl.formatDate(date, {
        dateStyle: "medium",
        timeStyle: "medium",
        fractionalSecondDigits: withMillis ? 3 : 0,
    });
    if (withMillis) {
        formatted += `.${date.getUTCMilliseconds()}`;
    }

    return formatted;
}

export function dateToLocaleDateString(date: Date | null, intl: IntlShape): string {
    if (date === null) return "??"; // TODO: use fallback or don't show at all?
    return intl.formatDate(date, { dateStyle: "medium" });
}

export function formatElapsedMillisNumber(
    value: number | undefined | null,
    intl: IntlShape
): string {
    if (value === undefined || value === null) {
        return "-";
    }
    if (value > 60000) {
        const minutes = Math.floor(value / 60000);
        const remainder = formatElapsedMillisNumber(value - minutes * 60000, intl);
        return `${minutes}m ${remainder}`;
    } else if (value > 1000) {
        const seconds = Math.floor(value / 1000);
        const remainder = formatElapsedMillisNumber(value - seconds * 1000, intl);
        return `${seconds}s ${remainder}`;
    } else {
        return intl.formatNumber(value, { maximumFractionDigits: 0 }) + "ms";
    }
}

export function formatJobStatistics(jobStatistics: JobStatistics, intl: IntlShape): string {
    const minAsString = formatElapsedMillisNumber(jobStatistics.minElapsedMillis, intl);
    const maxAsString = formatElapsedMillisNumber(jobStatistics.maxElapsedMillis, intl);
    const averageAsString = formatElapsedMillisNumber(jobStatistics.averageElapsedMillis, intl);
    return `${minAsString} / ${averageAsString} / ${maxAsString}`;
}

export function extractIdentifierFromToken(token: string): string | undefined {
    const split = token.split(".");
    if (split.length !== 3) return undefined;

    const base64 = split[1];
    try {
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        const json = JSON.parse(jsonPayload);
        return json["sub"];
    } catch (ex) {
        return undefined;
    }
}

interface MagnitudeAndUnit {
    magnitude: number;
    unit: string;
}

const magnitudesWithUnits: MagnitudeAndUnit[] = [
    { magnitude: 4, unit: "TiB" },
    { magnitude: 3, unit: "GiB" },
    { magnitude: 2, unit: "MiB" },
    { magnitude: 1, unit: "KiB" },
];

export function bytesToHumanReadable(value: number | null | undefined, locale: string): string {
    if (value === null || value === undefined) {
        return "?";
    }

    for (let i = 0; i < magnitudesWithUnits.length; i++) {
        const magnitudeAndUnit = magnitudesWithUnits[i];
        if (exceedsMagnitude(value, magnitudeAndUnit.magnitude)) {
            return (
                divideByMagnitude(value, magnitudeAndUnit.magnitude, locale) + magnitudeAndUnit.unit
            );
        }
    }

    return value + " Bytes";
}

function exceedsMagnitude(value: number, magnitude: number): boolean {
    return value / Math.pow(1024, magnitude) >= 1;
}

function divideByMagnitude(value: number, magnitude: number, locale: string): string {
    return (value / Math.pow(1024, magnitude)).toLocaleString(locale, {
        maximumFractionDigits: 2,
    });
}

export function fromRatioToPercent(
    value: number | null | undefined,
    locales?: string | string[]
): string {
    if (value === null || value == undefined) {
        return "?";
    }
    return (
        (value * 100).toLocaleString(locales, {
            maximumFractionDigits: 2,
        }) + "%"
    );
}
