import { PermissionForApiPermissionTypeEnum } from "../client";

export enum FrontendAccessRightsDepr {
    READ_ONLY = "READ_ONLY",
    ADMIN = "ADMIN",
}

export interface UserPermission {
    type: PermissionForApiPermissionTypeEnum;
    subgroup: string | null;
}

export const FrontendAccessRightsAll = [
    FrontendAccessRightsDepr.READ_ONLY,
    FrontendAccessRightsDepr.ADMIN,
];
