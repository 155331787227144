import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import {
    getTokenFromSearchParameters,
    isRepeatedPasswordValid,
} from "../../functionality/passwordFunctionality";
import { ImanForm } from "../wrappers/form/Form";
import { ImanSubmitButton } from "../wrappers/Buttons";
import { useImanApi } from "../../util/ApiUtil";
import { SetPasswordResponseResultEnum } from "../../client";
import { MessageBoxError } from "../wrappers/MessageBoxes";
import { InputNewPassword, InputNewPasswordRepeat } from "./ChangePasswordForm";
import { ImanContainer, ImanLogoImage, ImanStack } from "../wrappers/ImanLayout";
import { CenteredCard, ImanCardTitle } from "../wrappers/ImanCard";
import { Body1 } from "../wrappers/ImanTypography";
import { Routes } from "../../functionality/Routes";

// eslint-disable-next-line max-lines-per-function
function SetPasswordComponent(props: {
    token: string | undefined;
    password1: string;
    password2: string;
    postPassword: () => void;
    setPassword1: (value: string) => void;
    setPassword2: (value: string) => void;
    wasError: boolean;
}) {
    return (
        <React.Fragment>
            <ImanContainer maxWidth="sm">
                <CenteredCard headerContent={<ImanLogoImage />} footerContent={<React.Fragment />}>
                    <ImanCardTitle>
                        <FormattedMessage id="setPassword.title" defaultMessage="Set password" />
                    </ImanCardTitle>
                    <Body1>
                        <FormattedMessage
                            id="setPassword.infoText"
                            defaultMessage="Please set a password"
                        />
                    </Body1>
                    {props.token === undefined ? (
                        <div>
                            <FormattedMessage
                                id="setPassword.invalidLink"
                                defaultMessage="Invalid link"
                            />
                        </div>
                    ) : (
                        <React.Fragment>
                            <ImanForm
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    if (isRepeatedPasswordValid(props.password1, props.password2)) {
                                        props.postPassword();
                                    }
                                }}
                            >
                                <ImanStack>
                                    <InputNewPassword
                                        newPassword1={props.password1}
                                        setNewPassword1={props.setPassword1}
                                    />
                                    <InputNewPasswordRepeat
                                        newPassword1={props.password1}
                                        newPassword2={props.password2}
                                        setNewPassword2={props.setPassword2}
                                    />
                                    {props.wasError ? (
                                        <MessageBoxError>
                                            <FormattedMessage
                                                id="setPassword.invalidRequest"
                                                defaultMessage="Could not set password"
                                            />
                                        </MessageBoxError>
                                    ) : null}
                                    <ImanSubmitButton>
                                        <FormattedMessage
                                            id="setPassword.submit"
                                            defaultMessage={"Set password"}
                                        />
                                    </ImanSubmitButton>
                                </ImanStack>
                            </ImanForm>
                        </React.Fragment>
                    )}
                </CenteredCard>
            </ImanContainer>
        </React.Fragment>
    );
}

export function SetPasswordComponentContainer(): JSX.Element {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = getTokenFromSearchParameters(searchParams);
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [wasError, setWasError] = useState(false);
    const history = useHistory();
    const imanApi = useImanApi();

    function resetPasswords() {
        setPassword1("");
        setPassword2("");
    }

    function postPassword() {
        if (token !== undefined) {
            imanApi.postSetPassword(
                password1,
                token,
                (result) => {
                    resetPasswords();
                    if (result !== SetPasswordResponseResultEnum.Success) {
                        setWasError(true);
                    } else {
                        setWasError(false);
                        history.push(Routes.LOGIN);
                    }
                },
                () => {
                    setWasError(true);
                }
            );
        }
    }

    return (
        <SetPasswordComponent
            token={token}
            password1={password1}
            password2={password2}
            postPassword={postPassword}
            setPassword1={setPassword1}
            setPassword2={setPassword2}
            wasError={wasError}
        />
    );
}
