import { FormattedMessage, IntlShape } from "react-intl";
import DatePicker from "react-datepicker";
import { dateToLocaleDateTimeString } from "../util/StringUtil";
import { BsArrowCounterclockwise } from "react-icons/bs";
import React from "react";
import { ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { ImanButton } from "./wrappers/Buttons";
import { InputLabel } from "@mui/material";

export type DateRangePickersProps = {
    currentValue: Date | null;
    placeholder: string;
    intl: IntlShape;
    setNewValue: (data: Date | null) => void;
    labelMessageId: string;
};

export function DateRangePicker(props: DateRangePickersProps): JSX.Element {
    return (
        <ImanRow>
            <InputLabel htmlFor="datePicker">
                <FormattedMessage id={props.labelMessageId} defaultMessage="Date" />
            </InputLabel>
            <ImanRow>
                <ImanGridCell>
                    <DatePicker
                        id="datePicker"
                        selected={props.currentValue}
                        placeholderText={props.placeholder}
                        dateFormat={dateToLocaleDateTimeString(props.currentValue, props.intl)}
                        showTimeInput={true}
                        onChange={(date: Date) => {
                            date.setMilliseconds(0);
                            props.setNewValue(date);
                        }}
                    />
                </ImanGridCell>
                <ImanGridCell>
                    <ImanButton
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            props.setNewValue(null);
                        }}
                    >
                        <BsArrowCounterclockwise />
                    </ImanButton>
                </ImanGridCell>
            </ImanRow>
        </ImanRow>
    );
}
