import React from "react";
import { FormattedMessage } from "react-intl";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export type LabeledFilteringDropdownAsFormGroupProps<T> = {
    id: string;
    currentValue: T | undefined;
    setValue: (newValue: T | undefined) => void;
    allValues: T[];
    withEmpty?: boolean;
    labelText?: string;
    displayValues?: React.ReactNode[];
    fullWidth?: boolean;
};

// eslint-disable-next-line max-lines-per-function
export function FilteringDropdown<T>(
    props: LabeledFilteringDropdownAsFormGroupProps<T>
): JSX.Element {
    const labelId = props.id + "-label";
    return (
        <FormControl fullWidth={props.fullWidth ? props.fullWidth : false}>
            <InputLabel id={labelId}>{props.labelText}</InputLabel>
            <Select
                className={"iman-super-select"}
                value={
                    props.currentValue === undefined
                        ? -1
                        : props.allValues.indexOf(props.currentValue)
                }
                labelId={labelId}
                onChange={(event) => {
                    const index = event.target.value as number;
                    const value = props.allValues[index];
                    props.setValue(value);
                }}
            >
                {props.withEmpty ? (
                    <MenuItem value={-1}>
                        <i>
                            <FormattedMessage
                                id="jobs.filtering.emptyValue"
                                defaultMessage="Empty"
                            />
                        </i>
                    </MenuItem>
                ) : null}

                {props.allValues.map((value, index) => {
                    return (
                        <MenuItem key={index} value={index}>
                            {props.displayValues !== undefined ? props.displayValues[index] : value}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
