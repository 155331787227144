import React, { useEffect, useState } from "react";
import { ImanCard } from "./wrappers/ImanCard";
import { H2, H5 } from "./wrappers/ImanTypography";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { ImanBox, ImanStack, ProtectedComponent } from "./wrappers/ImanLayout";
import { ButtonWithConfirmationDialog, IconButtonMore, ImanButton } from "./wrappers/Buttons";
import {
    ImanTable,
    ImanTableBody,
    ImanTableCell,
    ImanTableHead,
    ImanTableRow,
} from "./wrappers/ImanTable";
import { useImanApi } from "../util/ApiUtil";
import {
    ActivateScheduleResponse,
    BlueprintMetadataForApi,
    DeactivateScheduleResponse,
} from "../client";
import { epochMillisToLocaleDateTimeString } from "../util/StringUtil";
import { useHistory } from "react-router-dom";
import { allSchedulesPaused, extractAllSchedules, noSchedulePaused } from "../util/ScheduleUtil";
import { Chip } from "@mui/material";
import { Routes } from "../functionality/Routes";
import { Permissions } from "../functionality/PermissionsUtil";

export function SchedulesPage(): JSX.Element {
    const api = useImanApi();
    const [blueprints, setBlueprints] = useState<Array<BlueprintMetadataForApi>>([]);

    function fetchBlueprints() {
        api.fetchBlueprints((response) => {
            setBlueprints(response.blueprints);
        });
    }

    useEffect(() => {
        fetchBlueprints();
    }, []);

    return (
        <ImanBox className="iman-schedules-page">
            <ImanStack align="start" spacing={6}>
                <SchedulesHeader />
                <SchedulesPageTitleRow refresh={fetchBlueprints} allBlueprints={blueprints} />
                <SchedulesTable blueprints={blueprints} />
            </ImanStack>
        </ImanBox>
    );
}

function TableHead(): JSX.Element {
    return (
        <ImanTableHead>
            <ImanTableRow>
                <ImanTableCell>
                    <FormattedMessage
                        id="scheduler.table.blueprintName"
                        defaultMessage="Blueprint"
                    />
                </ImanTableCell>
                <ImanTableCell>
                    <FormattedMessage
                        id="scheduler.table.configuration"
                        defaultMessage="Configuration"
                    />
                </ImanTableCell>
                <ImanTableCell>
                    <FormattedMessage id="scheduler.table.lastRun" defaultMessage="lastest run" />
                </ImanTableCell>
                <ImanTableCell>
                    <FormattedMessage id="scheduler.table.nextRun" defaultMessage="next run" />
                </ImanTableCell>
                <ImanTableCell />
            </ImanTableRow>
        </ImanTableHead>
    );
}

// eslint-disable-next-line max-lines-per-function
function SchedulesTable(props: { blueprints: Array<BlueprintMetadataForApi> }): JSX.Element {
    const intl = useIntl();
    const history = useHistory();

    return (
        <ImanTable>
            <TableHead />
            <ImanTableBody>
                {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
                {props.blueprints.map((blueprint, index) => {
                    const allActiveSchedules = blueprint.schedules.filter(
                        (schedule) => !schedule.isCurrentlyDeactivated
                    );
                    const firstActiveSchedule = allActiveSchedules[0];
                    return (
                        <ImanTableRow
                            key={index}
                            onClick={() =>
                                history.push(Routes.getSchedulerEditRoute(blueprint.identifier))
                            }
                        >
                            <ImanTableCell>{blueprint.name}</ImanTableCell>
                            <ImanTableCell>
                                {firstActiveSchedule ? (
                                    <ImanStack direction="row" justifyContent="space-between">
                                        {firstActiveSchedule.scheduleAsCronExpression}
                                        {allActiveSchedules.length > 1 && (
                                            <Chip
                                                label={"+" + (allActiveSchedules.length - 1)}
                                                color="primary"
                                            />
                                        )}
                                    </ImanStack>
                                ) : blueprint.schedules.length > 0 ? (
                                    <i>
                                        <FormattedMessage
                                            id="scheduler.edit.noActiveSchedule"
                                            defaultMessage="no active schedule"
                                        />
                                    </i>
                                ) : (
                                    "-"
                                )}
                            </ImanTableCell>
                            <ImanTableCell>
                                {epochMillisToLocaleDateTimeString(
                                    blueprint.lastRun?.startedAtInEpochMillis,
                                    intl
                                )}
                            </ImanTableCell>
                            <ImanTableCell>
                                {epochMillisToLocaleDateTimeString(
                                    blueprint.nextRun?.nextExecutionInEpochMillis,
                                    intl
                                )}
                            </ImanTableCell>
                            <ImanTableCell align="right">
                                <IconButtonMore />
                            </ImanTableCell>
                        </ImanTableRow>
                    );
                })}
            </ImanTableBody>
        </ImanTable>
    );
}

function SchedulesPageTitleRow(props: {
    refresh: () => void;
    allBlueprints: Array<BlueprintMetadataForApi>;
}): JSX.Element {
    return (
        <ImanStack direction="row" fullWidth justifyContent="space-between">
            <H2 textAlign="left">
                <FormattedMessage id="scheduler.title" defaultMessage="Scheduled" />
            </H2>
            <ProtectedComponent
                restrictedToAnyOf={[Permissions.ActivateAndDeactivateSchedulesOfAnyBlueprint]}
            >
                <EditButtons refresh={props.refresh} allBlueprints={props.allBlueprints} />
            </ProtectedComponent>
        </ImanStack>
    );
}

// eslint-disable-next-line max-lines-per-function
function EditButtons(props: {
    refresh: () => void;
    allBlueprints: Array<BlueprintMetadataForApi>;
}): JSX.Element {
    const api = useImanApi();
    const intl = useIntl();
    const allSchedules = extractAllSchedules(props.allBlueprints);
    const allScheduleIdentifiers = allSchedules.map((schedule) => schedule.identifier);
    const allPaused = allSchedulesPaused(allSchedules);
    const nonePaused = noSchedulePaused(allSchedules);

    return (
        <ImanStack direction="row">
            <PauseAllButton
                intl={intl}
                disabled={allPaused}
                allBlueprints={props.allBlueprints}
                onConfirm={() => api.pauseSchedules(allScheduleIdentifiers)}
                onResponse={() => props.refresh()}
            />
            <UnpauseAllButton
                intl={intl}
                disabled={nonePaused}
                allBlueprints={props.allBlueprints}
                onConfirm={() => api.unpauseSchedules(allScheduleIdentifiers)}
                onResponse={() => props.refresh()}
            />
        </ImanStack>
    );
}

function PauseAllButton(props: {
    intl: IntlShape;
    disabled: boolean;
    allBlueprints: Array<BlueprintMetadataForApi>;
    onConfirm: () => Promise<DeactivateScheduleResponse>;
    onResponse: () => void;
}): JSX.Element {
    return (
        <ButtonWithConfirmationDialog
            buttonChildren={
                <FormattedMessage id="scheduler.edit.pauseAll" defaultMessage="Pause all" />
            }
            dialogTitle={props.intl.formatMessage({
                id: "scheduler.edit.pauseAll.dialogTitle",
                defaultMessage: "Deactivate all schedules?",
            })}
            dialogMessage={
                <React.Fragment>
                    {props.intl.formatMessage({
                        id: "scheduler.edit.pauseAll.dialogMessage",
                        defaultMessage: "Deactivates all schedules of all blueprints.",
                    })}
                    <ListOfAllSchedulesPerBlueprint allBlueprints={props.allBlueprints} />
                </React.Fragment>
            }
            onConfirm={() => {
                return props.onConfirm();
            }}
            onResponse={() => {
                props.onResponse();
            }}
            buttonDisabled={props.disabled}
        />
    );
}

function UnpauseAllButton(props: {
    intl: IntlShape;
    disabled: boolean;
    allBlueprints: Array<BlueprintMetadataForApi>;
    onConfirm: () => Promise<ActivateScheduleResponse>;
    onResponse: () => void;
}): JSX.Element {
    return (
        <ButtonWithConfirmationDialog
            buttonChildren={
                <FormattedMessage id="scheduler.edit.unpauseAll" defaultMessage="Unpause all" />
            }
            dialogTitle={props.intl.formatMessage({
                id: "scheduler.edit.unpauseAll.dialogTitle",
                defaultMessage: "Activate all schedules?",
            })}
            dialogMessage={
                <React.Fragment>
                    {props.intl.formatMessage({
                        id: "scheduler.edit.unpauseAll.dialogMessage",
                        defaultMessage: "Activates all schedules of all blueprints.",
                    })}
                    <ListOfAllSchedulesPerBlueprint allBlueprints={props.allBlueprints} />
                </React.Fragment>
            }
            onConfirm={() => {
                return props.onConfirm();
            }}
            onResponse={() => {
                props.onResponse();
            }}
            buttonDisabled={props.disabled}
        />
    );
}

function ListOfAllSchedulesPerBlueprint(props: {
    allBlueprints: Array<BlueprintMetadataForApi>;
}): JSX.Element {
    return (
        <ul>
            {props.allBlueprints.map((bp) => {
                return (
                    <li key={bp.identifier}>
                        <strong>{bp.name}</strong>
                        {bp.schedules.length > 0 ? (
                            <ul>
                                {bp.schedules.map((schedule) => (
                                    <li key={schedule.identifier}>
                                        {schedule.scheduleAsCronExpression}
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <i>
                                &nbsp;(
                                {
                                    <FormattedMessage
                                        id="scheduler.edit.noSchedule"
                                        defaultMessage="no schedule"
                                    />
                                }
                                )
                            </i>
                        )}
                    </li>
                );
            })}
        </ul>
    );
}

function SchedulesHeader(): JSX.Element {
    return (
        <ImanCard width="100%">
            <ImanStack direction="row">
                <ImanBox textAlign="left" fillSpace>
                    <H5>
                        <FormattedMessage
                            id="scheduler.header"
                            defaultMessage="Schedule or start blueprints"
                        />
                    </H5>
                </ImanBox>
                <ImanButton disabled>
                    <FormattedMessage
                        id="scheduler.scheduleBlueprint"
                        defaultMessage="Schedule blueprint"
                    />
                </ImanButton>
                <ImanButton variant="outlined" color="inherit" disabled>
                    <FormattedMessage
                        id="scheduler.startBlueprint"
                        defaultMessage="Start blueprint now"
                    />
                </ImanButton>
            </ImanStack>
        </ImanCard>
    );
}
