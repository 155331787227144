export function epochMillisToDate(millis: number | null | undefined): Date | null {
    if (millis === undefined) return null;
    if (null === millis) return null;
    return new Date(millis);
}

export function dateToEpochMillis(date: Date | null): number | null {
    if (date === null) return null;
    return dateToEpochMillisNotNull(date);
}

export function dateToEpochMillisNotNull(date: Date): number {
    return date.getTime();
}
