import React, { useState } from "react";
import { useImanApi } from "../../util/ApiUtil";
import { ChangePasswordResponseResultEnum } from "../../client";
import { ImanForm } from "../wrappers/form/Form";
import { isRepeatedPasswordValid } from "../../functionality/passwordFunctionality";
import { ImanSubmitButton } from "../wrappers/Buttons";
import { FormattedMessage } from "react-intl";
import { MessageBoxError, MessageBoxSuccess } from "../wrappers/MessageBoxes";
import { SimpleInput } from "../wrappers/form/TextInputs";
import { ImanRow } from "../wrappers/ImanGrid";

export function ChangePasswordForm(props: { emailAddress: string }): JSX.Element {
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword1, setNewPassword1] = useState("");
    const [newPassword2, setNewPassword2] = useState("");
    const [wasChangeSuccessful, setChangeSuccessful] = useState<boolean | undefined>(undefined);
    const imanApi = useImanApi();

    const resetInputs = () => {
        setOldPassword("");
        setNewPassword1("");
        setNewPassword2("");
    };

    const onFailure = () => {
        resetInputs();
        setChangeSuccessful(false);
    };

    const sendChangePassword = () => {
        imanApi.postChangePassword(
            props.emailAddress,
            oldPassword,
            newPassword1,
            (response) => {
                if (response.result === ChangePasswordResponseResultEnum.Error) {
                    onFailure();
                } else {
                    setChangeSuccessful(true);
                }
                resetInputs();
            },
            () => {
                onFailure();
            }
        );
    };

    return (
        <ChangePasswordFormComponent
            newPassword1={newPassword1}
            newPassword2={newPassword2}
            sendChangePassword={sendChangePassword}
            oldPassword={oldPassword}
            setOldPassword={setOldPassword}
            setNewPassword1={setNewPassword1}
            setNewPassword2={setNewPassword2}
            wasChangeSuccessful={wasChangeSuccessful}
        />
    );
}

function ChangePasswordFormComponent(props: ChangePasswordProps): JSX.Element {
    return (
        <div>
            <H5ChangePasswordTitle />
            <ImanForm
                onSubmit={(event) => {
                    event.preventDefault();
                    if (isRepeatedPasswordValid(props.newPassword1, props.newPassword2)) {
                        props.sendChangePassword();
                    }
                }}
            >
                <InputOldPassword
                    oldPassword={props.oldPassword}
                    setOldPassword={props.setOldPassword}
                />
                <InputNewPassword
                    newPassword1={props.newPassword1}
                    setNewPassword1={props.setNewPassword1}
                />
                <InputNewPasswordRepeat
                    newPassword1={props.newPassword1}
                    newPassword2={props.newPassword2}
                    setNewPassword2={props.setNewPassword2}
                />
                <ImanSubmitButton>
                    <FormattedMessage
                        id="profile.changePassword.submit"
                        defaultMessage={"Set new password"}
                    />
                </ImanSubmitButton>
            </ImanForm>
            <ChangePasswordMessageRow wasChangeSuccessful={props.wasChangeSuccessful} />
        </div>
    );
}

function ChangePasswordMessageRow(props: {
    wasChangeSuccessful: boolean | undefined;
}): JSX.Element {
    return (
        <ImanRow>
            {props.wasChangeSuccessful === true ? (
                <MessageBoxSuccess>
                    <FormattedMessage
                        id="profile.changePassword.successMessage"
                        defaultMessage={"Password was changed"}
                    />
                </MessageBoxSuccess>
            ) : null}
            {props.wasChangeSuccessful === false ? (
                <MessageBoxError>
                    <FormattedMessage
                        id="profile.changePassword.failureMessage"
                        defaultMessage={"Password was changed"}
                    />
                </MessageBoxError>
            ) : null}
        </ImanRow>
    );
}

function H5ChangePasswordTitle(): JSX.Element {
    return (
        <h5>
            <FormattedMessage
                id="profile.changePassword.title"
                defaultMessage={"Change Password"}
            />
        </h5>
    );
}

type ChangePasswordProps = {
    newPassword1: string;
    newPassword2: string;
    sendChangePassword: () => void;
    oldPassword: string;
    setOldPassword: (value: string) => void;
    setNewPassword1: (value: string) => void;
    setNewPassword2: (value: string) => void;
    wasChangeSuccessful: boolean | undefined;
};

function InputOldPassword(props: {
    oldPassword: string;
    setOldPassword: (value: string) => void;
}): JSX.Element {
    return (
        <SimpleInput
            password
            labelI18nIdentifier="profile.changePassword.oldPassword"
            defaultLabel="Old password"
            value={props.oldPassword}
            onChange={(event) => props.setOldPassword(event.target.value)}
        />
    );
}

export function InputNewPassword(props: {
    newPassword1: string;
    setNewPassword1: (value: string) => void;
}): JSX.Element {
    return (
        <SimpleInput
            password
            labelI18nIdentifier="profile.changePassword.newPassword"
            defaultLabel="New password"
            value={props.newPassword1}
            onChange={(event) => props.setNewPassword1(event.target.value)}
        />
    );
}

export function InputNewPasswordRepeat(props: {
    newPassword1: string;
    newPassword2: string;
    setNewPassword2: (value: string) => void;
}): JSX.Element {
    return (
        <SimpleInput
            password
            labelI18nIdentifier="profile.changePassword.newPasswordRepeated"
            defaultLabel="New password (repeated)"
            value={props.newPassword2}
            onChange={(event) => props.setNewPassword2(event.target.value)}
            isValueValid={
                isRepeatedPasswordValid(props.newPassword1, props.newPassword2) ||
                props.newPassword2.length === 0
            }
        />
    );
}
