import React from "react";
import { H2 } from "./ImanTypography";
import { FormattedMessage } from "react-intl";
import { ImanStack } from "./ImanLayout";

export function ImanPageHeader(props: {
    titleId: string;
    titleDefaultMessage: string;
    children?: React.ReactNode;
}): JSX.Element {
    return (
        <ImanStack direction="row" spacing={6}>
            <H2>
                <FormattedMessage id={props.titleId} defaultMessage={props.titleDefaultMessage} />
            </H2>
            {props.children}
        </ImanStack>
    );
}
