import {
    PermissionForApi,
    PermissionForApiPermissionTypeEnum,
    PermissionForApiGroupEnum,
    CreateRoleResponseResultEnum,
    RoleForApi,
    UpdateRoleResponseResultEnum,
    UserForApi,
} from "../client";
import {
    HasAnyPermissionOfTypes,
    ImanConfirmationDialog,
    ImanExpandable2,
    ImanModal,
    ImanStack,
} from "./wrappers/ImanLayout";
import { Body1, Body2, H4, H6 } from "./wrappers/ImanTypography";
import { FormattedMessage, useIntl } from "react-intl";
import { IconButtonDelete, IconButtonEdit, ImanButton, ImanSubmitButton } from "./wrappers/Buttons";
import { ImanList, ImanListItem } from "./wrappers/ImanList";
import React, { useEffect, useState } from "react";
import { ImanCheckBox, ImanForm } from "./wrappers/form/Form";
import { useImanApi } from "../util/ApiUtil";
import { groupByMainGroup, groupBySubgroup } from "../functionality/PermissionsUtil";
import { ImanGrid, ImanGridCell } from "./wrappers/ImanGrid";
import { SimpleInput } from "./wrappers/form/TextInputs";
import { MessageBoxError } from "./wrappers/MessageBoxes";

// eslint-disable-next-line max-lines-per-function
export function RolesList(props: {
    allRoles: RoleForApi[];
    getAllRoles: () => void;
    updateUsersData: () => void;
}): JSX.Element {
    const imanApi = useImanApi();
    const [allAccessiblePermissions, setAllAccessiblePermissions] = useState<PermissionForApi[]>(
        []
    );
    const [isModalOpen, setIsModalOpen] = useState(false);

    const fetchAllPermissions = () => {
        imanApi
            .getAllPermissions()
            .then((response) => setAllAccessiblePermissions(response.permissions));
    };
    useEffect(fetchAllPermissions, [props.allRoles]);

    return (
        <React.Fragment>
            <CreateRoleModal
                allAccessRights={allAccessiblePermissions}
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                onSuccess={() => {
                    props.getAllRoles();
                }}
            />
            <ImanStack align="start" fullWidth>
                <ImanStack direction="row" justifyContent="space-between">
                    <H6>
                        <FormattedMessage id="admin.roles.title" defaultMessage="Roles" />
                    </H6>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.CreateRoles]}
                    >
                        <ImanButton
                            variant="outlined"
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            <FormattedMessage id="admin.roles.add" defaultMessage="Add role" />
                        </ImanButton>
                    </HasAnyPermissionOfTypes>
                </ImanStack>
                <ImanList>
                    {props.allRoles.map((role) => {
                        return (
                            <ImanListItem key={role.identifier}>
                                <RoleRow
                                    role={role}
                                    allAccessRights={allAccessiblePermissions}
                                    getAllRoles={props.getAllRoles}
                                    updateUsersData={props.updateUsersData}
                                />
                            </ImanListItem>
                        );
                    })}
                </ImanList>
            </ImanStack>
        </React.Fragment>
    );
}

function RoleRow(props: {
    role: RoleForApi;
    allAccessRights: PermissionForApi[];
    getAllRoles: () => void;
    updateUsersData: () => void;
}): JSX.Element {
    const imanApi = useImanApi();
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteRoleDialogOpen, setIsDeleteRoleDialogOpen] = useState(false);
    return (
        <React.Fragment>
            <EditRoleModal
                role={props.role}
                allAccessRights={props.allAccessRights}
                isOpen={isEditModalOpen}
                close={() => setIsEditModalOpen(false)}
                onSuccess={() => {
                    props.getAllRoles();
                    props.updateUsersData();
                }}
            />
            <DeleteRoleDialog
                role={props.role}
                isOpen={isDeleteRoleDialogOpen}
                onConfirm={() =>
                    imanApi.deleteRole(props.role.identifier).then(() => {
                        props.getAllRoles();
                        props.updateUsersData();
                    })
                }
                onCancel={() => setIsDeleteRoleDialogOpen(false)}
            />
            <ImanStack spacing={0} align="start" fullWidth>
                <Body1>{props.role.name}</Body1>
                <Body2>
                    {props.role.permissions
                        .map((permission) => {
                            return permission.serialized;
                        })
                        .join(", ")}
                </Body2>
            </ImanStack>
            <HasAnyPermissionOfTypes
                restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.EditRoles]}
            >
                <IconButtonEdit onClick={() => setIsEditModalOpen(true)} />
            </HasAnyPermissionOfTypes>
            <HasAnyPermissionOfTypes
                restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.DeleteRoles]}
            >
                <IconButtonDelete
                    onClick={() => {
                        setIsDeleteRoleDialogOpen(true);
                    }}
                />
            </HasAnyPermissionOfTypes>
        </React.Fragment>
    );
}

// eslint-disable-next-line max-lines-per-function
function DeleteRoleDialog(props: {
    role: RoleForApi;
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}): JSX.Element {
    const intl = useIntl();
    const imanApi = useImanApi();
    const [usersWithRole, setUsersWithRole] = useState<UserForApi[]>([]);

    useEffect(() => {
        if (props.isOpen) {
            imanApi
                .getAllUsersWithRole(props.role.identifier)
                .then((response) => setUsersWithRole(response.users));
        }
    }, [props.isOpen]);

    return (
        <ImanConfirmationDialog
            isOpen={props.isOpen}
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            title={intl.formatMessage({ id: "admin.roles.delete", defaultMessage: "Delete role?" })}
            message={
                <React.Fragment>
                    <Body1>
                        <FormattedMessage
                            id="admin.roles.delete.areYouSure"
                            defaultMessage="Delete role {name}?"
                            values={{
                                name: props.role.name,
                            }}
                        />
                    </Body1>
                    {usersWithRole.length === 0 ? (
                        <Body1>
                            <FormattedMessage
                                id="admin.roles.delete.noUserHasRole"
                                defaultMessage="No user has this role"
                            />
                        </Body1>
                    ) : (
                        <React.Fragment>
                            <FormattedMessage
                                id="admin.roles.delete.usersWithRole"
                                defaultMessage="The following users have this role"
                            />
                            <ImanList>
                                {usersWithRole.map((user) => (
                                    <ImanListItem key={user.id}>
                                        <Body1>{user.name}</Body1>
                                    </ImanListItem>
                                ))}
                            </ImanList>
                        </React.Fragment>
                    )}
                </React.Fragment>
            }
        />
    );
}

interface RoleData {
    identifier: string | null;
    name: string;
    accessRights: PermissionForApi[];
}

// eslint-disable-next-line max-lines-per-function
function RoleForm(props: {
    roleIdentifier: string | null;
    currentName?: string;
    currentAccessRightsOfRole?: PermissionForApi[];
    allAccessRights: PermissionForApi[];
    onSubmit: (roleData: RoleData, reset: () => void, nameInvalid: () => void) => void;
}): JSX.Element {
    const [name, setName] = useState(props.currentName !== undefined ? props.currentName : "");
    const [activatedAccessRights, setActivatedAccessRights] = useState<PermissionForApi[]>(
        props.currentAccessRightsOfRole !== undefined ? props.currentAccessRightsOfRole : []
    );
    const [isValidName, setIsValidName] = useState(true);

    const accessRightsByMainGroup = groupByMainGroup(props.allAccessRights);

    const activateAccessRight = (accessRight: PermissionForApi) => {
        const newActivatedAccessRights = activatedAccessRights.slice();
        newActivatedAccessRights.push(accessRight);
        setActivatedAccessRights(newActivatedAccessRights);
    };
    const deactivateAccessRight = (accessRight: PermissionForApi) => {
        setActivatedAccessRights(
            activatedAccessRights.filter((el) => {
                return el.serialized !== accessRight.serialized;
            })
        );
    };
    const isAccessRightActive: (accessRight: PermissionForApi) => boolean = (
        accessRight: PermissionForApi
    ) => {
        return (
            activatedAccessRights.findIndex((el) => el.serialized === accessRight.serialized) !== -1
        );
    };
    const toggleAccessRight = (accessRight: PermissionForApi) => {
        if (
            activatedAccessRights.findIndex((el) => el.serialized === accessRight.serialized) !== -1
        ) {
            deactivateAccessRight(accessRight);
        } else {
            activateAccessRight(accessRight);
        }
    };

    return (
        <ImanForm
            onSubmit={(event) => {
                event.preventDefault();
                props.onSubmit(
                    {
                        identifier: props.roleIdentifier,
                        accessRights: activatedAccessRights,
                        name: name,
                    },
                    () => {
                        setName("");
                        setActivatedAccessRights([]);
                    },
                    () => {
                        setIsValidName(false);
                    }
                );
            }}
        >
            <SimpleInput
                labelI18nIdentifier="admin.roles.name"
                defaultLabel="Role name"
                value={name}
                onChange={(newValue) => {
                    setIsValidName(true);
                    setName(newValue.target.value);
                }}
                isValueValid={isValidName}
            />
            {!isValidName && (
                <MessageBoxError>
                    <FormattedMessage id="admin.roles.nameInvalid" defaultMessage="Invalid name" />
                </MessageBoxError>
            )}
            <AccessRightsGroup
                title={<FormattedMessage id="admin.roles.group.general" defaultMessage="General" />}
                group={accessRightsByMainGroup.get(PermissionForApiGroupEnum.General)}
                toggleAccessRight={toggleAccessRight}
                isAccessRightActive={isAccessRightActive}
            />
            <AccessRightsGroup
                title={
                    <FormattedMessage id="admin.roles.group.userdata" defaultMessage="User data" />
                }
                group={accessRightsByMainGroup.get(PermissionForApiGroupEnum.UserData)}
                toggleAccessRight={toggleAccessRight}
                isAccessRightActive={isAccessRightActive}
            />
            <AccessRightsGroup
                title={
                    <FormattedMessage
                        id="admin.roles.group.blueprintsGeneral"
                        defaultMessage="Blueprints general"
                    />
                }
                group={accessRightsByMainGroup.get(PermissionForApiGroupEnum.BlueprintsGeneral)}
                toggleAccessRight={toggleAccessRight}
                isAccessRightActive={isAccessRightActive}
            />
            <BlueprintSpecificGroups
                blueprintSpecificAccessRights={accessRightsByMainGroup.get(
                    PermissionForApiGroupEnum.BlueprintSpecific
                )}
                toggleAccessRight={toggleAccessRight}
                isAccessRightActive={isAccessRightActive}
            />
            <ImanSubmitButton>
                <FormattedMessage id="admin.roles.save" defaultMessage="Save" />
            </ImanSubmitButton>
        </ImanForm>
    );
}

// eslint-disable-next-line max-lines-per-function
function CreateRoleModal(props: {
    allAccessRights: PermissionForApi[];
    isOpen: boolean;
    close: () => void;
    onSuccess: () => void;
}): JSX.Element {
    const imanApi = useImanApi();

    return (
        <ImanModal isOpen={props.isOpen} onClose={props.close}>
            <H4>
                <FormattedMessage id="admin.roles.add" defaultMessage="Create role" />
            </H4>
            <RoleForm
                roleIdentifier={null}
                allAccessRights={props.allAccessRights}
                onSubmit={(roleData, reset, nameIsInvalid) => {
                    imanApi
                        .createRole(
                            roleData.name,
                            roleData.accessRights.map((right) => right.serialized)
                        )
                        .then((response) => {
                            switch (response.result) {
                                case CreateRoleResponseResultEnum.Success:
                                    reset();
                                    props.onSuccess();
                                    props.close();
                                    break;
                                case CreateRoleResponseResultEnum.InvalidName:
                                    nameIsInvalid();
                                    break;
                            }
                        });
                }}
            />
        </ImanModal>
    );
}

// eslint-disable-next-line max-lines-per-function
function EditRoleModal(props: {
    role: RoleForApi;
    allAccessRights: PermissionForApi[];
    isOpen: boolean;
    close: () => void;
    onSuccess: () => void;
}): JSX.Element {
    const imanApi = useImanApi();

    return (
        <ImanModal isOpen={props.isOpen} onClose={props.close}>
            <H4>
                <FormattedMessage id="admin.roles.edit" defaultMessage="Edit role" />
            </H4>
            <RoleForm
                roleIdentifier={props.role.identifier}
                currentName={props.role.name}
                currentAccessRightsOfRole={props.role.permissions}
                allAccessRights={props.allAccessRights}
                onSubmit={(roleData, reset, nameIsInvalid) => {
                    imanApi
                        .updateRole(
                            props.role.identifier,
                            roleData.name,
                            roleData.accessRights.map((right) => right.serialized)
                        )
                        .then((response) => {
                            switch (response.result) {
                                case UpdateRoleResponseResultEnum.Success:
                                    reset();
                                    props.onSuccess();
                                    props.close();
                                    break;
                                case UpdateRoleResponseResultEnum.InvalidData:
                                    nameIsInvalid();
                                    break;
                            }
                        });
                }}
            />
        </ImanModal>
    );
}

function BlueprintSpecificGroups(props: {
    blueprintSpecificAccessRights: PermissionForApi[] | undefined;
    toggleAccessRight: (accessRight: PermissionForApi) => void;
    isAccessRightActive: (accessRight: PermissionForApi) => boolean;
}): JSX.Element {
    const blueprintSpecificSubGroups: Map<string, PermissionForApi[]> = groupBySubgroup(
        props.blueprintSpecificAccessRights
    );

    const subGroups: string[] = [];
    for (const subGroup of blueprintSpecificSubGroups.keys()) {
        subGroups.push(subGroup);
    }
    return (
        <React.Fragment>
            {subGroups.map((subGroup) => {
                return (
                    <AccessRightsGroup
                        key={subGroup}
                        title={
                            <FormattedMessage
                                id="admin.roles.group.blueprintSpecific"
                                defaultMessage="Access rights blueprint {id}"
                                values={{
                                    id: subGroup,
                                }}
                            />
                        }
                        group={blueprintSpecificSubGroups.get(subGroup)}
                        toggleAccessRight={props.toggleAccessRight}
                        isAccessRightActive={props.isAccessRightActive}
                    />
                );
            })}
        </React.Fragment>
    );
}

function AccessRightsGroup(props: {
    title: React.ReactNode;
    group: PermissionForApi[] | undefined;
    toggleAccessRight: (accessRight: PermissionForApi) => void;
    isAccessRightActive: (accessRight: PermissionForApi) => boolean;
}): JSX.Element {
    return (
        <ImanExpandable2 summary={<Body1>{props.title}</Body1>}>
            <ImanGrid>
                {props.group?.map((right) => {
                    return (
                        <ImanGridCell key={right.serialized} xs={12} sm={6}>
                            <ImanCheckBox
                                label={right.serialized}
                                isChecked={props.isAccessRightActive(right)}
                                onClick={() => props.toggleAccessRight(right)}
                            />
                        </ImanGridCell>
                    );
                })}
            </ImanGrid>
        </ImanExpandable2>
    );
}
