import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { JobRouteParams, Routes } from "../../functionality/Routes";
import { JobForApi, JobForApiStatusEnum } from "../../client";
import { ImanApi, triggerDownload, useImanApi } from "../../util/ApiUtil";
import { ImanCard } from "../wrappers/ImanCard";
import { ImanBox, ImanStack, ProtectedComponent } from "../wrappers/ImanLayout";
import { IconArrowBack } from "../wrappers/IconWrappers";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { H4 } from "../wrappers/ImanTypography";
import { Permissions } from "../../functionality/PermissionsUtil";
import { CancelButtonsRow } from "../RunningJobMetaCard";
import { ImanButtonWithIcon } from "../wrappers/Buttons";
import { Download } from "@mui/icons-material";

// eslint-disable-next-line max-lines-per-function
export function JobsDetailPage(): JSX.Element {
    const { job_identifier } = useParams<JobRouteParams>();
    const [jobData, setJobData] = useState<JobForApi | null>(null);
    const imanApi = useImanApi();
    const fetchJobData = () => {
        imanApi
            .getJobDetails(job_identifier)
            .then((response) =>
                response.jobData ? setJobData(response.jobData) : setJobData(null)
            );
    };
    useEffect(fetchJobData, []);
    return (
        <ImanBox>
            <ImanStack align="start">
                <Link to={Routes.JOBS}>
                    <IconArrowBack />
                    <FormattedMessage id="jobs.details.back" defaultMessage="Back to job list" />
                </Link>
                {jobData !== null && jobData !== undefined ? (
                    <ImanCard width="100%">
                        <H4>{jobData?.blueprintIdentifier}</H4>
                        <DownloadRow jobData={jobData} imanApi={imanApi} />
                        <CancelRow
                            jobData={jobData}
                            triggerRefresh={fetchJobData}
                            imanApi={imanApi}
                        />
                    </ImanCard>
                ) : null}
            </ImanStack>
        </ImanBox>
    );
}

function CancelRow(props: {
    jobData: JobForApi;
    triggerRefresh: () => void;
    imanApi: ImanApi;
}): JSX.Element {
    return (
        <React.Fragment>
            {props.jobData.status === JobForApiStatusEnum.Running ? (
                <ProtectedComponent
                    restrictedToAnyOf={[
                        Permissions.StopAnyBlueprint,
                        Permissions.StopJobsOfBlueprint(props.jobData.blueprintIdentifier),
                    ]}
                >
                    <CancelButtonsRow
                        imanApi={props.imanApi}
                        jobIdentifier={props.jobData.id}
                        triggerRefresh={props.triggerRefresh}
                    />
                </ProtectedComponent>
            ) : null}
        </React.Fragment>
    );
}

function DownloadRow(props: { jobData: JobForApi; imanApi: ImanApi }): JSX.Element {
    const intl = useIntl();
    return (
        <React.Fragment>
            {props.jobData.status !== JobForApiStatusEnum.Running &&
            props.jobData.status !== JobForApiStatusEnum.Cancelled ? (
                <ImanButtonWithIcon
                    color={"primary"}
                    className={"iman-button-download"}
                    icon={<Download />}
                    text={intl.formatMessage({
                        id: "jobs.download",
                        defaultMessage: "Download",
                    })}
                    onClick={() =>
                        props.imanApi.downloadJobData(props.jobData.id).then((blobWithFilename) => {
                            triggerDownload(blobWithFilename);
                        })
                    }
                />
            ) : null}
        </React.Fragment>
    );
}
