import React from "react";
import { Alarm, AlarmOff } from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export function IconAlarm(): JSX.Element {
    return <Alarm />;
}

export function IconAlarmOff(): JSX.Element {
    return <AlarmOff color="warning" />;
}

export function IconArrowBack(): JSX.Element {
    return <ArrowBackIosNewIcon sx={{ fontSize: 12 }} />;
}
