import React from "react";
import { Chip } from "@mui/material";

export function ImanChip(props: {
    text: string;
    color?: "success" | "warning";
    highlight?: boolean;
}): JSX.Element {
    let highlightClass = "";
    if (props.highlight) {
        highlightClass = " highlight";
    }
    return (
        <Chip
            className={"iman4-chip" + highlightClass}
            variant="outlined"
            label={props.text}
            color={props.color}
        />
    );
}
