import React from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { FormattedMessage } from "react-intl";

export function ImanTable(props: { children: React.ReactNode }): JSX.Element {
    return (
        <TableContainer component={Paper} sx={{ borderRadius: "15px" }}>
            <Table>{props.children}</Table>
        </TableContainer>
    );
}

export function ImanTableHead(props: { children: React.ReactNode }): JSX.Element {
    return <TableHead>{props.children}</TableHead>;
}

export function ImanTableBody(props: { children: React.ReactNode }): JSX.Element {
    return <TableBody>{props.children}</TableBody>;
}

export function ImanTableRow(props: {
    children: React.ReactNode;
    onClick?: () => void;
}): JSX.Element {
    return (
        <TableRow
            onClick={(event) => {
                console.log("row trgt", event.currentTarget);
                if (props.onClick) {
                    props.onClick();
                }
            }}
            hover={true}
        >
            {props.children}
        </TableRow>
    );
}

export function ImanTableCell(props: {
    children?: React.ReactNode;
    align?: "right" | "left";
}): JSX.Element {
    return <TableCell align={props.align ? props.align : "left"}>{props.children}</TableCell>;
}

export function FormattedTableCell(props: { id: string; defaultMessage: string }): JSX.Element {
    return (
        <ImanTableCell>
            <FormattedMessage id={props.id} defaultMessage={props.defaultMessage} />
        </ImanTableCell>
    );
}
