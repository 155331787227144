import {
    FormattedTableCell,
    ImanTable,
    ImanTableBody,
    ImanTableCell,
    ImanTableHead,
    ImanTableRow,
} from "../wrappers/ImanTable";
import React, { Fragment, useEffect, useState } from "react";
import { ImanBox, ImanStack } from "../wrappers/ImanLayout";
import { ImanPageHeader } from "../wrappers/ImanPage";
import {
    BlueprintBaseDataForApi,
    JobForApi,
    JobForApiStatusEnum,
    JobsMetadataResponse,
    JobsStatisticsForApi,
} from "../../client";
import { useHistory, useLocation } from "react-router-dom";
import {
    createJobsLink,
    extractPaginationParametersFromUrlSearchParams,
    PaginationParameters,
} from "../../util/LinkUtil";
import { useRecoilValue } from "recoil";
import { recoilHasToken } from "../../recoilStore";
import { ImanApi, triggerDownload, useImanApi } from "../../util/ApiUtil";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { epochMillisToLocaleDateTimeString } from "../../util/StringUtil";
import { FormattedMessage, useIntl } from "react-intl";
import { ImanForm } from "../wrappers/form/Form";
import { FilteringDropdown } from "../wrappers/form/FilteringDropdown";
import {
    extractJobFilterParametersFromUrlSearchParams,
    JobsFilterParameters,
    JobStatus,
} from "../../functionality/jobs/JobsUtil";
import { associateBy } from "../../functionality/CollectionUtil";
import { PagingControl } from "../PagingControl";
import { Routes } from "../../functionality/Routes";
import { ImanCard } from "../wrappers/ImanCard";
import { H4, H6 } from "../wrappers/ImanTypography";
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { Download } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// eslint-disable-next-line max-lines-per-function
export function JobsPage(): JSX.Element {
    const hasToken = useRecoilValue(recoilHasToken);
    const history = useHistory();
    const imanApi = useImanApi();
    const filterParametersFromUrl = useFilterParametersFromLocation();
    const paginationParametersFromUrl = usePaginationParametersFromLocation();
    const [currentPaginationParameters, setCurrentPaginationParameters] =
        useState<PaginationParameters | null>(null);

    const [jobsResponse, setJobsResponse] = useState<JobsMetadataResponse | null>();
    const [allBlueprints, setAllBlueprints] = useState<BlueprintBaseDataForApi[]>([]);
    const blueprintsByIdentifier = associateBy(allBlueprints, (bp) => bp.identifier);

    const [didParametersChange, setParametersChanged] = useState(true);
    const setFilterParameters = (newFilterParameters: JobsFilterParameters) => {
        const jobsLink = createJobsLink(newFilterParameters, paginationParametersFromUrl);
        history.push(jobsLink);
        setParametersChanged(true);
    };

    if (
        paginationParametersFromUrl.page !== currentPaginationParameters?.page ||
        paginationParametersFromUrl.entriesPerPage !== currentPaginationParameters?.entriesPerPage
    ) {
        setCurrentPaginationParameters(paginationParametersFromUrl);
        setParametersChanged(true);
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const fetchJobs = () => {
        if (hasToken && didParametersChange) {
            imanApi
                .getJobs(filterParametersFromUrl, paginationParametersFromUrl)
                .then((response) => {
                    setJobsResponse(response);
                });
            setParametersChanged(false);
        }
    };
    useEffect(() => fetchJobs(), [hasToken, didParametersChange, currentPaginationParameters]);

    useEffect(() => {
        imanApi.fetchBlueprintsBaseData().then((response) => setAllBlueprints(response.blueprints));
    }, []);

    return (
        <ImanBox className="iman-page-jobs">
            <ImanStack align="start" spacing={6}>
                <JobsPageHeader
                    allBlueprints={allBlueprints}
                    filterParameters={filterParametersFromUrl}
                    setFilterParameters={setFilterParameters}
                    jobsResponse={jobsResponse}
                />
                {jobsResponse ? (
                    <React.Fragment>
                        <JobsStatistics statistics={jobsResponse.statistics} />
                        <JobsTable
                            blueprintsByIdentifier={blueprintsByIdentifier}
                            jobsResponse={jobsResponse}
                            filterParameters={filterParametersFromUrl}
                        />
                    </React.Fragment>
                ) : null}
            </ImanStack>
        </ImanBox>
    );
}

function JobsPagingControl(props: {
    jobsResponse: JobsMetadataResponse;
    paginationParameters: PaginationParameters;
    filterParameters: JobsFilterParameters;
}): JSX.Element {
    return (
        <PagingControl
            pagingInfo={
                props.jobsResponse?.pagingMetaData ?? {
                    currentPage: props.paginationParameters.page,
                    entriesPerPage: props.paginationParameters.entriesPerPage,
                    numberOfItems: 0,
                    numberOfPages: 0,
                }
            }
            currentPage={props.paginationParameters.page}
            linkProducer={(page, entriesPerPage) => {
                return createJobsLink(props.filterParameters, {
                    ...props.paginationParameters,
                    page: page,
                    entriesPerPage: entriesPerPage,
                });
            }}
        />
    );
}

function JobsTable(props: {
    blueprintsByIdentifier: Map<string, BlueprintBaseDataForApi>;
    jobsResponse: JobsMetadataResponse;
    filterParameters: JobsFilterParameters;
}): JSX.Element {
    return (
        <ImanTable>
            <ImanTableHead>
                <FormattedTableCell id="jobs.table.status" defaultMessage="Status" />
                <FormattedTableCell id="jobs.table.blueprint" defaultMessage="Blueprint Name" />
                <FormattedTableCell id="jobs.table.start" defaultMessage="Start date and time" />
                <FormattedTableCell id="jobs.table.end" defaultMessage="End date and time" />
                <ImanTableCell />
            </ImanTableHead>
            <ImanTableBody>
                {props.jobsResponse.jobs.map((job) => {
                    return (
                        <JobTableRow
                            key={job.id}
                            blueprintsByIdentifier={props.blueprintsByIdentifier}
                            jobData={job}
                        />
                    );
                })}
            </ImanTableBody>
            <JobsPagingControl
                jobsResponse={props.jobsResponse}
                paginationParameters={{
                    page: props.jobsResponse.pagingMetaData.currentPage,
                    entriesPerPage: props.jobsResponse.pagingMetaData.entriesPerPage,
                }}
                filterParameters={props.filterParameters}
            />
        </ImanTable>
    );
}

function fallbackForUnexpectedlyMissingString(type: string, fallback: string): string {
    return `${type} not found: ${fallback}`;
}

function JobTableRow(props: {
    blueprintsByIdentifier: Map<string, BlueprintBaseDataForApi>;
    jobData: JobForApi;
}): JSX.Element {
    const imanApi = useImanApi();

    // Blueprint name should never be missing - fallback just because of map usage
    const blueprintName =
        props.blueprintsByIdentifier.get(props.jobData.blueprintIdentifier)?.name ??
        fallbackForUnexpectedlyMissingString("blueprintName", props.jobData.blueprintIdentifier);

    const intl = useIntl();
    const history = useHistory();
    const start = epochMillisToLocaleDateTimeString(props.jobData.startedAtInEpochMillis, intl);
    const end =
        props.jobData.elapsedMillis !== null && props.jobData.elapsedMillis !== undefined
            ? epochMillisToLocaleDateTimeString(
                  props.jobData.startedAtInEpochMillis + props.jobData.elapsedMillis,
                  intl
              )
            : "-";

    const jobStatus = resultToJobStatus(props.jobData.status);
    return (
        <ImanTableRow
            onClick={() => {
                history.push(Routes.getJobDetailsRoute(props.jobData.id));
            }}
        >
            <ImanTableCell>{<JobStatusIcon jobStatus={jobStatus} />}</ImanTableCell>
            <ImanTableCell>{blueprintName}</ImanTableCell>
            <ImanTableCell>{start}</ImanTableCell>
            <ImanTableCell>{end} </ImanTableCell>
            <ImanTableCell align="right">
                {jobStatus != JobStatus.RUNNING && jobStatus != JobStatus.CANCELLED ? (
                    <JobRowMenu imanApi={imanApi} jobData={props.jobData} />
                ) : null}
            </ImanTableCell>
        </ImanTableRow>
    );
}

function JobRowMenu(props: { imanApi: ImanApi; jobData: JobForApi }) {
    const [isOpen, setIsOpen] = useState(false);
    const [anchorElement, setAnchorElement] = useState<Element | null | undefined>(null);

    return (
        <Fragment>
            <IconButton
                onClick={(event) => {
                    event.stopPropagation();
                    setAnchorElement(event.currentTarget);
                    setIsOpen(true);
                }}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                onClick={(event) => {
                    // stopPropagation avoids triggering click on parent
                    event.stopPropagation();
                }}
                open={isOpen}
                onClose={() => {
                    setAnchorElement(null);
                    setIsOpen(false);
                }}
                anchorEl={anchorElement}
            >
                <MenuList>
                    <MenuItem
                        onClick={() => {
                            props.imanApi
                                .downloadJobData(props.jobData.id)
                                .then((blobWithFilename) => {
                                    setIsOpen(false);
                                    triggerDownload(blobWithFilename);
                                });
                        }}
                    >
                        <ListItemIcon>
                            <Download fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                            <FormattedMessage id="jobs.download" defaultMessage="Download" />
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>
        </Fragment>
    );
}

function resultToJobStatus(result: JobForApiStatusEnum): JobStatus {
    switch (result) {
        case JobForApiStatusEnum.Running:
            return JobStatus.RUNNING;
        case JobForApiStatusEnum.Cancelled:
            return JobStatus.CANCELLED;
        case JobForApiStatusEnum.Green:
            return JobStatus.DONE_GREEN;
        case JobForApiStatusEnum.Yellow:
            return JobStatus.DONE_YELLOW;
        case JobForApiStatusEnum.Red:
            return JobStatus.DONE_RED;
    }
}

function statusToSxColor(status: JobStatus): string {
    switch (status) {
        case JobStatus.RUNNING:
            return "info.main";
        case JobStatus.CANCELLED:
            return "warning.dark";
        case JobStatus.DONE_GREEN:
            return "success.dark";
        case JobStatus.DONE_YELLOW:
            return "warning.dark";
        case JobStatus.DONE_RED:
            return "error.dark";
    }
}
function JobStatusIcon(props: {
    jobStatus: JobStatus;
    size?: "small" | "medium" | "large";
}): JSX.Element {
    const fontSize = props.size ?? "medium";
    const color = statusToSxColor(props.jobStatus);
    switch (props.jobStatus) {
        case JobStatus.RUNNING:
            return <RotateRightOutlinedIcon sx={{ color: color }} fontSize={fontSize} />;
        case JobStatus.CANCELLED:
            return <CancelScheduleSendIcon sx={{ color: color }} fontSize={fontSize} />;
        case JobStatus.DONE_GREEN:
            return <AssignmentTurnedInOutlinedIcon sx={{ color: color }} fontSize={fontSize} />;
        case JobStatus.DONE_YELLOW:
            return <FeedbackOutlinedIcon sx={{ color: color }} fontSize={fontSize} />;
        case JobStatus.DONE_RED:
            return <WarningAmberOutlinedIcon sx={{ color: color }} fontSize={fontSize} />;
    }
}

function useFilterParametersFromLocation(): JobsFilterParameters {
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    return extractJobFilterParametersFromUrlSearchParams(urlSearchParams);
}

function usePaginationParametersFromLocation(): PaginationParameters {
    const location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    return extractPaginationParametersFromUrlSearchParams(urlSearchParams, 10);
}

function JobsPageHeader(props: {
    allBlueprints: BlueprintBaseDataForApi[];
    filterParameters: JobsFilterParameters;
    setFilterParameters: (newValue: JobsFilterParameters) => void;
    jobsResponse: JobsMetadataResponse | null | undefined;
}): JSX.Element {
    return (
        <ImanPageHeader titleId="jobs.title" titleDefaultMessage="Jobs">
            <JobFilters
                allBlueprints={props.allBlueprints}
                filterParameters={props.filterParameters}
                setFilterParameters={props.setFilterParameters}
            />
        </ImanPageHeader>
    );
}

function StatisticCard(props: {
    status: JobForApiStatusEnum;
    count: number;
    className?: string;
}): JSX.Element {
    const jobStatus = resultToJobStatus(props.status);
    return (
        <ImanCard width="200px">
            <ImanStack spacing={1}>
                <ImanStack direction="row" width="100%" align="center" justifyContent="center">
                    <JobStatusIcon jobStatus={jobStatus} size="large" />
                    <H4 className={props.className} sxColor={statusToSxColor(jobStatus)}>
                        {props.count}
                    </H4>
                </ImanStack>
                <H6 sxColor="text.secondary">
                    <StatusText status={jobStatus} />
                </H6>
            </ImanStack>
        </ImanCard>
    );
}

function StatusText(props: { status: JobStatus }): JSX.Element {
    switch (props.status) {
        case JobStatus.RUNNING:
            return <FormattedMessage id="jobs.status.active" defaultMessage="Active" />;
        case JobStatus.CANCELLED:
            return <FormattedMessage id="jobs.status.cancelled" defaultMessage="Cancelled" />;
        case JobStatus.DONE_GREEN:
            return <FormattedMessage id="jobs.status.green" defaultMessage="Successful" />;
        case JobStatus.DONE_YELLOW:
            return <FormattedMessage id="jobs.status.yellow" defaultMessage="Incomplete" />;
        case JobStatus.DONE_RED:
            return <FormattedMessage id="jobs.status.red" defaultMessage="Unsuccessful" />;
    }
}

function JobsStatistics(props: { statistics: JobsStatisticsForApi }): JSX.Element {
    return (
        <ImanStack direction="row" spacing={4}>
            <StatisticCard
                status={JobForApiStatusEnum.Running}
                count={props.statistics.activeJobs}
                className={"iman-statistics-active"}
            />
            <StatisticCard
                status={JobForApiStatusEnum.Green}
                count={props.statistics.greenJobs}
                className={"iman-statistics-green"}
            />
            <StatisticCard
                status={JobForApiStatusEnum.Yellow}
                count={props.statistics.yellowJobs}
                className={"iman-statistics-yellow"}
            />
            <StatisticCard
                status={JobForApiStatusEnum.Red}
                count={props.statistics.redJobs}
                className={"iman-statistics-red"}
            />
        </ImanStack>
    );
}

function JobFilters(props: {
    allBlueprints: BlueprintBaseDataForApi[];
    filterParameters: JobsFilterParameters;
    setFilterParameters: (newValue: JobsFilterParameters) => void;
}): JSX.Element {
    return (
        <ImanForm>
            <ImanStack direction="row">
                <BlueprintIdentifierDropdown
                    allBlueprints={props.allBlueprints}
                    currentBlueprintIdentifier={props.filterParameters.blueprintIdentifier}
                    setBlueprintIdentifier={(newBlueprint) =>
                        props.setFilterParameters({
                            ...props.filterParameters,
                            blueprintIdentifier: newBlueprint,
                        })
                    }
                />
                <JobStatusDropdown
                    currentJobStatus={props.filterParameters.jobStatus}
                    setJobStatus={(newStatus) =>
                        props.setFilterParameters({
                            ...props.filterParameters,
                            jobStatus: newStatus,
                        })
                    }
                />
            </ImanStack>
        </ImanForm>
    );
}

function BlueprintIdentifierDropdown(props: {
    allBlueprints: BlueprintBaseDataForApi[];
    currentBlueprintIdentifier: string | null;
    setBlueprintIdentifier: (newValue: string | null) => void;
}): JSX.Element {
    const allIdentifiers = props.allBlueprints.map((bp) => bp.identifier);
    return (
        <FilteringDropdown
            id="dropdown-blueprint"
            currentValue={props.currentBlueprintIdentifier}
            setValue={(newValue) =>
                newValue !== undefined
                    ? props.setBlueprintIdentifier(newValue)
                    : props.setBlueprintIdentifier(null)
            }
            allValues={[null, ...allIdentifiers]}
            displayValues={[
                <FormattedMessage
                    key="all"
                    id="jobs.filtering.blueprints.all"
                    defaultMessage="All blueprints"
                />,
                ...props.allBlueprints.map((bp) => bp.name),
            ]}
        />
    );
}

function JobStatusDropdown(props: {
    currentJobStatus: JobStatus | null;
    setJobStatus: (newValue: JobStatus | null) => void;
}): JSX.Element {
    return (
        <FilteringDropdown
            id="dropdown-status"
            currentValue={props.currentJobStatus}
            allValues={[
                null,
                JobStatus.RUNNING,
                JobStatus.DONE_GREEN,
                JobStatus.DONE_YELLOW,
                JobStatus.DONE_RED,
            ]}
            setValue={(newValue) =>
                newValue !== undefined ? props.setJobStatus(newValue) : props.setJobStatus(null)
            }
            displayValues={[
                <FormattedMessage
                    key="all"
                    id="jobs.filtering.status.all"
                    defaultMessage="All states"
                />,
                <FormattedMessage
                    key="running"
                    id="jobs.filtering.status.running"
                    defaultMessage="Running"
                />,
                <FormattedMessage
                    key="green"
                    id="jobs.filtering.status.green"
                    defaultMessage="Successful"
                />,
                <FormattedMessage
                    key="yellow"
                    id="jobs.filtering.status.yellow"
                    defaultMessage="Not successful"
                />,
                <FormattedMessage
                    key="red"
                    id="jobs.filtering.status.red"
                    defaultMessage="Error"
                />,
            ]}
        />
    );
}
