import { FormattedMessage } from "react-intl";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { recoilHasToken } from "../recoilStore";
import { ImanRow } from "./wrappers/ImanGrid";
import { Routes } from "../functionality/Routes";

export function HomePageComponent(): JSX.Element {
    const hasToken = useRecoilValue(recoilHasToken);

    return (
        <div className="iman-home">
            <h3>
                <FormattedMessage id="home.title" defaultMessage="Home" />
            </h3>
            {hasToken ? (
                ""
            ) : (
                <ImanRow>
                    <Link to={Routes.LOGIN}>
                        <FormattedMessage id="login.title" defaultMessage="Login" />
                    </Link>
                </ImanRow>
            )}
        </div>
    );
}
