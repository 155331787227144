import React, { useState } from "react";
import { BsPlus } from "react-icons/bs";
import { ImanButton } from "./wrappers/Buttons";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";

function callOnEnter(event: React.KeyboardEvent<HTMLDivElement>, callback: () => void) {
    if (event.key === "Enter" || event.keyCode === 13) {
        callback();
    }
}

interface AutoCompleteInputProps {
    id: string;
    submitValue: (newValue: string) => void;
    fetchSuggestions: (currentValue: string) => string[];
    label?: string;
}

// eslint-disable-next-line max-lines-per-function
export function AutoCompleteInput(props: AutoCompleteInputProps): JSX.Element {
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [newValue, setNewValue] = useState("");
    const submitCurrentValue = (value: string) => {
        if (suggestions.includes(value)) {
            setNewValue("");
            props.submitValue(value);
        }
    };

    return (
        <Autocomplete
            selectOnFocus
            fullWidth
            onFocus={() => {
                const newSuggestions = props.fetchSuggestions(newValue);
                setSuggestions(newSuggestions);
            }}
            onChange={(event, value) => {
                if (value !== null) {
                    submitCurrentValue(value);
                }
            }}
            freeSolo
            value={newValue}
            inputValue={newValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    label={props.label}
                    onKeyUp={(e) => {
                        callOnEnter(e, () => submitCurrentValue(newValue));
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                <ImanButton
                                    onClick={() => {
                                        submitCurrentValue(newValue);
                                        setSuggestions(props.fetchSuggestions(newValue));
                                    }}
                                >
                                    <BsPlus />
                                </ImanButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            options={suggestions}
        />
    );
}
