import { useRecoilState, useRecoilValue } from "recoil";
import { recoilLocaleSetByUser, recoilUserPermissions } from "../recoilStore";
import { FormattedMessage, useIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import {
    FrontendAccessRightsAll,
    FrontendAccessRightsDepr,
} from "../functionality/frontendAccessRightsDepr";
import { useImanApi } from "../util/ApiUtil";
import {
    DeleteUserResponseResultEnum,
    PermissionForApiPermissionTypeEnum,
    RoleForApi,
    StopApplicationResponseResultEnum,
    SystemInfoResponse,
    UpdateUserDataResponseResultEnum,
    UserForApi,
    UserForApiStateEnum,
} from "../client";
import { ImanForm } from "./wrappers/form/Form";
import {
    IconButtonDelete,
    IconButtonEdit,
    IconButtonKey,
    ImanButton,
    ImanSubmitButton,
} from "./wrappers/Buttons";
import { SimpleInput, TextInputWithButton } from "./wrappers/form/TextInputs";
import { createPasswordLink } from "../functionality/passwordFunctionality";
import { ImanRow } from "./wrappers/ImanGrid";
import { FrontendCreateUserResponseType, toFrontendType } from "../functionality/userHandling";
import { MessageBoxError } from "./wrappers/MessageBoxes";
import { AiOutlineCopy } from "react-icons/ai";
import { FilteringDropdown } from "./wrappers/form/FilteringDropdown";
import {
    HasAnyPermissionOfTypes,
    ImanAvatar,
    ImanBox,
    ImanConfirmationDialog,
    ImanDivider,
    ImanModal,
    ImanPopover,
    ImanStack,
} from "./wrappers/ImanLayout";
import { Body1, Body2, H2, H4, H5, H6 } from "./wrappers/ImanTypography";
import { ImanCard } from "./wrappers/ImanCard";
import { ImanList, ImanListItem, ImanListItemButton } from "./wrappers/ImanList";
import { bytesToHumanReadable, fromRatioToPercent } from "../util/StringUtil";
import { RolesList } from "./RolesList";
import { hasAnyRequiredPermissionOfType } from "../functionality/PermissionsUtil";

export function AdminComponentContainer(): JSX.Element {
    const imanApi = useImanApi();
    const userPermissions = useRecoilValue(recoilUserPermissions);

    const [didFetch, setDidFetch] = useState(false);

    const [users, setUsers] = useState<UserForApi[]>([]);
    const [allRoles, setAllRoles] = useState<RoleForApi[]>([]);

    const getAllRoles = () => {
        // Refresh access token before fetching so the new user's token contains any changes.
        imanApi.refreshAccessToken().then(() => {
            imanApi.getAllRoles().then((response) => setAllRoles(response.roles));
        });
    };
    useEffect(getAllRoles, []);

    const fetchUserData = () => {
        if (
            hasAnyRequiredPermissionOfType(
                [PermissionForApiPermissionTypeEnum.ReadUserData],
                userPermissions
            )
        ) {
            imanApi.getAllUsers((users) => setUsers(users));
        }
    };

    useEffect(() => {
        if (!didFetch) {
            fetchUserData();
            setDidFetch(true);
        }
    }, [users, setUsers, didFetch, setDidFetch]);

    return (
        <AdminComponent
            allRoles={allRoles}
            users={users}
            updateUserData={fetchUserData}
            updateAllRoles={getAllRoles}
        />
    );
}

function AdminComponent(props: {
    allRoles: RoleForApi[];
    users: UserForApi[];
    updateUserData: () => void;
    updateAllRoles: () => void;
}): JSX.Element {
    return (
        <ImanBox>
            <H2>
                <FormattedMessage id="admin.header" defaultMessage="Admin" />
            </H2>

            <ImanCard>
                <ImanStack align="start">
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.ReadUserData]}
                    >
                        <UsersList
                            allRoles={props.allRoles}
                            users={props.users}
                            updateUsersData={props.updateUserData}
                        />
                        <ImanDivider />
                    </HasAnyPermissionOfTypes>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[
                            PermissionForApiPermissionTypeEnum.CreateRoles,
                            PermissionForApiPermissionTypeEnum.EditRoles,
                            PermissionForApiPermissionTypeEnum.DeleteRoles,
                        ]}
                    >
                        <RolesList
                            allRoles={props.allRoles}
                            getAllRoles={props.updateAllRoles}
                            updateUsersData={props.updateUserData}
                        />
                        <ImanDivider />
                    </HasAnyPermissionOfTypes>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.VdctAll]}
                    >
                        <EncryptStringForm />
                    </HasAnyPermissionOfTypes>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.ReadSystemInfo]}
                    >
                        <SystemInformationComponent />
                    </HasAnyPermissionOfTypes>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.StopApplication]}
                    >
                        <StopApplicationForm />
                    </HasAnyPermissionOfTypes>
                </ImanStack>
            </ImanCard>
        </ImanBox>
    );
}

function EditUserForm(props: {
    allRoles: RoleForApi[];
    user: UserForApi;
    onSuccess: () => void;
    onCancel: () => void;
}): JSX.Element {
    const imanApi = useImanApi();
    const [name, setName] = useState(props.user.name);
    const [email, setEmail] = useState(props.user.emailAddress);
    const [isFetching, setIsFetching] = useState(false);
    const [roles, setRoles] = useState(props.user.roles);

    return (
        <ImanForm
            onSubmit={(event) => {
                event.preventDefault();
                setIsFetching(true);
                imanApi
                    .updateUser(
                        props.user.id,
                        name,
                        email,
                        roles.map((role) => role.identifier)
                    )
                    .then((response) => {
                        setIsFetching(false);
                        switch (response.result) {
                            case UpdateUserDataResponseResultEnum.Success:
                                props.onSuccess();
                                break;
                            case UpdateUserDataResponseResultEnum.Error:
                                break;
                        }
                    });
                setIsFetching(true);
            }}
        >
            <ImanRow>
                <UserFormInputs
                    cancel={props.onCancel}
                    emailAddress={email}
                    setEmailAddress={setEmail}
                    name={name}
                    setName={setName}
                    isFetching={isFetching}
                    allRoles={props.allRoles}
                    rolesOfUser={roles}
                    setRolesOfUser={setRoles}
                />
            </ImanRow>
        </ImanForm>
    );
}

type ErrorType =
    | "ID_NOT_UNIQUE"
    | "GENERIC_ERROR"
    | "INVALID_EMAIL_ADDRESS"
    | "EMAIL_ADDRESS_NOT_UNIQUE";

// eslint-disable-next-line max-lines-per-function
function CreateUserForm(props: {
    allRoles: RoleForApi[];
    onSuccess: () => void;
    onCancel: () => void;
}): JSX.Element {
    const imanApi = useImanApi();

    const [isFetching, setIsFetching] = useState(false);
    const [identifier, setIdentifier] = useState("");
    const [name, setName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [roles, setRoles] = useState<RoleForApi[]>([]);
    const [errorType, setErrorType] = useState<ErrorType | null>(null);
    const identifierNotEmpty = name !== "";

    return (
        <ImanForm
            onSubmit={(event) => {
                event.preventDefault();
                if (identifierNotEmpty) {
                    setIsFetching(true);
                    imanApi.createNewUser(
                        identifier,
                        name,
                        emailAddress,
                        FrontendAccessRightsDepr.READ_ONLY,
                        roles,
                        (response) => {
                            setIsFetching(false);
                            switch (toFrontendType(response.result)) {
                                case FrontendCreateUserResponseType.SUCCESS:
                                    props.onSuccess();
                                    setIdentifier("");
                                    setName("");
                                    setEmailAddress("");
                                    setErrorType(null);
                                    break;
                                case FrontendCreateUserResponseType.IDENTIFIER_NOT_UNIQUE:
                                    setErrorType("ID_NOT_UNIQUE");
                                    break;
                                case FrontendCreateUserResponseType.INVALID_EMAIL_ADDRESS:
                                    setErrorType("INVALID_EMAIL_ADDRESS");
                                    break;
                                case FrontendCreateUserResponseType.EMAIL_ADDRESS_NOT_UNIQUE:
                                    setErrorType("EMAIL_ADDRESS_NOT_UNIQUE");
                                    break;
                                case FrontendCreateUserResponseType.ERROR:
                                    setErrorType("GENERIC_ERROR");
                                    break;
                            }
                        }
                    );
                }
            }}
        >
            <UserFormInputs
                cancel={props.onCancel}
                emailAddress={emailAddress}
                setEmailAddress={setEmailAddress}
                name={name}
                setName={setName}
                isFetching={isFetching}
                allRoles={props.allRoles}
                rolesOfUser={roles}
                setRolesOfUser={setRoles}
            />
            <ImanRow>{errorType !== null ? <ErrorMessage errorType={errorType} /> : null}</ImanRow>
        </ImanForm>
    );
}

// eslint-disable-next-line max-lines-per-function
function UserFormInputs(props: {
    cancel: () => void;
    emailAddress: string;
    setEmailAddress: ((newValue: string) => void) | null;
    name: string;
    setName: (newName: string) => void;
    isFetching: boolean;
    rolesOfUser: RoleForApi[];
    setRolesOfUser: (newRoles: RoleForApi[]) => void;
    allRoles: RoleForApi[];
}): JSX.Element {
    const identifierNotEmpty = props.emailAddress !== "";
    return (
        <ImanBox fillSpace>
            <ImanStack fullWidth align="start" justifyContent="flex-start">
                <SimpleInput
                    labelI18nIdentifier={"admin.users.form.email"}
                    defaultLabel={"e-mail"}
                    value={props.emailAddress}
                    disabled={props.setEmailAddress === null}
                    onChange={(event) => {
                        if (props.setEmailAddress !== null) {
                            props.setEmailAddress(event.target.value);
                        }
                    }}
                />
                <SimpleInput
                    labelI18nIdentifier={"admin.users.form.name"}
                    defaultLabel={"Name of user"}
                    value={props.name}
                    onChange={(event) => {
                        props.setName(event.target.value);
                    }}
                />
                <ImanDivider />
                <HasAnyPermissionOfTypes
                    restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.AssignRolesToUsers]}
                >
                    <UserRolesInput
                        rolesOfUser={props.rolesOfUser}
                        setRolesOfUser={props.setRolesOfUser}
                        allRoles={props.allRoles}
                    />
                </HasAnyPermissionOfTypes>
                <ImanStack fullWidth direction="row" justifyContent="flex-end">
                    <ImanButton variant="outlined" color="secondary" onClick={props.cancel}>
                        <FormattedMessage id={"general.dialog.cancel"} defaultMessage={"Cancel"} />
                    </ImanButton>
                    <ImanSubmitButton isActive={identifierNotEmpty} isFetching={props.isFetching}>
                        <FormattedMessage id={"admin.users.form.submit"} defaultMessage={"Save"} />
                    </ImanSubmitButton>
                </ImanStack>
            </ImanStack>
        </ImanBox>
    );
}

// eslint-disable-next-line max-lines-per-function
function UserRolesInput(props: {
    rolesOfUser: RoleForApi[];
    setRolesOfUser: (newRoles: RoleForApi[]) => void;
    allRoles: RoleForApi[];
}): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const isRoleSelectOpen = anchorEl !== null;

    return (
        <div>
            <ImanPopover
                anchorEl={anchorEl}
                isOpen={isRoleSelectOpen}
                close={() => setAnchorEl(null)}
            >
                <ImanList>
                    {props.allRoles.map((role) => {
                        return (
                            <ImanListItemButton
                                key={role.identifier}
                                disabled={
                                    props.rolesOfUser.find((el) => {
                                        return el.identifier === role.identifier;
                                    }) !== undefined
                                }
                                onClick={() => {
                                    props.setRolesOfUser(props.rolesOfUser.concat(role));
                                }}
                            >
                                {role.name}
                            </ImanListItemButton>
                        );
                    })}
                </ImanList>
            </ImanPopover>
            <ImanStack direction="row" justifyContent="space-between">
                <H6>
                    <FormattedMessage id="admin.users.form.roles" defaultMessage="Roles" />
                </H6>
                <ImanButton
                    onClick={(event) => {
                        if (anchorEl === null) {
                            setAnchorEl(event.currentTarget);
                        } else {
                            setAnchorEl(null);
                        }
                    }}
                >
                    <FormattedMessage id="admin.users.form.roles.add" defaultMessage="Add" />
                </ImanButton>
            </ImanStack>
            {props.rolesOfUser.length === 0 && (
                <Body1>
                    <em>
                        <FormattedMessage
                            id={"admin.users.form.roles.none"}
                            defaultMessage="none"
                        />
                    </em>
                </Body1>
            )}
            <ImanList>
                {props.rolesOfUser.map((role) => {
                    return (
                        <ImanListItem key={role.identifier} divider>
                            <ImanStack fullWidth spacing={0} align="start">
                                <Body1>{role.name}</Body1>
                                <Body2>
                                    {role.permissions
                                        .map((permission) => permission.serialized)
                                        .join(", ")}
                                </Body2>
                            </ImanStack>
                            <IconButtonDelete
                                onClick={() => {
                                    props.setRolesOfUser(
                                        props.rolesOfUser.filter((el) => {
                                            return el.identifier !== role.identifier;
                                        })
                                    );
                                }}
                            />
                        </ImanListItem>
                    );
                })}
            </ImanList>
        </div>
    );
}

function ErrorMessage(props: { errorType: ErrorType }): JSX.Element {
    let errorMessageId = "admin.users.errorMessage.genericError";
    let defaultMessage = "Error";
    switch (props.errorType) {
        case "ID_NOT_UNIQUE":
            errorMessageId = "admin.users.errorMessage.identifierNotUnique";
            defaultMessage = "Id not unique";
            break;
        case "INVALID_EMAIL_ADDRESS":
            errorMessageId = "admin.users.errorMessage.emailInvalid";
            defaultMessage = "Invalid email";
            break;
        case "EMAIL_ADDRESS_NOT_UNIQUE":
            errorMessageId = "admin.users.errorMessage.emailNotUnique";
            defaultMessage = "Email not unique";
            break;
        case "GENERIC_ERROR":
            errorMessageId = "admin.users.errorMessage.genericError";
            defaultMessage = "There was an error";
            break;
    }
    return (
        <MessageBoxError>
            <FormattedMessage id={errorMessageId} defaultMessage={defaultMessage} />
        </MessageBoxError>
    );
}

export function AccessRightsDropdown(props: {
    currentAccessRights: FrontendAccessRightsDepr;
    setAccessRights: (newRights: FrontendAccessRightsDepr) => void;
}): JSX.Element {
    return (
        <FilteringDropdown
            id="access-rights-dropdown"
            currentValue={props.currentAccessRights}
            setValue={(newValue: FrontendAccessRightsDepr | undefined) => {
                if (newValue !== undefined) {
                    props.setAccessRights(newValue);
                }
            }}
            allValues={FrontendAccessRightsAll}
        />
    );
}

function CreateUserModal(props: {
    isOpen: boolean;
    onClose: () => void;
    fetchUsers: () => void;
    allRoles: RoleForApi[];
}): JSX.Element {
    return (
        <ImanModal isOpen={props.isOpen} onClose={props.onClose}>
            <H4 gutterBottom>
                <FormattedMessage id={"admin.users.create"} defaultMessage={"Create User"} />
            </H4>
            <CreateUserForm
                onSuccess={() => {
                    props.fetchUsers();
                    props.onClose();
                }}
                onCancel={props.onClose}
                allRoles={props.allRoles}
            />
        </ImanModal>
    );
}

// eslint-disable-next-line max-lines-per-function
export function UsersList(props: {
    allRoles: RoleForApi[];
    users: UserForApi[];
    updateUsersData: () => void;
}): JSX.Element {
    const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
    return (
        <React.Fragment>
            <CreateUserModal
                isOpen={isCreateUserModalOpen}
                onClose={() => setIsCreateUserModalOpen(false)}
                fetchUsers={props.updateUsersData}
                allRoles={props.allRoles}
            />
            <ImanStack align="start" fullWidth spacing={6}>
                <ImanStack direction="row">
                    <H6>
                        <FormattedMessage id="admin.users.header" defaultMessage="Users" />
                    </H6>
                    <HasAnyPermissionOfTypes
                        restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.CreateUsers]}
                    >
                        <ImanButton
                            variant="outlined"
                            onClick={() => {
                                setIsCreateUserModalOpen(true);
                            }}
                        >
                            <FormattedMessage id="admin.users.create" defaultMessage="Add user" />
                        </ImanButton>
                    </HasAnyPermissionOfTypes>
                </ImanStack>
                <ImanBox width="50%">
                    <ImanStack spacing={3} fullWidth>
                        {props.users.map((user) => {
                            return (
                                <UserRow
                                    key={user.id}
                                    user={user}
                                    fetchUsers={props.updateUsersData}
                                    allRoles={props.allRoles}
                                />
                            );
                        })}
                    </ImanStack>
                </ImanBox>
            </ImanStack>
        </React.Fragment>
    );
}

function PasswordLinkButton(props: {
    user: UserForApi;
    setPasswordLink: (passwordLink: string) => void;
}): JSX.Element {
    const imanApi = useImanApi();
    return (
        <IconButtonKey
            onClick={() => {
                imanApi.getPasswordToken(
                    props.user.id,
                    (token) => {
                        const passwordLink = createPasswordLink(window.location, token);
                        props.setPasswordLink(passwordLink);
                    },
                    () => {
                        console.log("TODO: Could not fetch");
                    }
                );
            }}
        />
    );
}

// eslint-disable-next-line max-lines-per-function
function DeleteUserDialog(props: {
    user: UserForApi;
    isOpen: boolean;
    close: () => void;
    onSuccess: () => void;
}): JSX.Element {
    const intl = useIntl();
    const imanApi = useImanApi();
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    return (
        <ImanConfirmationDialog
            isOpen={props.isOpen}
            onCancel={() => {
                props.close();
                setErrorMessage(undefined);
            }}
            onConfirm={() => {
                imanApi.deleteUser(props.user.id).then((response) => {
                    switch (response.result) {
                        case DeleteUserResponseResultEnum.Success:
                            props.onSuccess();
                            props.close();
                            break;
                        case DeleteUserResponseResultEnum.UnknownUser:
                            setErrorMessage(
                                intl.formatMessage({
                                    id: "admin.users.delete.error.genericError",
                                    defaultMessage: "Could not delete.",
                                })
                            );
                            break;
                        case DeleteUserResponseResultEnum.CannotDeleteYourself:
                            setErrorMessage(
                                intl.formatMessage({
                                    id: "admin.users.delete.error.cannotDeleteYourself",
                                    defaultMessage: "Cannot delete yourself.",
                                })
                            );
                            break;
                    }
                });
            }}
            title={intl.formatMessage({
                id: "admin.users.delete.title",
                defaultMessage: "Delete user",
            })}
            message={
                <Body1>
                    <FormattedMessage
                        id="admin.users.delete.confirmationText"
                        defaultMessage="Delete user {name}?"
                        values={{
                            name: props.user.name,
                        }}
                    />
                </Body1>
            }
            errorMessage={errorMessage}
        />
    );
}

function EditUserModal(props: {
    user: UserForApi;
    isOpen: boolean;
    close: () => void;
    allRoles: RoleForApi[];
    fetchUsers: () => void;
}): JSX.Element {
    return (
        <ImanModal isOpen={props.isOpen} onClose={props.close}>
            <ImanBox>
                <H4 gutterBottom>
                    <FormattedMessage
                        id={"admin.users.edit.title"}
                        defaultMessage="Edit {name}"
                        values={{
                            name: `${props.user.name} (${props.user.id})`,
                        }}
                    />
                </H4>
                <EditUserForm
                    allRoles={props.allRoles}
                    user={props.user}
                    onSuccess={() => {
                        props.fetchUsers();
                        props.close();
                    }}
                    onCancel={props.close}
                />
            </ImanBox>
        </ImanModal>
    );
}

export function UserRow(props: {
    allRoles: RoleForApi[];
    user: UserForApi;
    fetchUsers: () => void;
}): JSX.Element {
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [passwordLink, setPasswordLink] = useState<string | null>(null);

    return (
        <React.Fragment>
            <EditUserModal
                user={props.user}
                isOpen={isEditFormOpen}
                close={() => setIsEditFormOpen(false)}
                allRoles={props.allRoles}
                fetchUsers={props.fetchUsers}
            />
            <DeleteUserDialog
                user={props.user}
                isOpen={isDeleteDialogOpen}
                close={() => setIsDeleteDialogOpen(false)}
                onSuccess={() => props.fetchUsers()}
            />
            <ImanStack spacing={0} justifyContent="flex-start" align="start" fullWidth>
                <ImanStack direction="row" fullWidth>
                    <ImanAvatar text={props.user.name} />
                    <ImanStack justifyContent="flex-start" align="start" spacing={0} fullWidth>
                        <Body1>
                            {props.user.name} ({props.user.state})
                        </Body1>
                        <Body2>
                            {props.user.roles
                                .map((role) => {
                                    return role.name;
                                })
                                .join(", ")}
                        </Body2>
                    </ImanStack>
                    <UserButtons
                        user={props.user}
                        openEditForm={() => setIsEditFormOpen(true)}
                        openDeleteDialog={() => setIsDeleteDialogOpen(true)}
                        setPasswordLink={setPasswordLink}
                    />
                </ImanStack>
                {passwordLink !== null && (
                    <TextInputWithButton
                        value={passwordLink}
                        onClick={() => navigator.clipboard.writeText(passwordLink)}
                    >
                        <AiOutlineCopy />
                    </TextInputWithButton>
                )}
            </ImanStack>
        </React.Fragment>
    );
}

function UserButtons(props: {
    user: UserForApi;
    openEditForm: () => void;
    openDeleteDialog: () => void;
    setPasswordLink: (value: string) => void;
}): JSX.Element {
    return (
        <ImanStack direction="row">
            {props.user.state === UserForApiStateEnum.RegistrationPending ? (
                <PasswordLinkButton user={props.user} setPasswordLink={props.setPasswordLink} />
            ) : null}
            <HasAnyPermissionOfTypes
                restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.UpdateUsers]}
            >
                <IconButtonEdit onClick={() => props.openEditForm()} />
            </HasAnyPermissionOfTypes>
            <HasAnyPermissionOfTypes
                restrictedToAnyOf={[PermissionForApiPermissionTypeEnum.DeleteUsers]}
            >
                <IconButtonDelete onClick={() => props.openDeleteDialog()} />
            </HasAnyPermissionOfTypes>
        </ImanStack>
    );
}

function EncryptStringForm(): JSX.Element {
    const [rawValue, setRawValue] = useState("");
    const [encryptedValue, setEncryptedValue] = useState("");
    const imanApi = useImanApi();
    return (
        <ImanStack align="start" justifyContent="flex-start" fullWidth>
            <H6>
                <FormattedMessage id="admin.tools.title" defaultMessage="Tools" />
            </H6>
            <ImanForm
                onSubmit={(event) => {
                    event.preventDefault();
                    imanApi.postEncryptValue(rawValue).then((result) => setEncryptedValue(result));
                }}
            >
                <SimpleInput
                    labelI18nIdentifier="admin.encrypt.label"
                    defaultLabel={"Raw value"}
                    onChange={(event) => {
                        setRawValue(event.target.value);
                    }}
                    value={rawValue}
                />
                <ImanSubmitButton>
                    <FormattedMessage id="admin.encrypt.submit" defaultMessage={"Encrypt"} />
                </ImanSubmitButton>
            </ImanForm>
            <TextInputWithButton
                value={encryptedValue}
                onClick={() => navigator.clipboard.writeText(encryptedValue)}
            >
                <AiOutlineCopy />
            </TextInputWithButton>
        </ImanStack>
    );
}

function SystemInformationComponent(): JSX.Element {
    const api = useImanApi();
    const [systemInfo, setSystemInfo] = useState<SystemInfoResponse | null>(null);

    useEffect(() => {
        api.getSystemInfo().then((response) => setSystemInfo(response));
    }, []);

    return (
        <React.Fragment>
            <ImanButton
                onClick={() => {
                    api.getSystemInfo().then((response) => setSystemInfo(response));
                }}
            >
                <FormattedMessage id="admin.systeminfo.get" defaultMessage="Get System Info" />
            </ImanButton>
            {systemInfo !== null && <SystemInformation systemInformation={systemInfo} />}
        </React.Fragment>
    );
}

function SystemInformation(props: { systemInformation: SystemInfoResponse }): JSX.Element {
    return (
        <ImanStack align="start">
            <OsList systemInformation={props.systemInformation} />
            <CpuList systemInformation={props.systemInformation} />
            <MemoryList systemInformation={props.systemInformation} />
            <DiskSpaceList systemInformation={props.systemInformation} />
        </ImanStack>
    );
}

function OsList(props: { systemInformation: SystemInfoResponse }): JSX.Element {
    return (
        <ImanList>
            <SystemInfoElement
                id="admin.systeminfo.os.name"
                defaultMessage="Name"
                value={props.systemInformation.operatingSystemInfo.name}
            />
            <SystemInfoElement
                id="admin.systeminfo.os.version"
                defaultMessage="Version"
                value={props.systemInformation.operatingSystemInfo.version}
            />
            <SystemInfoElement
                id="admin.systeminfo.os.architecture"
                defaultMessage="Architecture"
                value={props.systemInformation.operatingSystemInfo.architecture}
            />
        </ImanList>
    );
}

function CpuList(props: { systemInformation: SystemInfoResponse }): JSX.Element {
    return (
        <ImanList>
            <SystemInfoElement
                id="admin.systeminfo.cpu.processCpuLoad"
                defaultMessage="Process Load"
                value={fromRatioToPercent(props.systemInformation.cpuInfo.processCpuLoad)}
            />
            <SystemInfoElement
                id="admin.systeminfo.cpu.systemLoad"
                defaultMessage="System Load"
                value={fromRatioToPercent(props.systemInformation.cpuInfo.systemCpuLoad)}
            />
            <SystemInfoElement
                id="admin.systeminfo.cpu.systemLoadAverage"
                defaultMessage="System Load Average"
                value={props.systemInformation.cpuInfo.systemLoadAverage.toString()}
            />
            <SystemInfoElement
                id="admin.systeminfo.cpu.jvmAvailableProcessors"
                defaultMessage="Available Processors JVM"
                value={props.systemInformation.cpuInfo.jvmAvailableProcessors.toString()}
            />
            <SystemInfoElement
                id="admin.systeminfo.cpu.systemAvailableProcessors"
                defaultMessage="Available Processors System"
                value={props.systemInformation.cpuInfo.systemAvailableProcessors.toString()}
            />
            <SystemInfoElement
                id="admin.systeminfo.cpu.runningThreads"
                defaultMessage="Running Threads"
                value={props.systemInformation.cpuInfo.runningThreads.toString()}
            />
        </ImanList>
    );
}

function MemoryList(props: { systemInformation: SystemInfoResponse }): JSX.Element {
    const [locale] = useRecoilState(recoilLocaleSetByUser);
    return (
        <ImanList>
            <SystemInfoElement
                id="admin.systeminfo.memory.jvmUsedMemorySize"
                defaultMessage="Used memory JVM"
                value={
                    bytesToHumanReadable(
                        props.systemInformation.memoryInfo.jvmTotalMemorySize -
                            props.systemInformation.memoryInfo.jvmFreeMemorySize,
                        locale
                    ) +
                    "/" +
                    bytesToHumanReadable(
                        props.systemInformation.memoryInfo.jvmTotalMemorySize,
                        locale
                    )
                }
            />
            <SystemInfoElement
                id="admin.systeminfo.memory.jvmMaxMemorySize"
                defaultMessage="JVM max memory"
                value={bytesToHumanReadable(
                    props.systemInformation.memoryInfo.jvmMaxMemorySize,
                    locale
                )}
            />
            <SystemInfoElement
                id="admin.systeminfo.memory.systemFreeMemorySize"
                defaultMessage="Free memory system"
                value={bytesToHumanReadable(
                    props.systemInformation.memoryInfo.systemFreeMemorySize,
                    locale
                )}
            />
            <SystemInfoElement
                id="admin.systeminfo.memory.systemTotalMemorySize"
                defaultMessage="Total memory system"
                value={bytesToHumanReadable(
                    props.systemInformation.memoryInfo.systemTotalMemorySize,
                    locale
                )}
            />
        </ImanList>
    );
}

function DiskSpaceList(props: { systemInformation: SystemInfoResponse }): JSX.Element {
    const [locale] = useRecoilState(recoilLocaleSetByUser);

    return (
        <ImanList>
            <SystemInfoElement
                id="admin.systeminfo.disk.usableDiskSpace"
                defaultMessage="Usable disk space"
                value={bytesToHumanReadable(
                    props.systemInformation.diskSpaceInfo.usableDiskSpace,
                    locale
                )}
            />
            <SystemInfoElement
                id="admin.systeminfo.disk.freeDiskSpace"
                defaultMessage="Free disk space"
                value={bytesToHumanReadable(
                    props.systemInformation.diskSpaceInfo.freeDiskSpace,
                    locale
                )}
            />
            <SystemInfoElement
                id="admin.systeminfo.disk.totalDiskSpace"
                defaultMessage="Total disk space"
                value={bytesToHumanReadable(
                    props.systemInformation.diskSpaceInfo.totalDiskSpace,
                    locale
                )}
            />
        </ImanList>
    );
}

function SystemInfoElement(props: {
    id: string;
    defaultMessage: string;
    value: string;
}): JSX.Element {
    return (
        <ImanListItem>
            <strong>
                <FormattedMessage id={props.id} defaultMessage={props.defaultMessage} />:
            </strong>
            &nbsp;
            {props.value}
        </ImanListItem>
    );
}

// eslint-disable-next-line max-lines-per-function
function StopApplicationForm(): JSX.Element {
    const api = useImanApi();
    const [password, setPassword] = useState("");
    const [isWaiting, setIsWaiting] = useState(false);
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);
    return (
        <React.Fragment>
            <H5>
                <FormattedMessage
                    id="admin.stopApplication.title"
                    defaultMessage="Stop application"
                />
            </H5>
            <strong>
                <FormattedMessage
                    id="admin.stopApplication.warning"
                    defaultMessage="Attention! This will stop iMan. Please confirm with password."
                />
            </strong>
            <p>
                <FormattedMessage
                    id="admin.stopApplication.info"
                    defaultMessage="Stops the application after all currently running jobs have finished regularly."
                />
            </p>
            <ImanForm
                onSubmit={(event) => {
                    event.preventDefault();
                    setIsWaiting(true);
                    setPasswordIsInvalid(false);
                    api.stopApplication(password, "SOFT")
                        .then((response) => {
                            if (
                                response.result ===
                                StopApplicationResponseResultEnum.InvalidPassword
                            ) {
                                setPasswordIsInvalid(true);
                            } else {
                                setPassword("");
                            }
                        })
                        .then(() => setIsWaiting(false));
                }}
                submitButton={
                    <ImanSubmitButton isFetching={isWaiting}>
                        <FormattedMessage
                            id={"admin.stopApplication.submit"}
                            defaultMessage="Stop application"
                        />
                    </ImanSubmitButton>
                }
            >
                <SimpleInput
                    password
                    required
                    labelI18nIdentifier="admin.stopApplication.password"
                    defaultLabel="Password"
                    value={password}
                    isValueValid={!passwordIsInvalid}
                    onChange={(event) => {
                        setPassword(event.target.value);
                    }}
                />
            </ImanForm>
        </React.Fragment>
    );
}
