import { BsPlus } from "react-icons/bs";
import React, { useState } from "react";
import { TextInputWithButton } from "./form/TextInputs";

export function SubmittableTextInput(props: {
    submitValue: (newValue: string) => void;
    placeholder?: string;
    label?: string;
}): JSX.Element {
    const [newValue, setNewValue] = useState("");

    return (
        <TextInputWithButton
            value={newValue}
            labelText={props.label}
            placeholder={props.placeholder}
            onChange={(event) => setNewValue(event.target.value)}
            onClick={() => {
                props.submitValue(newValue);
                setNewValue("");
            }}
        >
            <BsPlus />
        </TextInputWithButton>
    );
}
