import React from "react";
import { Grid } from "@mui/material";

export function ImanRow(props: {
    children?: React.ReactNode;
    className?: string;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    textAlign?: "left";
}): JSX.Element {
    const lightBorderColor = "rgba(0, 0, 0, 0.08)";
    return (
        <Grid
            container
            item
            textAlign={props.textAlign}
            className={props.className}
            xs={12}
            sx={{
                borderLeft: props.borderLeft ? 1 : 0,
                borderRight: props.borderRight ? 1 : 0,
                borderTop: props.borderTop ? 1 : 0,
                borderBottom: props.borderBottom ? 1 : 0,
                borderLeftColor: lightBorderColor,
                borderRightColor: lightBorderColor,
                borderTopColor: lightBorderColor,
                borderBottomColor: lightBorderColor,
            }}
        >
            {props.children}
        </Grid>
    );
}

export interface ImanGridCellProps {
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
    sm?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
    xl?: 1 | 2 | 3 | 4 | 5 | 6 | 8 | 9 | 10 | 11 | 12;
    className?: string;
    children?: React.ReactNode;
    borderLeft?: boolean;
    borderRight?: boolean;
    borderTop?: boolean;
    borderBottom?: boolean;
    padding?: number;
    textAlign?: "left";
}

export function ImanGridCell(props: ImanGridCellProps): JSX.Element {
    const lightBorderColor = "rgba(0, 0, 0, 0.08)";
    return (
        <Grid
            item
            textAlign={props.textAlign}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            padding={props.padding}
            className={props.className}
            sx={{
                borderLeft: props.borderLeft ? 1 : 0,
                borderRight: props.borderRight ? 1 : 0,
                borderTop: props.borderTop ? 1 : 0,
                borderBottom: props.borderBottom ? 1 : 0,
                borderLeftColor: lightBorderColor,
                borderRightColor: lightBorderColor,
                borderTopColor: lightBorderColor,
                borderBottomColor: lightBorderColor,
            }}
        >
            {props.children}
        </Grid>
    );
}

export function ImanGrid(props: { children?: React.ReactNode; fluid?: boolean }): JSX.Element {
    return <Grid container>{props.children}</Grid>;
}
