import React, { useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AppBar,
    Avatar,
    Box,
    BoxProps,
    Button,
    Container,
    ContainerProps,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    IconButton,
    Modal,
    Popover,
    Stack,
    styled,
} from "@mui/material";
import { PermissionForApiPermissionTypeEnum, AppInfo } from "../../client";
import { Body1, Caption, H5, H6 } from "./ImanTypography";
import { useRecoilValue } from "recoil";
import { recoilAppInfo, recoilHasToken, recoilUserPermissions } from "../../recoilStore";
import { Navigation } from "../Navigation";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import { ImanNavigationIcon } from "./ImanNavigation";
import { UserPermission } from "../../functionality/frontendAccessRightsDepr";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ImanCard, StyledCard } from "./ImanCard";
import { FormattedMessage } from "react-intl";
import { ImanList } from "./ImanList";
import { Routes } from "../../functionality/Routes";
import {
    hasAnyRequiredPermission,
    hasAnyRequiredPermissionOfType,
} from "../../functionality/PermissionsUtil";
import { MessageBoxError } from "./MessageBoxes";

export function ImanBasePage(props: { children?: React.ReactNode }): JSX.Element {
    const [isNavigationOpen, setIsOpen] = useState(true);
    const appInfo = useRecoilValue(recoilAppInfo);
    const appBarHeight = 80;
    return (
        <ImanContainer maxWidth="xl" className="iman-base-page">
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <ImanAppBar
                    height={appBarHeight}
                    appInfo={appInfo}
                    toggleNavigation={() => setIsOpen(!isNavigationOpen)}
                />
                <Navigation isOpen={isNavigationOpen} appBarHeight={appBarHeight} />
                <Box className="iman-base-page-content" width="100%" paddingTop={5}>
                    <Box height={appBarHeight} />
                    {props.children}
                </Box>
            </Box>
        </ImanContainer>
    );
}

export function ImanAppBar(props: {
    appInfo: AppInfo;
    toggleNavigation: () => void;
    height: number;
}): JSX.Element {
    const hasToken = useRecoilValue(recoilHasToken);

    return (
        <AppBar
            position="fixed"
            sx={{ height: props.height, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <Stack alignItems="center" spacing={2} direction="row" sx={{ height: 80, paddingX: 2 }}>
                <IconButton onClick={props.toggleNavigation}>
                    <MenuIcon sx={{ color: (theme) => theme.palette.primary.contrastText }} />
                </IconButton>
                <H6>
                    <ImanLogoImage minWidth="200px" />
                </H6>
                {/*<H6>IntegrationMan 4</H6>*/}
                <H5>
                    {props.appInfo.company} {props.appInfo.environment}
                </H5>
                <ImanBox fillSpace />
                {hasToken && <ImanNavigationIcon link={Routes.ADMIN} icon={<SettingsIcon />} />}
                {hasToken && <ImanNavigationIcon link={Routes.PROFILE} icon={<AccountCircle />} />}
            </Stack>
        </AppBar>
    );
}

export function ImanDrawer(props: {
    children: React.ReactNode;
    isOpen: boolean;
    appBarHeight: number;
}): JSX.Element {
    const drawerWidth = 250;
    return (
        <Drawer
            anchor="left"
            open={props.isOpen}
            variant="persistent"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                },
            }}
        >
            <Box height={props.appBarHeight} />
            <Box>{props.children}</Box>
        </Drawer>
    );
}

export function ImanContainer(props: {
    children?: React.ReactNode;
    maxWidth?: "xs" | "sm" | "xl";
    backgroundStyle?: "paper";
    className?: string;
}): JSX.Element {
    return props.backgroundStyle === "paper" ? (
        <StyledContainerPaper className={props.className} maxWidth={props.maxWidth}>
            {props.children}
        </StyledContainerPaper>
    ) : (
        <Container className={props.className} maxWidth={props.maxWidth}>
            {props.children}
        </Container>
    );
}

export const StyledContainerPaper = styled(Container)<ContainerProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export function ImanDivider(): JSX.Element {
    return <Divider light variant="fullWidth" />;
}

export function ImanStack(props: {
    children?: React.ReactNode;
    align?: "center" | "start";
    justifyContent?: "flex-start" | "space-between" | "flex-end" | "center";
    direction?: "row" | "column";
    spacing?: number;
    fullWidth?: boolean;
    width?: string;
    withBottomMargin?: boolean;
}): JSX.Element {
    const calculateAlign = () => {
        switch (props.align) {
            case "center":
                return "center";
            case "start":
                return "flex-start";
            default:
                return "center";
        }
    };

    const calculateMargin = () => {
        if (props.withBottomMargin) {
            return "4%";
        } else {
            return undefined;
        }
    };
    return (
        <Stack
            className="the-stack"
            direction={props.direction}
            sx={{ paddingX: 0, width: props.fullWidth ? "100%" : "auto" }}
            spacing={props.spacing !== undefined ? props.spacing : 2}
            maxWidth="xl"
            width={props.width}
            justifyContent={props.justifyContent}
            alignItems={calculateAlign()}
            marginBottom={calculateMargin()}
        >
            {props.children}
        </Stack>
    );
}

export function ImanLogoImage(props: { width?: string; minWidth?: string }): JSX.Element {
    return (
        <img
            style={{ width: props.width, minWidth: props.minWidth }}
            src="assets/IntegrationMan_Logo_Main_w.svg"
            alt="Proclane IntegrationMan 4"
        />
    );
}

export const ImanBackground = styled(Box)<BoxProps>(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
}));

export function ImanExpandable(props: {
    summary: React.ReactNode;
    children: React.ReactNode;
    footer?: React.ReactNode;
    id?: string;
    width?: string;
}): JSX.Element {
    const [expanded, setExpanded] = useState(false);
    return (
        <StyledCard id={props.id} sx={{ width: props.width }}>
            <Button
                disableRipple
                onClick={() => setExpanded(!expanded)}
                sx={{
                    width: "100%",
                    padding: 3,
                    color: (theme) => theme.palette.text.primary,
                }}
            >
                <ImanBox fillSpace>{props.summary}</ImanBox>
                <ExpandMoreIcon />
            </Button>
            <Box
                sx={{
                    height: expanded ? "auto" : 0,
                }}
            >
                <ImanDivider />
                <Box>{props.children}</Box>
                {props.footer && <ImanDivider />}
                {props.footer && <Box padding={2}>{props.footer}</Box>}
            </Box>
        </StyledCard>
    );
}

export function ImanExpandable2(props: {
    summary: React.ReactNode;
    children: React.ReactNode;
    footer?: React.ReactNode;
}): JSX.Element {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {props.summary}
            </AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
}

export function ImanExpandableDepr(props: {
    summary: React.ReactNode;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Accordion sx={{ borderRadius: "15px", borderTopLeftRadius: "15px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{props.summary}</AccordionSummary>
            <AccordionDetails>{props.children}</AccordionDetails>
        </Accordion>
    );
}

export function CaptionedData(props: {
    i18nId: string;
    defaultCaption: string;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <React.Fragment>
            <Caption>
                <FormattedMessage id={props.i18nId} defaultMessage={props.defaultCaption} />
            </Caption>
            <Body1>{props.children}</Body1>
        </React.Fragment>
    );
}

export function CaptionedList(props: {
    i18nId: string;
    defaultCaption: string;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <React.Fragment>
            <Caption>
                <FormattedMessage id={props.i18nId} defaultMessage={props.defaultCaption} />
            </Caption>
            <ImanList>{props.children}</ImanList>
        </React.Fragment>
    );
}

export function ImanBox(props: {
    className?: string;
    textAlign?: "left";
    children?: React.ReactNode;
    fillSpace?: boolean;
    width?: string;
}): JSX.Element {
    return (
        <Box
            sx={{ flexGrow: props.fillSpace === true ? 1 : 0 }}
            className={props.className}
            textAlign={props.textAlign}
            width={props.width}
        >
            {props.children}
        </Box>
    );
}

export function ImanConfirmationDialog(props: {
    isOpen: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    title: string;
    message: React.ReactNode;
    errorMessage?: string;
}): JSX.Element {
    return (
        <Dialog open={props.isOpen} onClose={props.onCancel}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.message}</DialogContentText>
                {props.errorMessage && (
                    <MessageBoxError>
                        <Body1>{props.errorMessage}</Body1>
                    </MessageBoxError>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel}>
                    <FormattedMessage id="general.dialog.cancel" defaultMessage="Cancel" />
                </Button>
                <Button onClick={props.onConfirm} autoFocus>
                    <FormattedMessage id="general.dialog.confirm" defaultMessage="Confirm" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export function ProtectedComponent(props: {
    restrictedToAnyOf: UserPermission[];
    children: React.ReactNode;
}): JSX.Element {
    const permissionsOfUser = useRecoilValue(recoilUserPermissions);

    return (
        <Conditional when={hasAnyRequiredPermission(props.restrictedToAnyOf, permissionsOfUser)}>
            {props.children}
        </Conditional>
    );
}

export function HasAnyPermissionOfTypes(props: {
    restrictedToAnyOf: PermissionForApiPermissionTypeEnum[];
    children: React.ReactNode;
}): JSX.Element {
    const permissionsOfUser = useRecoilValue(recoilUserPermissions);
    return (
        <Conditional
            when={hasAnyRequiredPermissionOfType(props.restrictedToAnyOf, permissionsOfUser)}
        >
            {props.children}
        </Conditional>
    );
}

export function Conditional(props: { when: boolean; children: React.ReactNode }): JSX.Element {
    if (props.when) {
        return <React.Fragment>{props.children}</React.Fragment>;
    } else {
        return <React.Fragment />;
    }
}

const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100%", md: "80%", lg: "75%", xl: "50%" },
};

export function ImanModal(props: {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Modal open={props.isOpen} onClose={props.onClose}>
            <Box sx={style}>
                <ImanCard>{props.children}</ImanCard>
            </Box>
        </Modal>
    );
}

export function ImanAvatar(props: { text: string }): JSX.Element {
    return <Avatar>{props.text[0]}</Avatar>;
}

export function ImanPopover(props: {
    isOpen: boolean;
    anchorEl: HTMLButtonElement | null;
    close: () => void;
    children: React.ReactNode;
}): JSX.Element {
    return (
        <Popover
            anchorEl={props.anchorEl}
            open={props.isOpen}
            onClose={props.close}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            {props.children}
        </Popover>
    );
}
