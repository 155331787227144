import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { BlueprintsPage } from "./components/BlueprintsPage";
import { LogsPage } from "./components/LogsPage";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import English from "./lang/en.json";
import German from "./lang/de.json";
import { recoilAppInfo, recoilHasToken, recoilLocaleSetByUser } from "./recoilStore";
import { HomePageComponent } from "./components/HomePageComponent";
import { LoginPage } from "./components/LoginPage";
import { useImanApi, useRefresh } from "./util/ApiUtil";
import { ProfileComponent } from "./components/profile/ProfileComponent";
import { AdminComponentContainer } from "./components/AdminComponent";
import { SetPasswordComponentContainer } from "./components/profile/SetPasswordComponentContainer";
import { ResourceComponent } from "./components/ResourceComponent";
import { ThemeProvider } from "@mui/material";
import { ImanBackground, ImanBasePage } from "./components/wrappers/ImanLayout";
import { theme } from "./Theme";
import { ImanCircularIndicator } from "./components/wrappers/Indicators";
import { SchedulesPage } from "./components/SchedulesPage";
import { ScheduleEditPage } from "./components/ScheduleEdit";
import { Routes } from "./functionality/Routes";
import { JobsPage } from "./components/jobs/JobsPage";
import { JobsDetailPage } from "./components/jobs/JobsDetailPage";
import { DashboardPage } from "./components/DashboardPage";

export function getBaseContext(): string {
    const baseElement = document.getElementById("iman-base");
    if (baseElement !== null) {
        const rawValue = baseElement.attributes.getNamedItem("href");
        if (rawValue !== null) {
            return rawValue.value;
        }
    }
    return "/";
}

// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
function App() {
    // const hasToken = useRecoilValue(recoilHasToken);
    // const [initialRefreshDone, setInitialRefreshDone] = useState(false);
    const api = useImanApi();
    const [, setAppInfo] = useRecoilState(recoilAppInfo);

    useEffect(() => {
        api.fetchInfo((result) => setAppInfo(result));
    }, []);

    return (
        <ImanBackground className="iman-background">
            <Switch>
                <Route path={Routes.SET_PASSWORD} component={SetPasswordComponentContainer} />
                <Route path="/">
                    <WithTokenRefresh>
                        <Switch>
                            <Route path={Routes.LOGIN} component={LoginPage} />
                            <Route path="/">
                                <ImanMainPage />
                            </Route>
                            <Redirect to={Routes.LOGIN} />
                        </Switch>
                    </WithTokenRefresh>
                </Route>
            </Switch>
        </ImanBackground>
    );
}

function ImanMainPage(): JSX.Element {
    return (
        <ImanBasePage>
            <Switch>
                <Route exact path={"/"} component={HomePageComponent} />
                <Route path={Routes.LOGIN} component={LoginPage} />
                <Route path={Routes.ADMIN} component={AdminComponentContainer} />
                <Route path={Routes.BLUEPRINTS} component={BlueprintsPage} />
                <Route path={Routes.DASHBOARD} component={DashboardPage} />
                <Route exact path={Routes.JOBS} component={JobsPage} />
                <Route path={Routes.JOB_DETAILS} component={JobsDetailPage} />
                <Route path={Routes.LOGS} component={LogsPage} />
                <Route path={Routes.PROFILE} component={ProfileComponent} />
                <Route path={Routes.RESOURCE} component={ResourceComponent} />
                <Route exact path={Routes.SCHEDULER} component={SchedulesPage} />
                <Route path={Routes.SCHEDULER_EDIT} component={ScheduleEditPage} />
                <Route path={Routes.SET_PASSWORD} component={SetPasswordComponentContainer} />
            </Switch>
        </ImanBasePage>
    );
}

function WithRouter(props: { children: React.ReactNode }): JSX.Element {
    const [contextBase] = useState(getBaseContext());
    return <BrowserRouter basename={contextBase}>{props.children}</BrowserRouter>;
}

function WithIntl(props: { children: React.ReactNode }): JSX.Element {
    const [globalLocale, setGlobalLocaleCode] = useRecoilState(recoilLocaleSetByUser);
    const locale = navigator.language;
    useEffect(() => {
        setGlobalLocaleCode(locale);
    }, [locale, setGlobalLocaleCode]);

    let lang;
    if (locale.includes("en")) {
        lang = English;
    } else if (locale === "de") {
        lang = German;
    }

    return (
        <IntlProvider locale={globalLocale} messages={lang}>
            {props.children}
        </IntlProvider>
    );
}

function WithTokenRefresh(props: { children: React.ReactNode }): JSX.Element {
    const hasToken = useRecoilValue(recoilHasToken);
    const [initialRefreshDone, setInitialRefreshDone] = useState(false);

    useRefresh(initialRefreshDone, () => {
        setInitialRefreshDone(true);
    });

    return (
        <React.Fragment>
            <Switch>
                <Route path={"/"}>
                    {!initialRefreshDone ? (
                        <ImanCircularIndicator />
                    ) : initialRefreshDone && !hasToken ? (
                        <LoginPage />
                    ) : (
                        props.children
                    )}
                </Route>
            </Switch>
        </React.Fragment>
    );
}

function WrappedApp(): JSX.Element {
    return (
        <div className="App">
            <RecoilRoot>
                <WithRouter>
                    <WithIntl>
                        <ThemeProvider theme={theme}>
                            <App />
                        </ThemeProvider>
                    </WithIntl>
                </WithRouter>
            </RecoilRoot>
        </div>
    );
}

export default WrappedApp;
