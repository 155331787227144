import React, { useEffect, useState } from "react";
import {
    PermissionForApiPermissionTypeEnum,
    DefaultApiApiV1InternalLogsGetRequest,
    LogEntryApi,
    LogEntryApiLevelEnum,
    LogsResponse,
} from "../client";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { epochMillisToLocaleDateTimeStringWithMillis } from "../util/StringUtil";
import { FilteringDropdown } from "./wrappers/form/FilteringDropdown";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    recoilAppInfo,
    recoilHasToken,
    recoilLocaleSetByUser,
    recoilUserPermissions,
} from "../recoilStore";
import { Link, useHistory, useLocation } from "react-router-dom";
import { createLogsLink, urlSearchParamsToLogsFilterParams } from "../util/LinkUtil";
import { PagingControl } from "./PagingControl";
import { dateToEpochMillisNotNull, epochMillisToDate } from "../util/DateUtil";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { DateRangePicker } from "./DateRangePicker";
import { MultiEntriesInput } from "./MultiEntriesInput";
import { useImanApi } from "../util/ApiUtil";
import { ImanGridCell, ImanRow } from "./wrappers/ImanGrid";
import { ImanButton, ImanSubmitButton } from "./wrappers/Buttons";
import { ImanCircularIndicator } from "./wrappers/Indicators";
import { ImanForm } from "./wrappers/form/Form";
import { SimpleInput, TextInputWithButton } from "./wrappers/form/TextInputs";
import {
    FormattedTableCell,
    ImanTable,
    ImanTableBody,
    ImanTableCell,
    ImanTableHead,
    ImanTableRow,
} from "./wrappers/ImanTable";
import { Parameters, Routes } from "../functionality/Routes";
import { hasAnyRequiredPermissionOfType, Permissions } from "../functionality/PermissionsUtil";
import { ImanBox, ImanStack, ProtectedComponent } from "./wrappers/ImanLayout";
import { ImanPageHeader } from "./wrappers/ImanPage";
import useMediaQuery from "@mui/material/useMediaQuery";
import { H4, H5 } from "./wrappers/ImanTypography";
import { ImanCard, ImanCardActions } from "./wrappers/ImanCard";
import { Box } from "@mui/material";

function InputLogLevelCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
}) {
    const intl = useIntl();
    const permissionsOfUser = useRecoilValue(recoilUserPermissions);
    const canReadDebugLogs = hasAnyRequiredPermissionOfType(
        [PermissionForApiPermissionTypeEnum.ReadDebugAndTraceLogs],
        permissionsOfUser
    );

    const readableLevels = Object.values(LogEntryApiLevelEnum).filter((level) => {
        if (canReadDebugLogs) {
            return true;
        } else {
            return level !== LogEntryApiLevelEnum.Trace && level !== LogEntryApiLevelEnum.Debug;
        }
    });
    return (
        <FilteringDropdown
            id="dropdownLevel"
            fullWidth
            currentValue={props.localFetchParameters.level}
            setValue={(newValue) =>
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    level: newValue ?? "TRACE",
                })
            }
            allValues={readableLevels}
            withEmpty={false}
            labelText={intl.formatMessage({
                id: "logs.filtering.level",
                defaultMessage: "Level",
            })}
        />
    );
}

function InputSubjectCol(props: {
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
}): JSX.Element {
    const intl = useIntl();

    const labelText = intl.formatMessage({
        id: "logs.filtering.subject",
        defaultMessage: "Subject",
    });
    return (
        <React.Fragment>
            <TextInputWithButton
                value={props.localFetchParameters.subject}
                placeholder={"subject/hierarchy"}
                onChange={(newValue) => {
                    const processedNewValue = newValue.target.value.replace(" ", "/");
                    props.setLocalFetchParameters({
                        ...props.localFetchParameters,
                        subject: processedNewValue,
                    });
                }}
                labelText={labelText}
                onClick={() =>
                    props.setLocalFetchParameters({
                        ...props.localFetchParameters,
                        subject: "/",
                    })
                }
            >
                <BsArrowCounterclockwise />
            </TextInputWithButton>
        </React.Fragment>
    );
}

export function DateTimePickerStartCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
}): JSX.Element {
    const intl = useIntl();
    const placeholderStartDate = intl.formatMessage({
        id: "logs.filtering.startDateEmpty",
    });
    return (
        <DateRangePicker
            currentValue={epochMillisToDate(props.localFetchParameters.rangeStartInEpochMillis)}
            placeholder={placeholderStartDate}
            intl={intl}
            labelMessageId={"logs.filtering.start"}
            setNewValue={(date) => {
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    rangeStartInEpochMillis:
                        date !== undefined && date !== null
                            ? dateToEpochMillisNotNull(date)
                            : undefined,
                });
            }}
        />
    );
}

function DateTimePickerEndCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
}) {
    const intl = useIntl();
    const placeholderEndDate = intl.formatMessage({
        id: "logs.filtering.endDateEmpty",
    });
    return (
        <ImanGridCell xs={6}>
            <DateRangePicker
                currentValue={epochMillisToDate(props.localFetchParameters.rangeEndInEpochMillis)}
                placeholder={placeholderEndDate}
                intl={intl}
                labelMessageId={"logs.filtering.end"}
                setNewValue={(date) => {
                    props.setLocalFetchParameters({
                        ...props.localFetchParameters,
                        rangeEndInEpochMillis:
                            date !== undefined && date !== null
                                ? dateToEpochMillisNotNull(date)
                                : undefined,
                    });
                }}
            />
        </ImanGridCell>
    );
}

function InputFreetextCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
}): JSX.Element {
    return (
        <SimpleInput
            id={"iman-filters-freetext"}
            fullwidth
            labelI18nIdentifier="logs.filtering.freeText"
            defaultLabel="Free text"
            value={props.localFetchParameters.freeText ?? ""}
            onChange={(event) => {
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    freeText: event.target.value,
                });
            }}
        />
    );
}

function InputBlueprintsCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
    allBlueprintIdentifiers: string[];
}): JSX.Element {
    const intl = useIntl();
    return (
        <MultiEntriesInput
            label={intl.formatMessage({
                id: "logs.filtering.blueprints",
                defaultMessage: "Blueprints",
            })}
            values={props.localFetchParameters.blueprintIdentifiers ?? []}
            setValues={(newValues) => {
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    blueprintIdentifiers: newValues,
                });
            }}
            suggestionsProvider={(currentValue) => {
                return props.allBlueprintIdentifiers.filter(
                    (s) => s.startsWith(currentValue) && s !== currentValue
                );
            }}
        />
    );
}

function InputJobIdsCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
}) {
    const intl = useIntl();
    const labelText = intl.formatMessage({
        id: "logs.filtering.jobId",
        defaultMessage: "Job ID",
    });
    return (
        <MultiEntriesInput
            label={labelText}
            placeholder={labelText}
            values={props.localFetchParameters.jobIds ?? []}
            setValues={(newValues) => {
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    jobIds: newValues,
                });
            }}
        />
    );
}

function InputLoggablesCol(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
    allLoggableNames: string[];
}): JSX.Element {
    const intl = useIntl();
    return (
        <MultiEntriesInput
            label={intl.formatMessage({
                id: "logs.filtering.loggableTypes",
                defaultMessage: "Loggable types",
            })}
            values={props.localFetchParameters.loggableTypes ?? []}
            setValues={(newValues) => {
                props.setLocalFetchParameters({
                    ...props.localFetchParameters,
                    loggableTypes: newValues,
                });
            }}
            suggestionsProvider={(currentValue) => {
                return props.allLoggableNames.filter(
                    (s) => s.startsWith(currentValue) && s !== currentValue
                );
            }}
        />
    );
}

// TODO: reintroduce i18n support
// function InputLocaleCol(props: {
//     locale: string;
//     setLocale: (value: string) => void;
//     supportedAppLocales: string[];
// }): JSX.Element {
//     const intl = useIntl();
//     return (
//         <ImanGridCell>
//             <FilteringDropdown
//                 id="dropdownLocale"
//                 labelText={intl.formatMessage({
//                     id: "general.language",
//                     defaultMessage: "Language",
//                 })}
//                 currentValue={props.locale}
//                 setValue={(newValue) => {
//                     if (newValue !== undefined) {
//                         props.setLocale(newValue);
//                     }
//                 }}
//                 allValues={props.supportedAppLocales}
//                 withEmpty={false}
//             />
//         </ImanGridCell>
//     );
// }

// eslint-disable-next-line max-lines-per-function
function FilterInputsRow(props: {
    localFetchParameters: DefaultApiApiV1InternalLogsGetRequest;
    setLocalFetchParameters: (value: DefaultApiApiV1InternalLogsGetRequest) => void;
    allBlueprintNames: string[];
    allLoggableNames: string[];
    locale: string;
    setLocale: (valOrUpdater: ((currVal: string) => string) | string) => void;
    supportedAppLocales: string[];
}): JSX.Element {
    return (
        <ImanStack fullWidth align="start" justifyContent="flex-start">
            <H4>
                <FormattedMessage id="logs.filter" defaultMessage="Filter" />{" "}
            </H4>
            <ImanStack fullWidth direction="row" spacing={4}>
                <ImanGridCell xs={2}>
                    <InputLogLevelCol
                        localFetchParameters={props.localFetchParameters}
                        setLocalFetchParameters={props.setLocalFetchParameters}
                    />
                </ImanGridCell>
                <ImanGridCell xs={10}>
                    <InputFreetextCol
                        localFetchParameters={props.localFetchParameters}
                        setLocalFetchParameters={props.setLocalFetchParameters}
                    />
                </ImanGridCell>
            </ImanStack>
            <InputSubjectCol
                setLocalFetchParameters={props.setLocalFetchParameters}
                localFetchParameters={props.localFetchParameters}
            />
            <InputBlueprintsCol
                localFetchParameters={props.localFetchParameters}
                setLocalFetchParameters={props.setLocalFetchParameters}
                allBlueprintIdentifiers={props.allBlueprintNames}
            />
            <InputJobIdsCol
                localFetchParameters={props.localFetchParameters}
                setLocalFetchParameters={props.setLocalFetchParameters}
            />
            <InputLoggablesCol
                localFetchParameters={props.localFetchParameters}
                setLocalFetchParameters={props.setLocalFetchParameters}
                allLoggableNames={props.allLoggableNames}
            />
            <ImanStack fullWidth direction="row" spacing={4}>
                <H5>
                    <FormattedMessage
                        id="logs.filtering.timeframe"
                        defaultMessage="Timeframe"
                    ></FormattedMessage>
                </H5>
                <DateTimePickerStartCol
                    localFetchParameters={props.localFetchParameters}
                    setLocalFetchParameters={props.setLocalFetchParameters}
                />
                <DateTimePickerEndCol
                    localFetchParameters={props.localFetchParameters}
                    setLocalFetchParameters={props.setLocalFetchParameters}
                />
            </ImanStack>
            {/*TODO: put language support back in*/}
            {/*<InputLocaleCol*/}
            {/*    locale={props.locale}*/}
            {/*    setLocale={props.setLocale}*/}
            {/*    supportedAppLocales={props.supportedAppLocales}*/}
            {/*/>*/}
        </ImanStack>
    );
}

// eslint-disable-next-line max-lines-per-function
function LogFilters(props: {
    fetchParameters: DefaultApiApiV1InternalLogsGetRequest;
}): JSX.Element {
    const [locale, setLocale] = useRecoilState(recoilLocaleSetByUser);
    const appInfo = useRecoilValue(recoilAppInfo);
    const history = useHistory();
    const hasToken = useRecoilValue(recoilHasToken);

    const [localFetchParameters, setLocalFetchParameters] = useState(props.fetchParameters);
    const [allBlueprintNames, setAllBlueprintNames] = useState<string[]>([]);
    const [allLoggableNames, setAllLoggableNames] = useState<string[]>([]);
    const imanApi = useImanApi();

    useEffect(() => {
        if (hasToken) {
            imanApi.fetchBlueprintsBaseData().then((response) => {
                const identifiers = response.blueprints.map((blueprint) => blueprint.identifier);
                setAllBlueprintNames(identifiers);
            });
            imanApi.fetchLoggableNames(setAllLoggableNames);
        }
    }, [setAllBlueprintNames, setAllLoggableNames, hasToken]);

    const submitLink = createLogsLink(localFetchParameters);

    return (
        <ImanForm
            onSubmit={(event) => {
                event.preventDefault();
                history.push(submitLink);
            }}
        >
            <ImanCard>
                <FilterInputsRow
                    localFetchParameters={localFetchParameters}
                    setLocalFetchParameters={setLocalFetchParameters}
                    allBlueprintNames={allBlueprintNames}
                    allLoggableNames={allLoggableNames}
                    locale={locale}
                    setLocale={setLocale}
                    supportedAppLocales={appInfo.supportedLocales}
                />
                <Box paddingTop="50px">
                    <ImanCardActions justify="end">
                        <ImanSubmitButton>
                            <FormattedMessage id="logs.filtering.submit" defaultMessage="Filter" />
                        </ImanSubmitButton>
                    </ImanCardActions>
                </Box>
            </ImanCard>
        </ImanForm>
    );
}

const defaultEntriesPerPage = 100;

function areNotEqual(
    fetchParamsFromUrl: DefaultApiApiV1InternalLogsGetRequest,
    fetchParams: DefaultApiApiV1InternalLogsGetRequest
) {
    return (
        fetchParamsFromUrl !== null &&
        (fetchParams.page !== fetchParamsFromUrl.page ||
            fetchParams.entriesPerPage !== fetchParamsFromUrl.entriesPerPage ||
            areStringArraysNotEqual(fetchParams.jobIds, fetchParamsFromUrl.jobIds) ||
            areStringArraysNotEqual(
                fetchParams.blueprintIdentifiers,
                fetchParamsFromUrl.blueprintIdentifiers
            ) ||
            areStringArraysNotEqual(fetchParams.loggableTypes, fetchParamsFromUrl.loggableTypes) ||
            fetchParams.level !== fetchParamsFromUrl.level ||
            fetchParams.subject !== fetchParamsFromUrl.subject ||
            fetchParams.freeText !== fetchParamsFromUrl.freeText ||
            fetchParams.rangeStartInEpochMillis !== fetchParamsFromUrl.rangeStartInEpochMillis ||
            fetchParams.rangeEndInEpochMillis !== fetchParamsFromUrl.rangeEndInEpochMillis ||
            fetchParams.logsBeforeEpochMillis !== fetchParamsFromUrl.logsBeforeEpochMillis ||
            fetchParams.localeCode !== fetchParamsFromUrl.localeCode)
    );
}

function areStringArraysNotEqual(lhs: string[] | undefined, rhs: string[] | undefined): boolean {
    return lhs?.join() !== rhs?.join();
}

function createDefaultFetchParameters(locale: string): DefaultApiApiV1InternalLogsGetRequest {
    return {
        page: 1,
        entriesPerPage: defaultEntriesPerPage,
        level: "INFO",
        localeCode: locale,
        subject: "/",
        jobIds: [],
        logsBeforeEpochMillis: undefined,
        sort: "NEWEST_FIRST",
    };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getLogsParametersFromLocation(): [
    DefaultApiApiV1InternalLogsGetRequest,
    (params: DefaultApiApiV1InternalLogsGetRequest) => void
] {
    const location = useLocation();
    const locale = useRecoilValue(recoilLocaleSetByUser);
    const searchParams = new URLSearchParams(location.search);
    const fetchParamsFromUrl = urlSearchParamsToLogsFilterParams(searchParams, locale);

    const [lastBlankRequestTimestampInEpochMillis, setLastBlankRequestTimestamp] = useState(
        dateToEpochMillisNotNull(new Date())
    );
    const nowInEpochMillis = dateToEpochMillisNotNull(new Date());

    const defaultFetchParams: DefaultApiApiV1InternalLogsGetRequest =
        createDefaultFetchParameters(locale);

    const [fetchParams, setFetchParams] = useState<DefaultApiApiV1InternalLogsGetRequest>(
        fetchParamsFromUrl ?? defaultFetchParams
    );

    if (fetchParamsFromUrl !== null && areNotEqual(fetchParamsFromUrl, fetchParams)) {
        setFetchParams(fetchParamsFromUrl);
    } else if (fetchParamsFromUrl === null && areNotEqual(fetchParams, defaultFetchParams)) {
        setFetchParams(defaultFetchParams);
    } else if (
        fetchParamsFromUrl === null &&
        // To avoid infinite update loops, we only fetch with blank url parameters when results are older than 2s
        nowInEpochMillis - lastBlankRequestTimestampInEpochMillis > 2000
    ) {
        setFetchParams(defaultFetchParams);
        setLastBlankRequestTimestamp(nowInEpochMillis);
    }

    return [fetchParams, setFetchParams];
}

function RefreshLogsRow(props: {
    isTailingActive: boolean;
    setIsTailingActive: (value: boolean) => void;
    fetchLogs: () => void;
}): JSX.Element {
    return (
        <ImanRow>
            <ImanGridCell>
                <ImanButton
                    color={props.isTailingActive ? "error" : "primary"}
                    onClick={() => props.setIsTailingActive(!props.isTailingActive)}
                >
                    {props.isTailingActive ? (
                        <div>
                            <ImanCircularIndicator color="primary" />
                            <FormattedMessage
                                id={"logs.tailing.deactivate"}
                                defaultMessage={"Deactivate tailing"}
                            />
                        </div>
                    ) : (
                        <FormattedMessage
                            id={"logs.tailing.activate"}
                            defaultMessage={"Activate tailing"}
                        />
                    )}
                </ImanButton>
            </ImanGridCell>
            <ImanGridCell>
                <ImanButton color="primary" onClick={() => props.fetchLogs()}>
                    <FormattedMessage id={"logs.refresh"} defaultMessage={"Refresh"} />
                </ImanButton>
            </ImanGridCell>
        </ImanRow>
    );
}

// eslint-disable-next-line max-lines-per-function
export function LogsPage(): JSX.Element {
    const [logEntries, setLogEntries] = useState<LogsResponse>();
    const useSelectedLocale = useRecoilValue(recoilLocaleSetByUser);
    const [fetchParams, setFetchParams] = getLogsParametersFromLocation();
    const hasToken = useRecoilValue(recoilHasToken);
    const [isTailingActive, setIsTailingActive] = useState(false);
    const [, setLastFetch] = useState(Date.now());
    const imanApi = useImanApi();
    const fetchEveryMs = 3000;

    const fetchLogs = () => {
        imanApi.fetchLogEntries(
            (data) => {
                setLogEntries(data);
            },
            (msg) => console.log(`Error while trying to fetch done jobs: ${msg}`),
            fetchParams
        );
    };

    useEffect(() => {
        if (hasToken) {
            imanApi.fetchLogEntries(
                (data) => {
                    setLogEntries(data);
                    setLastFetch(Date.now());
                },
                (msg) => console.log(`Error while trying to fetch done jobs: ${msg}`),
                fetchParams
            );
        }
    }, [fetchParams, setFetchParams, useSelectedLocale, hasToken]);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    useEffect(() => {
        setLastFetch(Date.now());
        const timer = setInterval(() => {
            if (hasToken && isTailingActive) {
                fetchLogs();
                setLastFetch(Date.now());
            }
        }, fetchEveryMs);
        // clearing interval
        return () => {
            clearInterval(timer);
        };
    }, [hasToken, isTailingActive]);

    return (
        <ImanBox className="iman-logs">
            <ImanStack fullWidth align="start" spacing={6}>
                <ImanPageHeader
                    titleId={"logs.title"}
                    titleDefaultMessage={"Logs"}
                ></ImanPageHeader>
                <ImanStack fullWidth align="start" justifyContent="flex-start" spacing={5}>
                    <LogFilters fetchParameters={fetchParams} />
                    <RefreshLogsRow
                        isTailingActive={isTailingActive}
                        setIsTailingActive={setIsTailingActive}
                        fetchLogs={fetchLogs}
                    />
                    <LogTable logEntries={logEntries} fetchParams={fetchParams} />
                </ImanStack>
            </ImanStack>
        </ImanBox>
    );
}

// eslint-disable-next-line max-lines-per-function
function LogTable(props: {
    logEntries: LogsResponse | undefined;
    fetchParams: DefaultApiApiV1InternalLogsGetRequest;
}): JSX.Element {
    const intl = useIntl();
    // only show the full table if we have enough space (width)
    const showFullTable = useMediaQuery("(min-width:1500px)");

    const pagingInfo = props.logEntries?.pagingMetaData ?? {
        currentPage: props.fetchParams.page,
        entriesPerPage: props.fetchParams.entriesPerPage,
        numberOfItems: 0,
        numberOfPages: 0,
    };

    return (
        <ImanTable>
            <ImanTableHead>
                <ImanTableRow>
                    <FormattedTableCell id="logs.table.timestamp" defaultMessage="Timestamp" />
                    <FormattedTableCell id="logs.table.level" defaultMessage="level" />
                    {showFullTable ? (
                        <React.Fragment>
                            <FormattedTableCell id="logs.table.subject" defaultMessage="subject" />
                            <FormattedTableCell id="logs.table.thread" defaultMessage="thread" />
                            <FormattedTableCell id="logs.table.request" defaultMessage="request" />
                            <FormattedTableCell id="logs.table.jobId" defaultMessage="Job ID" />
                        </React.Fragment>
                    ) : null}
                    <FormattedTableCell id="logs.table.message" defaultMessage="message" />
                    <FormattedTableCell id="logs.table.attachment" defaultMessage="attachment" />
                </ImanTableRow>
            </ImanTableHead>
            <ImanTableBody>
                {props.logEntries?.logEntries?.map((logEntry, index) => {
                    const key =
                        index + logEntry.subject + logEntry.level + logEntry.timestampInEpochMillis;
                    return (
                        <LogEntryRow
                            key={key}
                            logEntry={logEntry}
                            intl={intl}
                            showFullTable={showFullTable}
                        />
                    );
                })}
                <PagingControl
                    pagingInfo={pagingInfo}
                    currentPage={props.fetchParams.page}
                    linkProducer={(page) =>
                        createLogsLink({
                            ...props.fetchParams,
                            page: page,
                            logsBeforeEpochMillis:
                                props.logEntries?.pagingMetaData
                                    ?.timestampOfNewestEntryInEpochMillis ?? undefined,
                        })
                    }
                />
            </ImanTableBody>
        </ImanTable>
    );
}

function LogEntryRow(props: { logEntry: LogEntryApi; intl: IntlShape; showFullTable: boolean }) {
    const logEntry = props.logEntry;
    return (
        <ImanTableRow>
            <ImanTableCell>
                {epochMillisToLocaleDateTimeStringWithMillis(
                    logEntry.timestampInEpochMillis,
                    props.intl
                )}
            </ImanTableCell>
            <ImanTableCell>{logEntry.level}</ImanTableCell>
            {props.showFullTable ? (
                <React.Fragment>
                    <ImanTableCell>{logEntry.subject}</ImanTableCell>
                    <ImanTableCell>{logEntry.threadId}</ImanTableCell>
                    <ImanTableCell>{logEntry.requestId ?? "-"}</ImanTableCell>
                    <ImanTableCell>{logEntry.jobId ?? "-"}</ImanTableCell>
                </React.Fragment>
            ) : null}
            <ImanTableCell>{logEntry.localizedMessage}</ImanTableCell>
            <ImanTableCell align="right">
                {logEntry.attachmentUniqueFilename ? (
                    <ProtectedComponent
                        restrictedToAnyOf={[Permissions.ReadAttachmentsOfAllBlueprints]}
                    >
                        <Link
                            to={Routes.RESOURCE.replace(
                                Parameters.RESOURCE_ID,
                                logEntry.attachmentUniqueFilename
                            )}
                        >
                            <FormattedMessage
                                id="logs.table.attachment"
                                defaultMessage="attachment"
                            />
                        </Link>
                    </ProtectedComponent>
                ) : null}
            </ImanTableCell>
        </ImanTableRow>
    );
}
