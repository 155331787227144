import { FormattedMessage } from "react-intl";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useRecoilValue } from "recoil";
import { recoilUserPermissions, recoilUserIdentifier, recoilLoggedInUser } from "../../recoilStore";
import { ImanRow } from "../wrappers/ImanGrid";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { Body1, H6 } from "../wrappers/ImanTypography";
import { ImanStack } from "../wrappers/ImanLayout";

export function ProfileComponent(): JSX.Element {
    const identifierOfLoggedInUser = useRecoilValue(recoilUserIdentifier);
    const loggedInUser = useRecoilValue(recoilLoggedInUser);
    const accessRights = useRecoilValue(recoilUserPermissions);

    if (loggedInUser === null) {
        console.info("Data of user missing.");
        return <React.Fragment />;
    }

    return (
        <div className="iman-home">
            <h3>
                <FormattedMessage id="profile.title" defaultMessage="Profile" />
            </h3>
            <h5>{loggedInUser.emailAddress}</h5>
            <hr />
            <ImanRow>
                {identifierOfLoggedInUser !== undefined ? (
                    <ChangePasswordForm emailAddress={loggedInUser.emailAddress} />
                ) : null}
            </ImanRow>
            <ImanStack align="start">
                <H6>
                    <FormattedMessage
                        id="profile.accessRights.title"
                        defaultMessage="My access rights"
                    />
                </H6>
                <Body1>
                    {accessRights
                        .map(
                            (right) => right.type + (right.subgroup !== null ? right.subgroup : "")
                        )
                        .join(", ")}
                </Body1>
            </ImanStack>
        </div>
    );
}
